import FieldSecureText from "@/components/form/fields/FieldSecureText";
import FormError from "@/components/form/layout/FormError";
import { FormRequirement } from "@/components/form/layout/FormRequirement";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import Spacer from "@/components/Spacer";
import { Button } from "@/components/ui/button";
import {
  MIN_LENGTH_REGEX,
  MIN_PASSWORD_LENGTH,
  SPECIAL_CHARACTER_REGEX,
  UPPERCASE_REGEX,
} from "@/config/passwordRequirements";
import { CPException } from "@/models/exceptions/CPException";
import { setHttpAuthToken } from "@/services/api";
import { useLoginMutation } from "@/services/auth";
import {
  useGetUserQuery,
  useUpdateUserPasswordMutation,
} from "@/services/user";
import { saveAuthToken } from "@/utils/storage";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export default function FormNewPassword() {
  const { t } = useTranslation(["account", "common", "validation", "auth"]);
  const { data: user } = useGetUserQuery();
  const { mutateAsync: updateAsync } = useUpdateUserPasswordMutation();
  const { mutateAsync: loginAsync } = useLoginMutation();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema: unknown = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("validation:passwordRequired"))
      .matches(UPPERCASE_REGEX, t("validation:passwordUppercase"))
      .matches(SPECIAL_CHARACTER_REGEX, t("validation:passwordSpecial"))
      .matches(
        MIN_LENGTH_REGEX,
        t("validation:passwordLength", { length: MIN_PASSWORD_LENGTH })
      ),
    confirmNewPassword: Yup.string()
      .required(t("validation:confirmPasswordRequired"))
      .oneOf([Yup.ref("newPassword")], t("validation:passwordMatch")),
  });

  const {
    submitForm,
    resetForm,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async () => {
      if (!user?.email) return;

      setFormError(undefined);
      setFormSuccess(false);

      try {
        await updateAsync(values);
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }

        return;
      }

      try {
        const { authToken } = await loginAsync({
          username: user.email,
          password: values.newPassword,
        });

        saveAuthToken(authToken);
        setHttpAuthToken(authToken);

        setFormSuccess(true);
        setFormError(undefined);

        setTimeout(() => {
          setFormSuccess(false);
        }, 5000);
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }

      resetForm();
    },
  });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("account:changePasswordFormTitle")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("account:changePasswordFormDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && <FormError title={formError} />}

          <FormFields
            fields={[
              <FormField key="currentPassword">
                <FieldSecureText
                  name="currentPassword"
                  autoComplete="new-password"
                  value={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t("account:currentPassword")}
                  error={
                    (touched.currentPassword && errors.currentPassword) ||
                    undefined
                  }
                />
              </FormField>,
              <FormField key="newPassword">
                <FieldSecureText
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t("account:newPassword")}
                  error={
                    (touched.newPassword && errors.newPassword) || undefined
                  }
                />
              </FormField>,
              <FormField key="confirmNewPassword">
                <FieldSecureText
                  name="confirmNewPassword"
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t("account:confirmNewPassword")}
                  error={
                    (touched.confirmNewPassword && errors.confirmNewPassword) ||
                    undefined
                  }
                />

                <Spacer h={3} />

                <strong className="mb-1 block text-xs font-semibold leading-5 text-simple-900">
                  {t("auth:passwordRequirements")}
                </strong>

                <FormRequirement
                  isValid={UPPERCASE_REGEX.test(values.currentPassword)}
                >
                  {t("auth:passwordRequiresOneUppercaseCharacter")}
                </FormRequirement>

                <FormRequirement
                  isValid={SPECIAL_CHARACTER_REGEX.test(values.newPassword)}
                >
                  {t("auth:passwordRequiresOneSpecialCharacter")}
                </FormRequirement>

                <FormRequirement
                  isValid={MIN_LENGTH_REGEX.test(values.confirmNewPassword)}
                >
                  {t("auth:passwordRequiresHeightCharacters")}
                </FormRequirement>
              </FormField>,
            ]}
          />
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
