import { FCP_VehicleModel } from "@/@types/vehicle";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

interface Request {
  modelName: string;
}

type Response = FCP_VehicleModel[];

export const useLazyFindVehicleModels = () => {
  const queryClient = useQueryClient();
  return async ({ modelName }: Request) => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyFindVehicleModels"],
      queryFn: async () => {
        const { data } = await api.get<Response>(
          `/vehicleModels/search/${modelName}`
        );
        return data;
      },
    });
  };
};
