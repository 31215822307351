import FieldMap from "@/components/form/fields/FieldMap";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import { CPException } from "@/models/exceptions/CPException";
import { getCurrentPark } from "@/utils/storage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ParkAddLocation() {
  const { t } = useTranslation(["park", "validation"]);

  const [parkLocation, setParkLocation] = useState<
    google.maps.LatLngLiteral | undefined
  >();

  const [formError, setFormError] = useState<string | undefined>();
  const [formAction, setFormAction] = useState<"next" | "exit">("next");
  const { next, back, exit, updatePark } = useParkAdd();

  useEffect(() => {
    const park = getCurrentPark();

    if (park?.location) {
      setParkLocation({
        lat: park.location.latitude,
        lng: park.location.longitude,
      });
    }
  }, []);

  const initialValues = {
    location: parkLocation,
  };

  const { submitForm, isValid, isSubmitting, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      if (!values.location) {
        errors.location = t("validation:locationRequired");
      }
    },
    onSubmit: async (values) => {
      if (!values.location) return;

      setFormError(undefined);

      try {
        await updatePark({
          location: {
            latitude: values.location.lat,
            longitude: values.location.lng,
          },
        });

        formAction === "next" ? next() : exit();
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  function onBack() {
    back();
  }

  function onNext() {
    setFormAction("next");
    submitForm();
  }

  function onSaveAndExit() {
    setFormAction("exit");
    submitForm();
  }

  return (
    <ParkAddShell
      loading={isSubmitting}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: !isValid || isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: !isValid || isSubmitting,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      <FormHeading title={t("park:placeEntrancePin")} />

      {formError && <FormError title={formError} />}

      {parkLocation && (
        <FieldMap
          height={`80%`}
          location={parkLocation}
          onSelect={(location) => setFieldValue("location", location)}
        />
      )}
    </ParkAddShell>
  );
}
