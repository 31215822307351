import classNames from "@/utils/cx";
import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";

interface Option {
  name: string;
  value: string;
  description?: string;
  badge?: React.ReactNode;
}

interface Props {
  options: Option[];
  selectedOptionValue?: string;
  onSelect: (option: Option) => void;
}

export default function FieldRadiosGroup({
  options,
  selectedOptionValue,
  onSelect,
}: Props) {
  const [selected, setSelected] = useState<Option>(options[0]);

  useEffect(() => {
    if (!selectedOptionValue) return;

    const selectedOption = options.find(
      (option) => option.value === selectedOptionValue
    );

    if (selectedOption) setSelected(selectedOption);
  }, [selectedOptionValue, options]);

  return (
    <RadioGroup value={selected} onChange={onSelect}>
      <div className="-space-y-px rounded-md bg-white">
        {options.map((option, index) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ checked }) =>
              classNames(
                index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                index === options.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "z-10 border-rapide-600/50 bg-rapide-600/5"
                  : "border-gray-200",
                "relative flex cursor-pointer border p-4 focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <div className="flex flex-1 items-center justify-between">
                <div className="flex items-center justify-between">
                  <span
                    className={classNames(
                      checked
                        ? "border-transparent bg-rapide-600"
                        : "border-gray-300 bg-white",
                      active ? "ring-2 ring-rapide-600 ring-offset-2" : "",
                      "mt-[-1px] flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                    )}
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>

                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? "text-rapide-600" : "text-simple-900",
                        "block text-sm font-bold"
                      )}
                    >
                      {option.name}
                    </RadioGroup.Label>

                    {option?.description && (
                      <RadioGroup.Description
                        as="span"
                        className={classNames(
                          checked
                            ? "text-cobalt-700 font-normal"
                            : "text-gray-600",
                          "block text-sm"
                        )}
                      >
                        {option.description}
                      </RadioGroup.Description>
                    )}
                  </span>
                </div>

                {option?.badge}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
