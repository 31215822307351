type Holiday = { name: { fr: string; en: string }; date: Date };

function getHolidays(exceptionsDates: (Date | null)[]): Holiday[] {
  const holidays = holidaysData;

  const filteredHolidays: Holiday[] = [];

  holidays.forEach((holiday) => {
    let alreadyBlocked = false;
    const isInPast = holiday.date < new Date();

    if (!isInPast) {
      exceptionsDates.forEach((exceptionDate) => {
        if (
          exceptionDate &&
          holiday.date.getDay() === exceptionDate.getDay() &&
          holiday.date.getMonth() === exceptionDate.getMonth() &&
          holiday.date.getFullYear() === exceptionDate.getFullYear()
        ) {
          alreadyBlocked = true;
        }
      });

      if (!alreadyBlocked) {
        filteredHolidays.push(holiday);
      }
    }
  });

  return [...new Set(filteredHolidays)];
}

const holidaysData: Holiday[] = [
  {
    name: {
      fr: "Journée des patriotes",
      en: "Patriots' Day",
    },
    date: new Date("2024-05-20T00:00:00"),
  },
  {
    name: {
      fr: "Fête nationale du Québec",
      en: "Quebec National Day",
    },
    date: new Date("2024-06-24T00:00:00"),
  },
  {
    name: { fr: "Fête du Canada", en: "Canada Day" },
    date: new Date("2024-07-01T00:00:00"),
  },
  {
    name: { fr: "Fête du travail", en: "Labor Day" },
    date: new Date("2024-09-02T00:00:00"),
  },
  {
    name: { fr: "Action de Grâce", en: "Thanksgiving" },
    date: new Date("2024-10-14T00:00:00"),
  },
  {
    name: { fr: "Veille de Noël", en: "Christmas Eve" },
    date: new Date("2024-12-24T00:00:00"),
  },
  {
    name: { fr: "Noël", en: "Christmas" },
    date: new Date("2024-12-25T00:00:00"),
  },
  {
    name: { fr: "Lendemain de Noël", en: "Boxing Day" },
    date: new Date("2024-12-26T00:00:00"),
  },
  {
    name: { fr: "Veille du jour de l'an", en: "New Year's Eve" },
    date: new Date("2024-12-31T00:00:00"),
  },
  {
    name: { fr: "Jour de l'an", en: "New Year's Day" },
    date: new Date("2025-01-01T00:00:00"),
  },
  {
    name: { fr: "Lendemain du jour de l'an", en: "Day after New Year's Day" },
    date: new Date("2025-01-02T00:00:00"),
  },
  {
    name: { fr: "Vendredi Saint", en: "Good Friday" },
    date: new Date("2025-04-18T00:00:00"),
  },
  {
    name: { fr: "Lundi de Pâques", en: "Easter Monday" },
    date: new Date("2025-04-21T00:00:00"),
  },
];

export default getHolidays;
