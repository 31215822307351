import Loader from "@/components/layout/Loader";
import Spacer from "@/components/Spacer";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import HelpPanel from "@/containers/HelpPanel";
import OnboardingMenu, {
  OnboardingMenuItem,
} from "@/containers/OnboardingMenu";
import { TOTAL_NUMBER_OF_STEP } from "@/contexts/ParkAddContext";
import useEntryAnimation from "@/hooks/animation/useEntryAnimation";
import useParkAdd from "@/hooks/context/useParkAdd";
import useOnLogout from "@/hooks/use-on-logout.hook";
import {
  getAltLanguageLabel,
  getLanguage,
  switchLanguage,
} from "@/utils/language";
import { getBifrostEmail, setBifrostSkipOnboarding } from "@/utils/storage";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Action {
  visible: boolean;
  disabled: boolean;
  onClick: () => void;
}

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  displayFooter?: boolean;
  actions: Partial<{
    exit: Partial<Action>;
    saveAndExit: Partial<Action>;
    next: Partial<Action> & { submit?: boolean };
    back: Partial<Action>;
  }>;
}

export default function ParkAddShell({
  children,
  loading = false,
  displayFooter = true,
  actions = {
    exit: {
      visible: false,
      disabled: false,
      onClick: () => {},
    },
    saveAndExit: {
      visible: false,
      disabled: false,
      onClick: () => {},
    },
    next: {
      visible: false,
      disabled: false,
      onClick: () => {},
      submit: false,
    },
    back: {
      visible: false,
      disabled: false,
      onClick: () => {},
    },
  },
}: Props) {
  const navigate = useNavigate();
  const logout = useOnLogout();

  const { launchEntryAnimation, animatingEntry, entryAnimationClassNames } =
    useEntryAnimation({
      entryAnimationDelay: 250,
    });

  useEffect(() => {
    launchEntryAnimation();
  }, []);

  const { t } = useTranslation(["park", "common"]);
  const { step, canGoBack, canGoNext, onboardingComplete } = useParkAdd();
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const menuItems: OnboardingMenuItem[] = [
    {
      name: t("common:switchToLanguage", {
        language: getAltLanguageLabel(false),
      }),
      onClick: () => {
        switchLanguage();
        setSubmenuOpen(false);
      },
    },
  ];

  if (onboardingComplete && actions?.exit?.visible) {
    menuItems.push({
      name: t("common:exit"),
      disabled: actions.exit.disabled,
      onClick: () => {
        if (
          !actions?.exit?.disabled &&
          typeof actions?.exit?.onClick === "function"
        ) {
          actions.exit.onClick();
        }

        setSubmenuOpen(false);
      },
    });
  }

  if (onboardingComplete && actions?.saveAndExit?.visible) {
    menuItems.push({
      name: t("common:saveAndExit"),
      disabled: actions?.saveAndExit?.disabled,
      onClick: () => {
        if (
          !actions?.saveAndExit?.disabled &&
          typeof actions?.saveAndExit?.onClick === "function"
        ) {
          actions.saveAndExit.onClick();
        }

        setSubmenuOpen(false);
      },
    });
  }

  menuItems.push({
    name: t("common:logout"),
    onClick: logout,
  });

  if (getBifrostEmail()) {
    menuItems.push({
      name: t("common:bifrostLeave"),
      onClick: () => {
        setBifrostSkipOnboarding();
        navigate("/");
      },
    });
  }

  const headerClassName = "h-[85px] md:h-[92px] px-5";
  const footerClassName = "h-[85px] md:h-[94px] px-5";
  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-85px-44px)] md:h-[calc(100dvh-92px-94px-44px)]"
    : "h-[calc(100dvh-85px-85px)] md:h-[calc(100dvh-92px-94px)]";

  return (
    <div className="flex h-[100dvh] flex-col bg-white">
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <header
        className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
      >
        <img
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <div className="flex items-center justify-between">
          <Button
            className="mr-3"
            variant="outline"
            onClick={() => setHelpPanelOpen(true)}
          >
            <div className="flex items-center">{t("common:help")}</div>
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <div className="flex items-center">
                {t("common:menu")}
                <IconChevronDown
                  className={`ml-1 h-[20px] w-[20px] ${
                    submenuOpen ? "rotate-180" : ""
                  } transition-all`}
                />
              </div>
            </Button>

            {submenuOpen && (
              <div className="absolute right-0 top-[50px] z-30">
                <OnboardingMenu items={menuItems} />
              </div>
            )}
          </div>
        </div>
      </header>

      <main
        className={`${mainClassName} relative overflow-hidden bg-white opacity-0 ${
          animatingEntry && entryAnimationClassNames
        }`}
      >
        <div className="h-full overflow-auto">
          <div className="mx-auto h-full md:max-w-[500px]">
            <div className="h-full px-6 py-8 md:px-6 md:py-12">
              {children} <Spacer />
            </div>
          </div>
        </div>
      </main>

      {displayFooter && (
        <footer
          className={`${footerClassName} relative z-20 mx-auto flex w-full items-center justify-center border-t border-silver-800 bg-white pb-[5px] sm:static md:px-0`}
        >
          <div
            className={`flex w-full items-center md:max-w-[500px] justify-${
              actions?.next?.visible && actions?.back?.visible
                ? "between"
                : "center"
            }`}
          >
            {actions?.back?.visible && (
              <Button
                size="lg"
                variant="outline"
                onClick={
                  actions.back.disabled ? () => {} : actions.back.onClick
                }
                disabled={actions.back.disabled || !canGoBack}
              >
                {t("common:back")}
              </Button>
            )}
            {actions?.next?.visible && (
              <Button
                size="lg"
                onClick={
                  actions.next.disabled ? () => {} : actions.next.onClick
                }
                disabled={
                  actions.next.disabled ||
                  (step < TOTAL_NUMBER_OF_STEP && !canGoNext)
                }
              >
                {t(actions.next.submit ? "common:submit" : "common:continue")}
              </Button>
            )}
          </div>
        </footer>
      )}

      {loading && <Loader />}

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />
    </div>
  );
}
