import FieldTextarea from "@/components/form/fields/FieldTextarea";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export default function FormParkDescription() {
  const { park } = useParkEdit();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues = {
    descriptionFr: park?.localizedDirections?.fr || "",
    descriptionEn: park?.localizedDirections?.en || "",
  };

  const validationSchema: unknown = Yup.object().shape({
    descriptionFr: Yup.string().required(t("validation:descriptionRequired")),
    descriptionEn: Yup.string().required(t("validation:descriptionRequired")),
  });

  const {
    submitForm,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async () => {
      if (!park) return;

      setFormError(undefined);
      setFormSuccess(false);

      try {
        await updateParkAsync({
          parkId: park.objectId,
          updates: {
            localizedDirections: [
              {
                languageCode: "fr",
                localizedString: values.descriptionFr.trim(),
              },
              {
                languageCode: "en",
                localizedString: values.descriptionEn.trim(),
              },
              {
                languageCode: "default",
                localizedString: values.descriptionFr.trim(),
              },
            ],
          },
        });

        setFormSuccess(true);
        setFormError(undefined);

        setTimeout(() => {
          setFormSuccess(false);
        }, 5000);
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:howToGetThere")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:editDirectionsDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField key="directionsFr">
                <FieldTextarea
                  label={t("park:directionsFr")}
                  name="descriptionFr"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descriptionFr}
                  error={
                    (touched.descriptionFr && errors.descriptionFr) || undefined
                  }
                  style={{ minHeight: 100 }}
                />
              </FormField>,
              <FormField key="directionsEn">
                <FieldTextarea
                  label={t("park:directionsEn")}
                  name="descriptionEn"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descriptionEn}
                  error={
                    (touched.descriptionEn && errors.descriptionEn) || undefined
                  }
                  style={{ minHeight: 100 }}
                />
              </FormField>,
            ]}
          />
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
