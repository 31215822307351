import Loader from "@/components/layout/Loader";

interface Props {
  loading?: boolean;
  title: string;
  stats: {
    name: string;
    value: string;
  }[];
}

export default function Statistics({ loading, title, stats }: Props) {
  return (
    <div>
      {loading && <Loader />}

      <h3 className="text-base font-semibold leading-6 text-simple-900">
        {title}
      </h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow ring-1 ring-black ring-opacity-5 sm:p-6"
          >
            <dt className="font-regular truncate text-sm text-gray-600">
              {item.name || ""}
            </dt>
            <dd className="mt-3 text-4xl font-semibold tracking-tight text-simple-900">
              {item.value || ""}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
