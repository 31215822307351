import classNames from "@/utils/cx";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { MdEmail } from "react-icons/md";

export interface OnboardingMenuItem {
  name: string;
  onClick?: () => void;
  current?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
}

interface Props {
  items: OnboardingMenuItem[];
}

export default function OnboardingMenu({ items }: Props) {
  const { t } = useTranslation(["common"]);

  return (
    <nav className="flex w-[200px] flex-1 flex-col rounded-md bg-white shadow ring-1 ring-black ring-opacity-5">
      <ul role="list" className="divide-y divide-silver-800">
        {items.map((item) => (
          <li
            key={item.name}
            onClick={item.onClick}
            className={classNames(
              item.current
                ? "bg-gray-50 text-rapide-600"
                : "text-gray-700 hover:bg-gray-50 hover:text-rapide-600",
              item.disabled
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer",
              "group flex cursor-pointer items-center px-5 pb-2.5 pt-2.5 text-sm font-semibold leading-6 transition duration-150 ease-in-out first:rounded-tl-md first:rounded-tr-md last:rounded-bl-none last:rounded-br-none"
            )}
          >
            {item.icon}
            {item.name}
          </li>
        ))}
      </ul>

      <div className="border-t border-t-silver-800 bg-silver-900/75 py-3">
        <div className="mb-2 pl-5 text-sm font-semibold">
          {t("common:help")}
        </div>
        <ul role="list">
          <li className="py-1 pl-5 text-xs">
            <a
              className="flex items-center hover:text-rapide-600"
              href="tel:18559797275"
            >
              <PhoneIcon className="mr-2 w-[12px] text-rapide-600" />
              <strong>1-855-979-7275</strong>
            </a>
          </li>
          <li className="py-1 pl-5 text-xs">
            <a
              className="flex items-center hover:text-rapide-600"
              href="mailto:info@clicknpark.com"
            >
              <MdEmail className="mr-2 text-sm text-rapide-600" />
              <strong>info@clicknpark.com</strong>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
