interface Props {
  title: string;
  subtitle?: string;
  marginBottom?: "sm" | "md" | "lg";
}

export default function FormHeading({
  title,
  subtitle,
  marginBottom = "md",
}: Props) {
  let marginBottomClassName = "";

  switch (marginBottom) {
    case "sm":
      marginBottomClassName = "mb-3";
      break;
    case "md":
      marginBottomClassName = "mb-5";
      break;
    case "lg":
      marginBottomClassName = "mb-8";
      break;
  }

  return (
    <div className={marginBottomClassName}>
      {title && (
        <h2 className="text-xl font-bold leading-6 text-rapide-600">{title}</h2>
      )}

      {subtitle && (
        <p className="mt-3 text-sm leading-6 text-gray-600">{subtitle}</p>
      )}
    </div>
  );
}
