interface Props {
  h?: 3 | 5 | 10 | 20 | 30;
}

export default function Spacer({ h = 5 }: Props) {
  let className = "";

  switch (h) {
    case 3:
      className = "h-3";
      break;
    case 5:
      className = "h-5";
      break;
    case 10:
      className = "h-10";
      break;
    case 20:
      className = "h-20";
      break;
    case 30:
      className = "h-30";
      break;
  }

  return <div className={className} />;
}
