import cn from "@/utils/cn.utils";
import { useEffect, useRef, useState } from "react";

interface Props {
  onSubmit: (imageDataUrl: string) => void;
}

export default function PlateCheckerRequestModeImage({ onSubmit }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: "environment" },
        },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setMediaStream(stream);
    } catch (error) {
      console.error("Error accessing webcam", error);
    }
  };

  const stopWebcam = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  useEffect(() => {
    startWebcam();
    return () => {
      stopWebcam();
    };
  }, []);

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context && video.videoWidth && video.videoHeight) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL("image/jpeg");
        onSubmit(imageDataUrl);
      }
    }
  };

  const [buttonPressed, setButtonPressed] = useState(false);

  return (
    <div className="mb-5 w-full bg-black md:mb-0">
      <div className="relative mx-auto w-full max-w-md">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          className="h-auto max-h-[40vh] min-h-[278px] w-full object-cover"
        />
        <canvas ref={canvasRef} className="hidden" />

        {mediaStream ? (
          <button
            onClick={captureImage}
            onTouchStart={() => setButtonPressed(true)}
            onTouchEnd={() => setButtonPressed(false)}
            className={cn(
              "absolute bottom-5 left-1/2 h-[65px] w-[65px] -translate-x-1/2 transform cursor-pointer rounded-full border-[6px] border-black shadow-md ring-[3px] ring-white",
              buttonPressed ? "bg-white/50" : "bg-white"
            )}
          />
        ) : null}
      </div>
    </div>
  );
}
