import { CPError, CPErrorCode } from "../errors/CPError";

export class CPException extends Error {
  public status?: string;
  public code: CPErrorCode;
  public ref?: string;

  public constructor(error: CPError) {
    super(error.message);
    this.code = error.code;
    this.ref = error.ref;
    this.status = error.status;
  }
}
