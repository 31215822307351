import { FCP_VehicleSize } from "@/@types/park";
import FieldRadiosCards from "@/components/form/fields/FieldRadiosCards";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import { CPException } from "@/models/exceptions/CPException";
import { getCurrentPark } from "@/utils/storage";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ParkAddVehicleSize() {
  const { t } = useTranslation(["park", "validation"]);

  const [formError, setFormError] = useState<string | undefined>();
  const [formAction, setFormAction] = useState<"next" | "exit">("next");
  const { next, back, exit, updatePark } = useParkAdd();
  const park = getCurrentPark();

  const initialValues: {
    maxVehicleSize: FCP_VehicleSize;
  } = {
    maxVehicleSize: park?.maxVehicleSize || "small",
  };

  const { values, submitForm, isSubmitting, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setFormError(undefined);

      try {
        await updatePark({
          maxVehicleSize: values.maxVehicleSize,
        });

        formAction === "next" ? next() : exit();
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  function onBack() {
    back();
  }

  function onNext() {
    setFormAction("next");
    submitForm();
  }

  function onSaveAndExit() {
    setFormAction("exit");
    submitForm();
  }

  return (
    <ParkAddShell
      loading={isSubmitting}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: isSubmitting,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      {formError && <FormError title={formError} />}

      <FormFields
        fields={[
          <FormField key="maxVehicleSize">
            <FormHeading title={t("park:maxVehicleSize")} />
            <FieldRadiosCards
              onSelect={(option) =>
                setFieldValue("maxVehicleSize", option.value)
              }
              selectedOptionValue={values.maxVehicleSize}
              options={[
                {
                  name: t("park:small"),
                  description: t("park:smallExample"),
                  value: "small",
                  image: (
                    <img
                      src="/images/vehicles/small/small.png"
                      width={75}
                      className="mt-2"
                    />
                  ),
                },
                {
                  name: t("park:medium"),
                  description: t("park:mediumExample"),
                  value: "medium",
                  image: (
                    <img
                      src="/images/vehicles/small/medium.png"
                      width={92}
                      className="mt-2"
                    />
                  ),
                },
                {
                  name: t("park:suv"),
                  description: t("park:suvExample"),
                  value: "suv",
                  image: (
                    <img
                      src="/images/vehicles/small/suv.png"
                      width={85}
                      className="mt-2"
                    />
                  ),
                },
                {
                  name: t("park:van"),
                  description: t("park:vanExample"),
                  value: "van",
                  image: (
                    <img
                      src="/images/vehicles/small/large.png"
                      width={95}
                      className="mt-1"
                    />
                  ),
                },
              ]}
            />
          </FormField>,
        ]}
      />
    </ParkAddShell>
  );
}
