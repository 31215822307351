import { useEffect, useState } from "react";

interface Option {
  name: string;
  value: string;
  description?: string;
  badge?: React.ReactNode;
}

interface Props {
  options: Option[];
  selectedValues?: string[];
  onCheck: (option: Option) => void;
  onUncheck: (option: Option) => void;
  direction?: "row" | "column";
}

export default function FieldCheckboxesInline({
  options,
  selectedValues,
  onCheck,
  onUncheck,
  direction = "row",
}: Props) {
  const [selected, setSelected] = useState<Option[]>([]);
  const [rand] = useState(Math.random().toString(36).substring(7));

  useEffect(() => {
    if (!selectedValues) return;

    const selectedOptions = options.filter((option) =>
      selectedValues.includes(option.value)
    );

    setSelected(selectedOptions);
  }, [selectedValues, options]);

  const handleSelect = (option: Option) => {
    if (selected.find((o) => o.value === option.value)) {
      setSelected(selected.filter((o) => o.value !== option.value));
      onUncheck(option);
    } else {
      setSelected([...selected, option]);
      onCheck(option);
    }
  };

  return (
    <div>
      <fieldset className="mt-4">
        <div
          className={` sm:flex ${
            direction === "row"
              ? "flex-row space-y-4 sm:items-center sm:space-x-5 sm:space-y-0"
              : "flex-col space-y-1"
          }`}
        >
          {options.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                id={option.value + rand}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 text-rapide-600 focus:ring-rapide-600"
                onChange={() => handleSelect(option)}
                checked={selectedValues?.includes(option.value)}
              />
              <label
                htmlFor={option.value + rand}
                className="ml-2 mt-[1px] block text-sm font-semibold leading-6 text-simple-900"
              >
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
