import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";
import {
  CPPlateCheckerOwnerResult,
  CPPlateCheckerPark,
  CPPlateCheckerReservation,
  CPPlateCheckerVehicle,
} from "@clicknpark/sdk";
import i18next from "i18next";

export function isVehicleWhitelistedOnCurrentPark(
  parkId: string,
  whitelist: CPPlateCheckerPark[]
) {
  return whitelist.some((p) => p.id === parkId);
}

export function userHasAssociatedWrongPlateToReservation(
  request: CPPlateCheckerOwnerResult
) {
  let hasWrongPlate = false;
  const reservations = [
    ...request.currentReservations,
    ...request.upcomingReservations,
  ];

  // is there a reservation with a different plate that belongs to the user?
  for (const reservation of reservations) {
    if (reservation?.vehicle?.plate !== request.vehicle.plate) {
      hasWrongPlate = true;
      break;
    }
  }

  if (!hasWrongPlate) {
    // is there a reservation without a vehicle?
    for (const reservation of reservations) {
      if (!reservation?.vehicle) {
        hasWrongPlate = true;
        break;
      }
    }
  }

  return {
    hasWrongPlate,
    plate: request.vehicle.plate,
  };
}

export function isWithinRange(startDate: Date, endDate: Date) {
  const now = new Date();
  return now >= startDate && now <= endDate;
}

export function hasAtLeastOneReservationWithinRange(
  reservations: CPPlateCheckerReservation[]
) {
  return reservations.some((reservation) =>
    isWithinRange(
      new Date(reservation.startDate),
      new Date(reservation.endDate)
    )
  );
}

export function getRequestResultValidity(results: CPPlateCheckerOwnerResult[]) {
  let validity: PlateCheckerTypes.RequestResultValidity | undefined;

  let reservations: CPPlateCheckerReservation[] = [];

  for (const result of results) {
    reservations.push(
      ...result.currentReservations,
      ...result.upcomingReservations
    );
  }

  if (hasAtLeastOneReservationWithinRange(reservations)) {
    validity = PlateCheckerTypes.RequestResultValidity.PlateAllowed;
  } else if (reservations.length > 0) {
    validity = PlateCheckerTypes.RequestResultValidity.PlateDiscretionary;
  } else {
    validity = PlateCheckerTypes.RequestResultValidity.PlateDisallowed;
  }

  return validity;
}

export function base64ToFile(
  base64: string,
  filename: string,
  mimeType: string
): File {
  const byteString = atob(base64.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new File([uint8Array], filename, { type: mimeType });
}

export function getVehicleName(vehicle: CPPlateCheckerVehicle) {
  let vehicleName = "";

  if (vehicle.model) {
    vehicleName = `${vehicle.model.make} ${vehicle.model.model}`;
  } else if (vehicle.noModelString) {
    vehicleName = vehicle.noModelString;
    if (
      vehicle.noModelString === "_UNKNOWN_" ||
      vehicle.noModelString.trim() === ""
    ) {
      vehicleName = i18next.t("plateChecker:unknownVehicle");
    }
  }

  return vehicleName;
}
