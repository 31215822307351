import NavigationContext from "@/contexts/NavigationContext";
import classNames from "@/utils/cx";
import { getLanguage } from "@/utils/language";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  menuOpen: boolean;
  closeMenu: VoidFunction;
}

export default function MobileMenu({ menuOpen, closeMenu }: Props) {
  const { primaryNavItems, secondaryNavItems } = useContext(NavigationContext);

  return (
    <Transition.Root show={menuOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-simple-900/90" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={closeMenu}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    className="mb-1 mt-6 h-[32px] w-[175px]"
                    src={`/images/layout/logo-${getLanguage()}-CA.svg`}
                    alt="clicknpark"
                  />
                </div>

                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {primaryNavItems.map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item.href}
                              onClick={closeMenu}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? "bg-silver-900 text-rapide-600"
                                    : "text-gray-700 hover:bg-silver-900 hover:text-rapide-600",
                                  "group flex gap-x-3 rounded-full p-2 text-sm font-semibold leading-6"
                                )
                              }
                            >
                              {item.icon}
                              {item.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>

                  <li className="-mx-6 mt-auto">
                    <ul role="list" className="mx-4 mb-4 space-y-1">
                      {secondaryNavItems.map((item) => (
                        <li key={item.name}>
                          <span
                            onClick={item.onClick}
                            className={
                              "group flex gap-x-3 rounded-full p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-silver-900 hover:text-rapide-600"
                            }
                          >
                            {item.icon}
                            {item.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </li>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
