import Tabs from "@/components/layout/Tabs";
import Wrapper from "@/components/layout/Wrapper";
import { HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  actions?: React.ReactNode[];
  breadcrumbs?: {
    name: string;
    href: string;
    current: boolean;
  }[];
  tabs?: {
    name: string;
    href: string;
    current: boolean;
  }[];
}

export default function PageHeader({
  title,
  actions,
  breadcrumbs,
  tabs,
}: Props) {
  return (
    <>
      <div
        className={`pt-8 sm:pt-14 ${
          typeof tabs === "undefined" && "pb-5 sm:pb-12"
        } border-b border-b-silver-800 bg-silver-900`}
      >
        <Wrapper>
          <div className="flex items-center justify-between">
            <div>
              <div>
                {breadcrumbs && (
                  <nav className="mb-3 flex items-center">
                    <ol role="list" className="flex space-x-1">
                      <li className="flex">
                        <div className="flex items-center">
                          <Link
                            to="/"
                            className="text-gray-400 hover:text-gray-600"
                          >
                            <HomeIcon
                              className="h-5 w-5 flex-shrink-0"
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </li>
                      {breadcrumbs.map((breadcrumb) => (
                        <li key={breadcrumb.name} className="flex">
                          <div className="flex items-center">
                            <svg
                              className="h-5 w-5 flex-shrink-0 text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <Link
                              to={breadcrumb.href}
                              className="ml-1 text-sm font-semibold text-gray-600 hover:text-gray-700"
                            >
                              {breadcrumb.name}
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </nav>
                )}

                <h2 className="font-heading text-2xl font-extrabold leading-7 text-simple-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  {title}
                </h2>
              </div>
            </div>

            {actions?.map((action, index) => (
              <div key={index} className="ml-3">
                {action}
              </div>
            ))}
          </div>
        </Wrapper>

        {tabs && <Tabs tabs={tabs} />}
      </div>
    </>
  );
}
