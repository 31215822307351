import PageHeader from "@/components/layout/PageHeader";
import Wrapper from "@/components/layout/Wrapper";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdCheckCircle, MdClose } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

export default function EmailVerification() {
  const { t } = useTranslation(["common"]);
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  return (
    <div>
      <PageHeader title={t("common:emailVerification")} />

      <Wrapper>
        <div className="flex items-center">
          {status === "verified" ? (
            <MdCheckCircle size={20} className="mr-1 text-green-500" />
          ) : (
            <MdClose size={20} className="mr-1 text-red-500" />
          )}

          <p className="text-lg font-semibold">
            {t(
              `common:${
                status === "verified"
                  ? "emailVerified"
                  : "invalidEmailVerificationLink"
              }`
            )}
          </p>
        </div>
      </Wrapper>
    </div>
  );
}
