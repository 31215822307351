import { FCP_Address, FCP_PriceIndicator } from "@/@types/park";
import { getLanguage } from "@/utils/language";

export function prependZero(number: number): string {
  if (number < 10) return `0${number}`;
  return number.toString();
}

export function formatPhone(value: string): string {
  if (!value) return value;
  const phone = value.replace(/[^\d]/g, "");
  const phoneLength = phone.length;
  if (phoneLength < 4) return phone;
  if (phoneLength < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
}

export function formatAddress(
  a: FCP_Address,
  format: "short" | "long" = "short"
): string {
  const short = `${(a && a.streetNumber + " ") || " "}${a && a.street}`;
  if (format === "short") return short;
  else
    return `${short ? short + ", " : ""}${a && a.city ? a.city + ", " : " "}${
      a && a.state ? a.state + ", " : " "
    }${a && a.country ? a.country + ", " : " "}${(a && a.zip) || ""}`;
}

export function getFormattedPrice(
  price: number,
  countryCode: string,
  currency: string
) {
  if (!price) return "-";

  const formatter = new Intl.NumberFormat(`${getLanguage()}-${countryCode}`, {
    style: "currency",
    currency,
  });

  return formatter.format(price);
}

export function getFormattedPriceForTimeUnit(
  timeUnit: string,
  price: number,
  countryCode: string,
  currency: string,
  priceIndicator?: FCP_PriceIndicator
): string {
  if (!price) return "-";
  if (!priceIndicator) return "-";

  const formatter = new Intl.NumberFormat(`${getLanguage()}-${countryCode}`, {
    style: "currency",
    currency,
  });

  if (timeUnit === "hour") {
    return formatter.format(price);
  }

  if (timeUnit === "5hours") {
    if (
      priceIndicator.factorsForAnchors &&
      priceIndicator.factorsForAnchors.fiveHours
    ) {
      return formatter.format(price * priceIndicator.factorsForAnchors.fiveHours);
    }
    else return formatter.format(price * 5);
  }

  if (timeUnit === "24hours") {
    if (
      priceIndicator.factorsForAnchors &&
      priceIndicator.factorsForAnchors.day
    ) {
      return formatter.format(price * priceIndicator.factorsForAnchors.day);
    }
  }

  if (timeUnit === "week") {
    if (
      priceIndicator.factorsForAnchors &&
      priceIndicator.factorsForAnchors.week
    ) {
      return formatter.format(price * priceIndicator.factorsForAnchors.week);
    }
  }

  if (timeUnit === "month") {
    if (
      priceIndicator.factorsForAnchors &&
      priceIndicator.factorsForAnchors.month
    ) {
      return formatter.format(price * priceIndicator.factorsForAnchors.month);
    }
  }

  return "-";
}

export function formatStripeAddress(address: {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}) {
  const { line1, line2, city, state, country, postal_code } = address;
  return `${line1 || ""} ${line2 || ""}, ${city || ""}, ${state || ""} ${
    country || ""
  } ${postal_code || ""}`;
}
