import { CPOnSitePaymentMethod } from "@/@types/park";
import FieldCheckboxesCards from "@/components/form/fields/FieldCheckboxesCards";
import FieldCheckboxesInline from "@/components/form/fields/FieldCheckboxesInline";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import {
  convertArrayToFeatures,
  convertFeaturesToArray,
} from "@/helpers/park.helpers";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import { getBifrostEmail } from "@/utils/storage";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormParkFeatures() {
  const { park } = useParkEdit();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues: {
    features: string[];
    maximumHeight?: number;
    onSitePaymentMethods: CPOnSitePaymentMethod[];
  } = {
    features: park?.featuresV3 ? convertFeaturesToArray(park.featuresV3) : [],
    maximumHeight: park?.featuresV3?.maximumHeight
      ? park.featuresV3.maximumHeight
      : undefined,
    onSitePaymentMethods: park?.featuresV3?.onSitePaymentMethods
      ? park.featuresV3.onSitePaymentMethods
      : [],
  };

  const {
    values,
    submitForm,
    isSubmitting,
    setFieldValue,
    isValid,
    handleChange,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (!park) return;

      setFormError(undefined);
      setFormSuccess(false);

      try {
        await updateParkAsync({
          parkId: park.objectId,
          updates: {
            featuresV3: {
              ...park.featuresV3,
              ...convertArrayToFeatures(values.features),
              maximumHeight: values.maximumHeight,
              onSitePaymentMethods: values.onSitePaymentMethods,
            },
          },
        });

        setFormSuccess(true);
        setFormError(undefined);

        setTimeout(() => {
          setFormSuccess(false);
        }, 5000);
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  const ExtraBifrostOptions = getBifrostEmail() ? (
    <div className="rounded-xl border border-rapide-600 px-10 py-5">
      <div className="mb-3 mt-5">
        <p className="text-md font-semibold leading-5 text-indigo-500">
          {t("common:extraOptionsForBifrostAgent")}
        </p>
      </div>

      <FormFields
        fields={[
          <FormField key="allowsMultiUse">
            <FieldCheckboxesCards
              selectedValues={values.features}
              onCheck={(option) =>
                setFieldValue("features", [...values.features, option.value])
              }
              onUncheck={(option) =>
                setFieldValue(
                  "features",
                  values.features.filter((feature) => feature !== option.value)
                )
              }
              options={[
                {
                  name: t("park:multiUse"),
                  description: t("park:multiUseDescription"),
                  value: "allowsMultiUse",
                },
                {
                  name: t("park:allowsStuddedTires"),
                  description: t("park:allowsStuddedTiresDescription"),
                  value: "allowsStuddedTires",
                },
                {
                  name: t("park:hasCarWash"),
                  description: t("park:hasCarWashDescription"),
                  value: "hasCarWash",
                },
              ]}
            />
          </FormField>,
          <FormField key="onSitePaymentMethods">
            <div className="my-3 border-b border-b-silver-800 pb-6">
              <p className="text-sm font-semibold leading-5">
                {t("park:onSitePaymentMethods")}
              </p>
              <FieldCheckboxesInline
                direction="column"
                options={[
                  {
                    name: "American Express",
                    value: "amex",
                  },
                  {
                    name: "Visa",
                    value: "visa",
                  },
                  {
                    name: "Mastercard",
                    value: "mastercard",
                  },
                  {
                    name: "Interact",
                    value: "interact",
                  },
                  {
                    name: "Cash",
                    value: "cash",
                  },
                ]}
                selectedValues={values.onSitePaymentMethods}
                onCheck={(option) => {
                  setFieldValue("onSitePaymentMethods", [
                    ...values.onSitePaymentMethods,
                    option.value,
                  ]);
                }}
                onUncheck={(option) => {
                  setFieldValue(
                    "onSitePaymentMethods",
                    values.onSitePaymentMethods.filter(
                      (method) => method !== option.value
                    )
                  );
                }}
              />
            </div>
          </FormField>,
          <FormField key="maximumHeight">
            <FieldText
              name="maximumHeight"
              label={t("park:maximumHeight")}
              value={values.maximumHeight}
              onChange={handleChange}
              type="number"
              step="0.01"
            />
          </FormField>,
        ]}
      />
    </div>
  ) : null;

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:features")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:editFeaturesDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField key="features">
                <FieldCheckboxesCards
                  selectedValues={values.features}
                  onCheck={(option) =>
                    setFieldValue("features", [
                      ...values.features,
                      option.value,
                    ])
                  }
                  onUncheck={(option) =>
                    setFieldValue(
                      "features",
                      values.features.filter(
                        (feature) => feature !== option.value
                      )
                    )
                  }
                  options={[
                    {
                      name: t("park:wide"),
                      description: t("park:wideDescription"),
                      value: "wideness",
                      image: <img src="/images/features/wide.svg" width={50} />,
                    },
                    {
                      name: t("park:video"),
                      description: t("park:videoDescription"),
                      value: "hasCCTV",
                      image: (
                        <img src="/images/features/video.svg" width={50} />
                      ),
                    },
                    {
                      name: t("park:onsiteStaff"),
                      description: t("park:onsiteStaffDescription"),
                      value: "hasStaff",
                      image: (
                        <img
                          src="/images/features/onsiteStaff.svg"
                          width={50}
                          className="mt-1"
                        />
                      ),
                    },
                  ]}
                />
              </FormField>,
            ]}
          />

          {ExtraBifrostOptions}
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
