import { version } from "@/../package.json";
import NavigationContext from "@/contexts/NavigationContext";
import classNames from "@/utils/cx";
import { getLanguage } from "@/utils/language";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

export default function ShellSidebarDesktop() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { primaryNavItems, secondaryNavItems } = useContext(NavigationContext);

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-silver-800 bg-white px-6">
        <img
          onClick={() => navigate("/")}
          className="mb-1 mt-6 h-[32px] w-[175px] cursor-pointer"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <nav className="flex flex-1 flex-col">
          <strong className="mb-3 text-xs font-bold">
            {t("common:dashboard")}
          </strong>
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {primaryNavItems.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? "bg-silver-900 text-rapide-600"
                            : "text-gray-700 hover:bg-silver-900 hover:text-rapide-600",
                          "group flex cursor-pointer gap-x-3 rounded-full p-2 text-sm font-semibold leading-6 transition-all"
                        )
                      }
                    >
                      {item.icon}
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>

            <li className="-mx-6 mt-auto">
              <ul role="list" className="mx-4 mb-4 space-y-1">
                {secondaryNavItems.map((item) => (
                  <li key={item.name}>
                    <span
                      onClick={item.onClick}
                      className={
                        "group flex cursor-pointer gap-x-3 rounded-full p-2 text-sm font-semibold leading-6 text-gray-700 transition-all hover:bg-silver-900 hover:text-rapide-600"
                      }
                    >
                      {item.icon}
                      {item.name}
                    </span>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <p className="mt-0 border-r border-r-silver-800 pb-4 pl-6 text-[10px] text-silver-600">
        v{version}
      </p>
    </div>
  );
}
