import Loader from "@/components/layout/Loader";
import useOnboarding from "@/hooks/useOnboarding";
import { useGetUserQuery, useUpdateUserMutation } from "@/services/user";
import { getBifrostSkipOnboarding } from "@/utils/storage";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function GateOnboarding({
  children,
}: {
  children: JSX.Element;
}) {
  const navigate = useNavigate();
  const { mutateAsync: updateUserAsync } = useUpdateUserMutation();
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery();
  const {
    loading: verifyingOnboardingStatus,
    kycRequired,
    parkSubmitRequired,
  } = useOnboarding();

  const [openGate, setOpenGate] = useState(false);

  useEffect(() => {
    if (verifyingOnboardingStatus) return;
    if (isLoadingUser) return;

    if (getBifrostSkipOnboarding()) {
      setOpenGate(true);
      return;
    }

    (async () => {
      if (kycRequired || parkSubmitRequired) {
        // We verify the flag because we don't want to redirect the user to the onboarding page if they have already completed it
        // This would be possible if the user decided to delete all of his parkings
        if (!user?.uxMetadata?.onboardingComplete) {
          navigate("/onboarding", { replace: true });
          return;
        }
      } else {
        // Onboarding is completed
        if (!user?.uxMetadata?.onboardingComplete) {
          // Flag the user as having completed onboarding
          await updateUserAsync({ uxMetadata: { onboardingComplete: true } });
        }
      }

      setOpenGate(true);
    })();
  }, [
    verifyingOnboardingStatus,
    isLoadingUser,
    kycRequired,
    parkSubmitRequired,
    navigate,
  ]);

  if (!openGate) {
    return <Loader backgroundStyle="opaque" />;
  }

  return children;
}
