import { X } from "lucide-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  onChange: (text: string) => void;
  onSubmit: (text: string) => void;
}

export default function PlateCheckerRequestModeText({
  value,
  onChange,
  onSubmit,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(["plateChecker"]);

  return (
    <div className="mb-5 p-2.5 md:mb-0 md:p-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(value);
        }}
      >
        <div className="flex items-center gap-2">
          <div className="relative flex flex-1">
            <input
              ref={ref}
              required
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={t("plateChecker:enterLicensePlate")}
              className="h-[60px] w-full rounded-md border-2 border-silver-600"
              autoFocus
            />
            {value !== "" ? (
              <button
                type="button"
                className="absolute right-0 top-0 h-[60px] rounded-md px-5 font-bold"
                onClick={() => {
                  onChange("");
                  ref.current?.focus();
                }}
              >
                <X size={24} />
              </button>
            ) : null}
          </div>
          <button
            type="submit"
            className="h-[60px] flex-shrink rounded-md bg-rapide-600 px-5 font-bold text-white"
          >
            {t("plateChecker:verify")}
          </button>
        </div>
      </form>
    </div>
  );
}
