import { FCP_Park } from "@/@types/park";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

type GetParksResponse = Partial<FCP_Park>[];

export const useLazyGetParks = () => {
  const queryClient = useQueryClient();
  return async () => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyParks"],
      queryFn: async () => {
        const offset = new Date().getTimezoneOffset();
        const { data } = await api.get<GetParksResponse>(
          `/v2/users/me/connect/parkings/`,
          {
            params: {
              offset,
            },
          }
        );
        return data;
      },
    });
  };
};
