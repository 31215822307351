import { FCP_PriceIndicator } from "@/@types/park";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

interface GetPriceIndicatorsRequest {
  zip?: string;
  parkId?: string;
}

type GetPriceIndicatorsResponse = FCP_PriceIndicator;

export const useLazyGetPriceIndicators = () => {
  const queryClient = useQueryClient();
  return async ({ zip, parkId }: GetPriceIndicatorsRequest) => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyPriceIndicators" + parkId],
      queryFn: async () => {
        if (!zip && !parkId) return;
        const { data } = await api.get<GetPriceIndicatorsResponse>(
          zip
            ? `/v2/parks/priceIndicator?postal=${zip.replace(/ /g, "")}`
            : `/v2/parks/priceIndicator?parkId=${parkId}`
        );
        return data;
      },
    });
  };
};
