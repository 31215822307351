import { useEffect, useState } from "react";

interface Option {
  name: string;
  value: string;
  description?: string;
  badge?: React.ReactNode;
}

interface Props {
  options: Option[];
  selectedOptionValue?: string;
  onSelect: (option: Option) => void;
}

export default function FieldRadiosInline({
  options,
  selectedOptionValue,
  onSelect,
}: Props) {
  const [selected, setSelected] = useState<Option | undefined>();
  const [rand] = useState(Math.random().toString(36).substring(7));

  useEffect(() => {
    if (!selectedOptionValue) return;

    const selectedOption = options.find(
      (option) => option.value === selectedOptionValue
    );

    if (selectedOption) setSelected(selectedOption);
  }, [selectedOptionValue, options]);

  return (
    <div>
      <fieldset className="mt-4">
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-5 sm:space-y-0">
          {options.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                id={option.value + rand}
                type="radio"
                className="h-4 w-4 border-gray-300 text-rapide-600 focus:ring-rapide-600"
                onChange={() => onSelect(option)}
                checked={selected?.value === option.value}
              />
              <label
                htmlFor={option.value + rand}
                className="ml-2 mt-[1px] block text-sm font-semibold leading-6 text-simple-900"
              >
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
