import classNames from "@/utils/cx";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";

interface Option {
  name: string;
  description?: string;
  image?: React.ReactNode;
  value: string;
}

interface Props {
  options: Option[];
  selectedOptionValue: string;
  onSelect: (option: Option) => void;
}

export default function FieldRadiosVerticalList({
  options,
  selectedOptionValue,
  onSelect,
}: Props) {
  const [selected, setSelected] = useState<Option>(options[0]);

  useEffect(() => {
    if (!selectedOptionValue) return;

    const selectedOption = options.find(
      (option) => option.value === selectedOptionValue
    );

    if (selectedOption) setSelected(selectedOption);
  }, [selectedOptionValue, options]);

  return (
    <RadioGroup value={selected} onChange={onSelect}>
      {options.map((option) => (
        <div className="mt-4" key={option.name}>
          <RadioGroup.Option
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-rapide-600 ring-2 ring-rapide-600" : "",
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1 items-center">
                  {option.image && (
                    <span className="mr-5 flex-shrink-0">{option.image}</span>
                  )}

                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-bold text-simple-900"
                    >
                      {option.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-sm text-gray-600"
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-rapide-600"
                  )}
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-rapide-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                />
              </>
            )}
          </RadioGroup.Option>
        </div>
      ))}
    </RadioGroup>
  );
}
