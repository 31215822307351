import { useMutation, useQuery } from "@tanstack/react-query";

import { AuthAction, EncryptedData } from "@/@types/auth";
import api from "@/services/api";
import { getInstallationId } from "@/utils/device";
import { getLanguage } from "@/utils/language";

export interface ValidateAuthTokenResponse {
  status: "validToken";
}

export const useValidateAuthTokenQuery = (enabled: boolean) => {
  return useQuery({
    enabled,
    retry: false,
    queryKey: ["validateAuthToken"],
    queryFn: async () => {
      const { data } = await api.get<ValidateAuthTokenResponse>(
        "/users/me/authcheck"
      );
      return data.status === "validToken";
    },
  });
};

export interface AuthPreflightRequest {
  email: string;
}

export interface AuthPreflightResponse {
  hasAccount: boolean;
  error?: unknown;
}

export const useAuthPreflightMutation = () => {
  return useMutation({
    mutationKey: ["authPreflight"],
    mutationFn: async (request: AuthPreflightRequest) => {
      const { data } = await api.post<AuthPreflightResponse>(
        "/v2/users/login/preflight",
        request
      );
      return data;
    },
  });
};

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  authToken: string;
}

export const useLoginMutation = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async ({ username, password }: LoginRequest) => {
      const locale = `${getLanguage()}-ca`;
      const installationId = getInstallationId();

      const { data } = await api.post<LoginResponse>("/v2/users/login", {
        username,
        password,
        locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device: { name: "web", installationId },
      });

      return data;
    },
  });
};

export interface SignUpRequest {
  email: string;
  password: string;
  locale: string;
  timezone: string;
  device: {
    installationId: string;
    name?: string;
  };
}

export interface SignUpResponse {
  authToken: string;
}

export const useSignUpMutation = () => {
  return useMutation({
    mutationKey: ["signUp"],
    mutationFn: async ({ email, password }: Partial<SignUpRequest>) => {
      const locale = `${getLanguage()}-ca`;
      const installationId = getInstallationId();

      const { data } = await api.post<SignUpResponse>("/signup", {
        email,
        password,
        locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device: { name: "web", installationId },
      });
      return data;
    },
  });
};

export interface SocialAuthRequest {
  provider: "facebook" | "apple" | "google";
  providerToken: string;
}

export interface SocialAuthResponse {
  loginMethod: AuthAction;
  authToken: string;
}

export const useSocialAuthMutation = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async (request: SocialAuthRequest) => {
      const locale = `${getLanguage()}-ca`;
      const installationId = getInstallationId();

      const { data } = await api.post<SocialAuthResponse>(
        `/social/${request.provider}/${request.providerToken}`,
        {
          locale,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          device: { name: "web", installationId },
        }
      );
      return data;
    },
  });
};

export interface ResetPasswordRequest {
  email: string;
  newPassword: string;
  code: string;
  encryptedData: EncryptedData;
}

export interface ResetPasswordResponse {
  success: boolean;
}

export const useResetPasswordMutation = () => {
  return useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: async ({
      email,
      newPassword,
      code,
      encryptedData,
    }: ResetPasswordRequest) => {
      const { data } = await api.post<ResetPasswordResponse>(
        `/v2/users/password/reset?email=${encodeURIComponent(email)}`,
        { newPassword, code, encryptedData }
      );
      return data;
    },
  });
};
