import { PlateCheckerRequestContext } from "@/containers/plate-checker/contexts/plate-checker-request.context";
import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";

import { DateUtils } from "@/utils/date.utils";
import dayjs from "dayjs";
import {
  ChevronDown,
  ChevronUp,
  CircleX,
  CopySlash,
  ListStart,
  ThumbsDown,
  ThumbsUp,
  UserX,
} from "lucide-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

type IconName =
  | PlateCheckerTypes.RequestStatus
  | PlateCheckerTypes.RequestResultStatus
  | PlateCheckerTypes.RequestResultValidity;

interface IconProps {
  size: number;
  name: IconName;
}

function Icon({ size, name }: IconProps) {
  switch (name) {
    case PlateCheckerTypes.RequestStatus.Queued:
      return (
        <div className="rounded-md bg-silver-800 p-2">
          <ListStart size={size} className="text-silver-800" />
        </div>
      );
    case PlateCheckerTypes.RequestStatus.Processing:
      return (
        <div className="rounded-md bg-blue-100 p-2">
          <div
            className={`animate-spin rounded-full border-[4px] border-current border-t-transparent text-rapide-600`}
            style={{ width: size, height: size }}
            role="status"
            aria-label="loading"
          />
        </div>
      );
    case PlateCheckerTypes.RequestStatus.Error:
      return (
        <div className="rounded-md bg-red-100 p-2">
          <CircleX size={size} className="text-red-500" />
        </div>
      );
    case PlateCheckerTypes.RequestResultStatus.PlateNotDetectedInImage:
      return (
        <div className="rounded-md bg-silver-800 p-2">
          <CopySlash size={size} className="text-silver-800" />
        </div>
      );
    case PlateCheckerTypes.RequestResultStatus.PlateNotFoundInSystem:
      return (
        <div className="rounded-md bg-red-100 p-2">
          <UserX size={size} className="text-red-800" />
        </div>
      );
    case PlateCheckerTypes.RequestResultValidity.PlateDisallowed:
      return (
        <div className="rounded-md bg-red-100 p-2">
          <ThumbsDown size={size} className="text-red-800" />
        </div>
      );
    case PlateCheckerTypes.RequestResultValidity.PlateAllowed:
      return (
        <div className="rounded-md bg-green-100 p-2">
          <ThumbsUp size={size} className="text-green-800" />
        </div>
      );
    case PlateCheckerTypes.RequestResultValidity.PlateDiscretionary:
      return (
        <div className="rounded-md bg-yellow-100 p-2">
          <ThumbsUp size={size} className="text-yellow-800" />
        </div>
      );
    default:
      return null;
  }
}

function StatusText({ status }: { status: string }) {
  const { t } = useTranslation(["plateChecker"]);

  switch (status) {
    case PlateCheckerTypes.RequestResultValidity.PlateAllowed:
      return (
        <p className="text-sm font-bold text-green-600">
          {t("plateChecker:requestValidityAllowed")}
        </p>
      );
    case PlateCheckerTypes.RequestResultValidity.PlateDisallowed:
      return (
        <p className="text-sm font-bold text-red-600">
          {t("plateChecker:requestValidityDisallowed")}
        </p>
      );

    case PlateCheckerTypes.RequestResultValidity.PlateDiscretionary:
      return (
        <p className="text-sm font-bold text-yellow-600">
          {t("plateChecker:requestValidityDiscretionary")}
        </p>
      );

    case PlateCheckerTypes.RequestResultStatus.PlateNotFoundInSystem:
      return (
        <p className="text-sm font-bold text-red-600">
          {t("plateChecker:plateNotFound")}
        </p>
      );

    case PlateCheckerTypes.RequestResultStatus.PlateNotDetectedInImage:
      return (
        <p className="text-sm font-bold text-red-600">
          {t("plateChecker:plateNotDetected")}
        </p>
      );
  }
}

export default function PlateCheckerRequestHeader() {
  const context = useContext(PlateCheckerRequestContext);

  if (typeof context === "undefined") return null;

  const { request, isContentVisible, toggleContentVisibility } = context;

  const title = dayjs(request.requestedAt).format(
    DateUtils.getLocalizedFormat("MMM DD YYYY, HH:mm")
  );

  let iconName: IconName = request.status;

  if (request.status === PlateCheckerTypes.RequestStatus.Completed) {
    if (request.result?.status) iconName = request.result.status;
    if (request.result?.validity) iconName = request.result.validity;
  }

  let status: string | undefined = request.result?.status;
  if (request.result?.validity) status = request.result?.validity;

  return (
    <div
      className="flex items-center justify-between p-5"
      onClick={toggleContentVisibility}
    >
      <div className="flex items-center gap-4">
        <Icon size={32} name={iconName} />

        <div>
          <h2 className="text-xl font-bold">{title}</h2>
          {status ? <StatusText status={status} /> : null}
        </div>
      </div>

      {isContentVisible ? <ChevronUp size={32} /> : <ChevronDown size={32} />}
    </div>
  );
}
