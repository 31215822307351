import { XCircleIcon } from "@heroicons/react/20/solid";

interface Props {
  title: string;
  description?: string;
}

export default function FormError({ title, description }: Props) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="font-regular text-sm text-red-800">{title}</h3>
          {description && (
            <div className="mt-2 text-sm text-red-700">
              <p className="space-y-1">{description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
