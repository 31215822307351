import Wrapper from "@/components/layout/Wrapper";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  padding?: "sm" | "md" | "lg" | "xl";
}

export default function PageSection({ children, padding }: Props) {
  const paddingClass = {
    sm: "py-8",
    md: "py-12",
    lg: "py-16",
    xl: "py-20",
  }[padding || "md"];

  return (
    <section className={`${paddingClass} relative`}>
      <Wrapper>{children}</Wrapper>
    </section>
  );
}
