import { FCP_Timebloc } from "@/@types/park";

export function getHrTimebloc(timebloc: FCP_Timebloc): string {
  const hour = parseInt(timebloc);
  const quarter = timebloc.slice(-1);
  if (quarter === "A") return `${hour}:00`;
  if (quarter === "B") return `${hour}:15`;
  if (quarter === "C") return `${hour}:30`;
  if (quarter === "D") return `${hour + 1}:00`;
  else return "";
}
