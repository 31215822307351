import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Head() {
  const { t } = useTranslation("common");
  return (
    <Helmet>
      <title>{t("appTitle")}</title>
    </Helmet>
  );
}
