import FormLoader from "@/components/form/layout/FormLoader";
import { IconPlus } from "@tabler/icons-react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  onUpload: (file: File) => void;
  uploading?: boolean;
}

export default function FieldMinimalUpload({
  className,
  onUpload,
  uploading,
}: Props) {
  const { t } = useTranslation("common");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ["image/jpg", "image/jpeg", "image/png"],
    onDrop: async (acceptedFiles) => {
      for (const file of acceptedFiles) {
        await onUpload(file);
      }
    },
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} disabled={uploading} />

        <div
          className={`${className} flex items-center justify-center border-2 border-dashed border-silver-800 ${
            !uploading ? "hover:border-rapide-600" : ""
          } rounded-lg text-center transition-all ${
            isDragActive && !uploading ? "border-rapide-600" : ""
          }`}
        >
          {uploading ? (
            <div className="flex-col items-center justify-center">
              <FormLoader noMargin />
              <div className="mt-4 text-sm font-bold">{t("uploading")}</div>
            </div>
          ) : (
            <IconPlus size={42} className="text-rapide-600" />
          )}
        </div>
      </div>
    </div>
  );
}
