import PlateCheckerRequestResults from "@/containers/plate-checker/components/request/request-results";
import { PlateCheckerRequestContext } from "@/containers/plate-checker/contexts/plate-checker-request.context";
import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";
import { CPException } from "@clicknpark/sdk";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

interface MessageProps {
  title: string;
  description?: string;
}

function Message({ title, description }: MessageProps) {
  return (
    <div className="py-4 pt-2 text-center">
      <p className="mt-5 font-semibold">{title}</p>
      {description && <p className="mt-2.5 text-sm">{description}</p>}
    </div>
  );
}

export default function PlateCheckerRequestContent() {
  const { t } = useTranslation(["plateChecker"]);
  const context = useContext(PlateCheckerRequestContext);

  if (!context) return null;

  const { request, isContentVisible } = context;

  if (!isContentVisible) return null;

  let requestContent = null;

  if (request.status === PlateCheckerTypes.RequestStatus.Queued) {
    requestContent = (
      <Message
        title={t("plateChecker:addedToQueue")}
        description={t("plateChecker:addedToQueueDescription")}
      />
    );
  }

  if (request.status === PlateCheckerTypes.RequestStatus.Processing) {
    requestContent = <Message title={t("plateChecker:processing")} />;
  }

  if (request.status === PlateCheckerTypes.RequestStatus.Error) {
    if (request.error instanceof CPException) {
      requestContent = (
        <Message
          title={`${request.error.message} (${request.error.code})`}
          description={t("plateChecker:errorDescription")}
        />
      );
    } else if (request.error instanceof Error) {
      requestContent = (
        <Message
          title={request.error.message}
          description={t("plateChecker:errorDescription")}
        />
      );
    } else {
      requestContent = (
        <Message
          title={t("plateChecker:error")}
          description={t("plateChecker:errorDescription")}
        />
      );
    }
  }

  if (
    request?.result?.status ===
    PlateCheckerTypes.RequestResultStatus.PlateNotDetectedInImage
  ) {
    requestContent = (
      <Message
        title={t("plateChecker:plateNotDetected")}
        description={t("plateChecker:plateNotDetectedDescription")}
      />
    );
  }

  if (
    request?.result?.status ===
    PlateCheckerTypes.RequestResultStatus.PlateNotFoundInSystem
  ) {
    requestContent = (
      <Message
        title={t("plateChecker:plateNotFound")}
        description={t("plateChecker:plateNotFoundDescription")}
      />
    );
  }

  if (
    request?.result?.status ===
    PlateCheckerTypes.RequestResultStatus.PlateFoundInSystem
  ) {
    requestContent = <PlateCheckerRequestResults />;
  }

  return (
    <div className="space-y-2 bg-slate-100 px-2.5 py-1 pb-3 md:px-5">
      {requestContent}
    </div>
  );
}
