import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import FormParkClearOfSnow from "@/containers/forms/FormParkClearOfSnow";
import FormParkDescription from "@/containers/forms/FormParkDescription";
import FormParkFeatures from "@/containers/forms/FormParkFeatures";
import FormParkNumberOfSpaces from "@/containers/forms/FormParkNumberOfSpaces";
import FormParkPrice from "@/containers/forms/FormParkPrice";
import FormParkVehicleSize from "@/containers/forms/FormParkVehicleSize";
import useParkEdit from "@/hooks/context/useParkEdit";
import { formatAddress } from "@/utils/formatter";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function ParkEdit() {
  const { id } = useParams();
  const { t } = useTranslation(["common"]);
  const { park } = useParkEdit();

  return (
    <Shell>
      <PageHeader
        title={(park?.address && formatAddress(park.address)) || ""}
        breadcrumbs={[
          {
            name: t("common:parks"),
            href: "/parks",
            current: false,
          },
        ]}
        tabs={[
          {
            name: t("details"),
            href: `/parks/${id}`,
            current: true,
          },
          {
            name: t("photos"),
            href: `/parks/${id}/photos`,
            current: false,
          },
          {
            name: t("schedule"),
            href: `/parks/${id}/schedule`,
            current: false,
          },
          {
            name: t("delete"),
            href: `/parks/${id}/delete`,
            current: false,
          },
        ]}
      />

      <div className="divide-y divide-silver-800">
        <PageSection>
          <FormParkNumberOfSpaces />
        </PageSection>
        <PageSection>
          <FormParkDescription />
        </PageSection>
        <PageSection>
          <FormParkVehicleSize />
        </PageSection>
        <PageSection>
          <FormParkFeatures />
        </PageSection>
        <PageSection>
          <FormParkClearOfSnow />
        </PageSection>
        <PageSection>
          <FormParkPrice />
        </PageSection>
      </div>
    </Shell>
  );
}
