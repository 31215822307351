import { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(() => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false; // Default value for server-side rendering
  });

  useEffect(() => {
    if (typeof window === "undefined") return; // Ensure it's only run in the browser

    const mediaQueryList = window.matchMedia(query);
    const updateMatch = () => setMatches(mediaQueryList.matches);

    // Initial check and event listener setup
    updateMatch();
    mediaQueryList.addEventListener("change", updateMatch);

    // Cleanup function
    return () => mediaQueryList.removeEventListener("change", updateMatch);
  }, [query]);

  return matches;
}
