import { useGetConnectedAccountQuery } from "@/services/connect";
import { useGetParksQuery } from "@/services/park";
import { useEffect, useState } from "react";

interface Requirements {
  kycRequired?: boolean;
  parkSubmitRequired?: boolean;
  payoutsSetupRequired?: boolean;
  onboardingComplete?: boolean;
}

export default function useOnboarding() {
  const { data: parkings, isLoading: isLoadingParks } = useGetParksQuery();
  const { data, error } = useGetConnectedAccountQuery();

  const [loading, setLoading] = useState(true);
  const [accountRequirements, setAccountRequirements] = useState<Requirements>(
    {}
  );

  const parkSubmitRequired =
    parkings?.filter((p) => p.status !== "draft")?.length === 0;

  useEffect(() => {
    // If the connected account has never been set, the error 551 is thrown, we need to verify identity
    if (error?.code.toString() === "551") {
      setAccountRequirements({
        ...accountRequirements,
        kycRequired: true,
        payoutsSetupRequired: true,
      });

      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const { accountData } = data;
      const { requirements, bankAccounts } = accountData;

      // Unless requirements only includes external_account, we need to verify identity
      const stripeRequirements: string[] =
        requirements?.currently_due?.filter(
          (r: string) => r !== "external_account"
        ) || [];

      setAccountRequirements({
        ...accountRequirements,
        kycRequired: stripeRequirements.length > 0,
        payoutsSetupRequired: !bankAccounts || bankAccounts.length === 0,
      });

      setLoading(false);
    }
  }, [data]);

  return {
    loading,
    ...accountRequirements,
    parkSubmitRequired,
    onboardingComplete: !accountRequirements.kycRequired && !parkSubmitRequired,
    park: parkings?.[0],
    isLoadingParks,
  };
}
