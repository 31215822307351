import PlateCheckerRequestResult from "@/containers/plate-checker/components/request/result/result";
import { PlateCheckerRequestContext } from "@/containers/plate-checker/contexts/plate-checker-request.context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function PlateCheckerRequestResults() {
  const context = useContext(PlateCheckerRequestContext);
  const { t } = useTranslation(["plateChecker"]);

  if (!context) return null;

  const { request } = context;
  const results = request?.result?.data;

  if (!results) return null;

  const sortedResults = results.items.sort((a, b) => {
    if (a.currentReservations?.length && !b.currentReservations?.length)
      return -1;

    if (!a.currentReservations?.length && b.currentReservations?.length)
      return 1;

    if (a.upcomingReservations?.length && !b.upcomingReservations?.length)
      return -1;

    if (!a.upcomingReservations?.length && b.upcomingReservations?.length)
      return 1;

    return 0;
  });

  return (
    <div>
      {sortedResults ? (
        <div>
          <p className="mt-2.5 text-sm font-bold md:mt-5">
            {t("plateChecker:resultsFound", { count: sortedResults.length })}
          </p>

          <ul className="grid-cols-2 md:grid md:gap-3 md:py-5 xl:grid-cols-3 2xl:grid-cols-4">
            {sortedResults.map((result, index) => (
              <PlateCheckerRequestResult key={index} result={result} />
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
