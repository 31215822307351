import Wrapper from "@/components/layout/Wrapper";
import classNames from "@/utils/cx";
import { useNavigate } from "react-router-dom";

interface Props {
  tabs: {
    name: string;
    href: string;
    current: boolean;
  }[];
}

export default function Tabs({ tabs }: Props) {
  const navigate = useNavigate();

  return (
    <div className="pt-5">
      <div className="bg-silver-900 pb-5 sm:hidden">
        <Wrapper>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <label htmlFor="tabs" className="mb-2 block text-sm font-bold">
            Navigation:
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab) => tab.current)?.name}
            onChange={(e) => {
              navigate(
                tabs.find((tab) => tab.name === e.target.value)?.href || ""
              );
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </Wrapper>
      </div>

      <div className="hidden sm:block">
        <Wrapper>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                key={tab.name}
                onClick={() => navigate(tab.href)}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-rapide-600"
                    : "border-transparent text-simple-900 hover:border-gray-300",
                  "group inline-flex cursor-pointer items-center border-b-2 px-1 pb-3 pt-4 text-sm font-semibold"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {/* <tab.icon
                className={classNames(
                  tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-600',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              /> */}
                <span>{tab.name}</span>
              </span>
            ))}
          </nav>
        </Wrapper>
      </div>
    </div>
  );
}
