import { CPException, CPPlateCheckerOwnerResponse } from "@clicknpark/sdk";

export namespace PlateCheckerTypes {
  export type RequestMode = "text" | "image";

  export enum RequestStatus {
    Queued = "request-queued",
    Processing = "request-processing",
    Completed = "request-completed",
    Error = "request-error",
  }

  export enum RequestResultStatus {
    PlateFoundInSystem = "plate-found-in-system",
    PlateNotFoundInSystem = "plate-not-found-in-system",
    PlateNotDetectedInImage = "plate-not-detected-in-image",
  }

  export enum RequestResultValidity {
    PlateAllowed = "plate-allowed",
    PlateDisallowed = "plate-disallowed",
    PlateDiscretionary = "plate-discretionary",
  }

  export interface RequestResult {
    status: RequestResultStatus;
    validity?: RequestResultValidity;
    data?: CPPlateCheckerOwnerResponse;
  }

  export interface Request {
    index: number;
    requestedAt: Date;
    status: RequestStatus;
    isContentVisible: boolean;
    result?: RequestResult;
    error?: CPException | Error;
  }
}
