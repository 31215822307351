import Wrapper from "@/components/layout/Wrapper";
import companyData from "@/config/companyData";
import { chooseLanguage, getLanguage } from "@/utils/language";
import { PropsWithChildren, useEffect } from "react";
import {
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useRouteError,
} from "react-router-dom";

export default function Error() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const error: unknown = useRouteError();

  console.error(error);

  /* Redirects old URLs to new URLs */

  useEffect(() => {
    if (pathname.indexOf(`en-ca`) !== -1) {
      chooseLanguage("en");
    } else if (pathname.indexOf(`fr-ca`) !== -1) {
      chooseLanguage("fr");
    }

    if (
      pathname === `/${getLanguage()}-ca/` ||
      pathname === `/${getLanguage()}-ca`
    ) {
      navigate("/", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/account`)) {
      navigate("/account", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/payment`)) {
      navigate("/payment", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/parkings/add`)) {
      navigate("/parks/add/1", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/parkings`)) {
      navigate("/parks", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/activities`)) {
      navigate("/activities", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/reservations`)) {
      navigate("/reservations", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/help`)) {
      navigate("/help", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/email-verification`)) {
      navigate("/email-verification", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/auth/login`)) {
      navigate("/auth", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/auth/signup`)) {
      navigate("/auth/signup", { replace: true });
      return;
    } else if (pathname.startsWith(`/${getLanguage()}-ca/auth/reset`)) {
      navigate("/auth/reset-password", { replace: true });
      return;
    }
  }, [pathname, navigate]);

  const Message = ({ children }: PropsWithChildren) => (
    <div className="flex h-screen items-center justify-center">
      <Wrapper>
        {getLanguage() === "fr" ? (
          <div className="text-center">
            <h2 className="mb-5 text-2xl font-bold">
              Une erreur inattendue s'est produite.
            </h2>
            <p className="mb-1 text-lg">
              Nous sommes désolés pour cet inconvénient. Si le problème
              persiste, veuillez réessayer ou contacter le service à la
              clientèle.
            </p>
            <div className="mb-1 flex text-lg">
              <a
                className="text-rapide-600 hover:underline"
                href={`mailto:${companyData.email}`}
              >
                courriel
              </a>{" "}
              /{" "}
              <a
                className="text-rapide-600 hover:underline"
                href={`tel:${companyData.phoneNumber}`}
              >
                téléphone
              </a>
            </div>

            {children}
          </div>
        ) : (
          <div className="text-center">
            <h2 className="mb-5 text-2xl font-bold">
              An unexpected error occured.
            </h2>
            <p className="mb-1 text-lg">
              We are sorry for this inconvenience. If the problem persists,
              please try again or contact customer service.
            </p>
            <p className="mb-1 text-lg">
              <a
                className="text-rapide-600 hover:underline"
                href={`mailto:${companyData.email}`}
              >
                email
              </a>{" "}
              /{" "}
              <a
                className="text-rapide-600 hover:underline"
                href={`tel:${companyData.phoneNumber}`}
              >
                phone
              </a>
            </p>

            {children}
          </div>
        )}
      </Wrapper>
    </div>
  );

  if (isRouteErrorResponse(error)) {
    return (
      <Message>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </Message>
    );
  }

  return <Message />;
}
