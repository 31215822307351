import { useState } from "react";

interface Props {
  exitAnimationDelay?: number;
  exitAnimationClassNames?: string;
  exitAnimationSpeed?: "fast" | "slow";
}

export default function useExitAnimation({
  exitAnimationDelay = 1500,
  exitAnimationSpeed = "fast",
  exitAnimationClassNames = "animate-fade animate-once animate-ease-in-out",
}: Props) {
  const [animatingExit, setanimatingExit] = useState(false);

  exitAnimationClassNames += ` animate-duration-${
    exitAnimationSpeed === "fast" ? "[750ms]" : "[1500ms]"
  }`;

  async function launchExitAnimation() {
    setTimeout(() => {
      setanimatingExit(true);
    }, exitAnimationDelay);
  }

  return {
    launchExitAnimation,
    animatingExit,
    exitAnimationClassNames,
  };
}
