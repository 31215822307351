import { FCP_Park } from "@/@types/park";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

interface GetParkRequest {
  parkId: string;
  options?: {
    fetchPhotos?: boolean;
    timeTableFormat?: boolean;
  };
}

type GetParkResponse = Partial<FCP_Park>;

export const useLazyGetPark = () => {
  const queryClient = useQueryClient();
  return async ({ parkId, options }: GetParkRequest) => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyPark" + parkId],
      queryFn: async () => {
        const offset = new Date().getTimezoneOffset();
        const { data } = await api.get<GetParkResponse>(
          `/v2/users/me/connect/parkings/${parkId}`,
          {
            params: {
              ...options,
              offset,
            },
          }
        );
        return data;
      },
    });
  };
};
