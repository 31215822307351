import {
  addMinutes,
  format,
  isAfter,
  isEqual,
  isSameDay,
  parse,
} from "date-fns";

import { FCP_Day, FCP_Timebloc, FCP_TimeblocLetter } from "@/@types/park";
import { getLanguage } from "@/utils/language";
import { enUS, fr } from "date-fns/locale";

/* Days utils */

export function getDaysArray(): FCP_Day[] {
  return [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
}

export function getDayNameFromNumber(dayNumber: number): FCP_Day {
  const days = getDaysArray();
  // 1 = monday, 7 = sunday
  return days[dayNumber - 1];
}

export function getDayNumberFromName(dayName: FCP_Day): number {
  const days = getDaysArray();
  // 1 = monday, 7 = sunday
  return days.indexOf(dayName) + 1;
}

/* Date formatter */

export function formatDate(date: Date, customDateFormat?: string): string {
  const isEN = getLanguage() === "en";
  const dateFormat = isEN ? "MMM. do yyyy" : "d MMM. yyyy";
  const dateLocale = { locale: isEN ? enUS : fr };
  return format(date, customDateFormat || dateFormat, dateLocale);
}

export function appendAmPmToHour(hour: number): string {
  if (hour <= 11) {
    return `${hour} AM`;
  } else {
    return `${hour} PM`;
  }
}

/* Timebloc utils */

export function getTimeblocLetterFromMinute(
  minute: string
): FCP_TimeblocLetter {
  switch (minute) {
    case "00":
      return "A";
    case "15":
      return "B";
    case "30":
      return "C";
    case "45":
      return "D";
    default:
      return "A";
  }
}

export function getMinuteFromTimeblocLetter(
  timeblocLetter: FCP_TimeblocLetter
): string {
  switch (timeblocLetter) {
    case "A":
      return "00";
    case "B":
      return "15";
    case "C":
      return "30";
    case "D":
      return "45";
    default:
      return "00";
  }
}

export function getTimeblocFromTime(time: string): FCP_Timebloc {
  const hour = time.split(":")[0];
  const minute = time.split(":")[1];
  const timeblock = `${hour.padStart(2, "0")}${getTimeblocLetterFromMinute(
    minute
  )}` as FCP_Timebloc;
  return timeblock;
}

export function getTimeFromTimeblock(timebloc: FCP_Timebloc): string {
  const hour = timebloc.slice(0, 2);
  const minuteLetter = timebloc.slice(2, 3) as FCP_TimeblocLetter;
  const time = `${hour}:${getMinuteFromTimeblocLetter(minuteLetter)}`;
  return time;
}

/* Various utils */

export function generateArrayOfYears(): number[] {
  const minDate = 2020;
  const maxDate = new Date().getFullYear();
  const ratio = maxDate - minDate;
  const iterator = maxDate - ratio;
  const years = [];

  for (let i = maxDate; i >= iterator; i--) {
    years.push(i);
  }

  return years;
}

export function generateHoursAndMinutesArray(): string[] {
  const timeArray = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      timeArray.push(`${(i < 10 ? "0" : "") + i}:${j === 0 ? `00` : 15 * j}`);
    }
  }

  return timeArray;
}

/* Add time to date */

export function addTimeStringToDate(date: Date, timeString: string): Date {
  const [hours, minutes] = timeString.split(":").map(Number);
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  return newDate;
}

/* Add 30 minutes to time */

export function adjustEndTime(
  startDate: Date,
  endDate: Date,
  startTime: string,
  endTime: string,
  setEndTime: (time: string) => void
) {
  const sameDay = isSameDay(startDate, endDate);

  const startIsAfterEnd = isAfter(startDate, endDate);

  const sameTime = isEqual(
    parse(startTime, "HH:mm", new Date()),
    parse(endTime, "HH:mm", new Date())
  );

  const startTimeIsAfterEndTime = isAfter(
    parse(startTime, "HH:mm", new Date()),
    parse(endTime, "HH:mm", new Date())
  );

  if ((sameDay || startIsAfterEnd) && (sameTime || startTimeIsAfterEndTime)) {
    setEndTime(
      format(addMinutes(parse(startTime, "HH:mm", new Date()), 30), "HH:mm")
    );
  }
}
