import FieldSelect from "@/components/form/fields/FieldSelect";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import OnboardingShell from "@/components/layout/onboarding/Shell";
import useOnboarding from "@/hooks/useOnboarding";
import { CPException } from "@/models/exceptions/CPException";
import { useCreateBankAccountMutation } from "@/services/connect";
import { useUpdateOnboardingStepMutation } from "@/services/owner";
import { generateStripeBankAccountToken } from "@/services/stripe";
import { getLanguage } from "@/utils/language";
import { getBifrostEmail } from "@/utils/storage";
import * as tracker from "@/utils/tracker";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import * as Yup from "yup";

export default function LinkBankAccount() {
  const navigate = useNavigate();
  const { t } = useTranslation(["onboarding", "account", "validation"]);
  const { loading, kycRequired } = useOnboarding();
  const { mutateAsync: createBankAccountAsync } =
    useCreateBankAccountMutation();
  const [formError, setFormError] = useState<string | undefined>();

  const initialValues = {
    bankCountry: "ca",
    bankCurrency: "cad",
    bankAccountHolderName: "",
    bankAccountHolderType: "individual",
    bankRoutingNumber: "",
    bankInstitutionNumber: "",
    bankAccountNumber: "",
  };

  const validationSchema = Yup.object().shape({
    bankAccountHolderName: Yup.string().required(
      t("validation:bankAccountHolderNameRequired")
    ),
    bankRoutingNumber: Yup.string().required(
      t("validation:bankRoutingNumberRequired")
    ),
    bankInstitutionNumber: Yup.string().required(
      t("validation:bankInstitutionNumberRequired")
    ),
    bankAccountNumber: Yup.string().required(
      t("validation:bankAccountNumberRequired")
    ),
  });

  const {
    submitForm,
    errors,
    values,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async () => {
      try {
        const { data } = await generateStripeBankAccountToken(
          values.bankCountry,
          values.bankCurrency,
          values.bankAccountHolderName,
          values.bankAccountHolderType,
          values.bankCountry === "ca"
            ? values.bankRoutingNumber + values.bankInstitutionNumber
            : values.bankRoutingNumber,
          values.bankAccountNumber
        );

        if (data) {
          await createBankAccountAsync({ bankToken: data });
          tracker.track.onboarding.linkedBankAccount();
          navigate("/onboarding/end");
        }
      } catch (error) {
        if (error instanceof Error) {
          setFormError(error.message);
        } else if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  useEffect(() => {
    if (!loading && kycRequired && !getBifrostEmail()) {
      navigate("/onboarding", { replace: true });
    }
  }, [loading, kycRequired]);

  useEffectOnce(() => {
    tracker.track.onboarding.landedOnLinkBankAccount();
  });

  const { mutateAsync: updateOnboadingStep } =
    useUpdateOnboardingStepMutation();

  useEffectOnce(() => {
    updateOnboadingStep("link-bank-account");
  });

  return (
    <OnboardingShell
      loading={loading || isSubmitting}
      actions={{
        exit: {
          visible: true,
          disabled: false,
          onClick: () => navigate("/"),
        },
        next: {
          visible: true,
          disabled: !isValid || isSubmitting || loading,
          onClick: () => submitForm(),
        },
      }}
    >
      <FormHeading
        title={t("onboarding:configureDeposits")}
        subtitle={t("onboarding:configureDepositsText")}
      />

      {formError && <FormError title={formError} />}

      <FormFields
        gapY="md"
        fields={[
          <FormField key="bankAccountHolderName">
            <FieldText
              value={values.bankAccountHolderName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                (touched.bankAccountHolderName &&
                  errors.bankAccountHolderName) ||
                undefined
              }
              autoComplete="off"
              name="bankAccountHolderName"
              label={t("account:bankAccountHolderName")}
            />
          </FormField>,

          <FormField key="bankAccountHolderType">
            <FieldSelect
              label={t("account:bankAccountHolderType")}
              selectedOptionValue={values.bankAccountHolderType}
              onSelect={({ value }) =>
                setFieldValue("bankAccountHolderType", value)
              }
              options={[
                {
                  name: t("account:bankAccountHolderTypeIndividual"),
                  value: "individual",
                },
                {
                  name: t("account:bankAccountHolderTypeCompany"),
                  value: "company",
                },
              ]}
            />
          </FormField>,

          <FormField size="3-6" key="bankCountry">
            <FieldSelect
              label={t("account:bankCountry")}
              selectedOptionValue={values.bankCountry}
              onSelect={({ value }) => setFieldValue("bankCountry", value)}
              options={[
                {
                  name: t("account:bankCountryCanada"),
                  value: "ca",
                },
                {
                  name: t("account:bankCountryUnitedStates"),
                  value: "us",
                },
              ]}
            />
          </FormField>,

          <FormField size="3-6" key="bankCurrency">
            <FieldSelect
              label={t("account:bankCurrency")}
              selectedOptionValue={values.bankCurrency}
              onSelect={({ value }) => setFieldValue("bankCurrency", value)}
              options={[
                {
                  name: t("account:bankCurrencyCanada"),
                  value: "cad",
                },
                {
                  name: t("account:bankCurrencyUnitedStates"),
                  value: "usd",
                },
              ]}
            />
          </FormField>,

          <FormField size="2-6" key="bankRoutingNumber">
            <FieldText
              value={values.bankRoutingNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                (touched.bankRoutingNumber && errors.bankRoutingNumber) ||
                undefined
              }
              autoComplete="off"
              name="bankRoutingNumber"
              label={t("account:bankRoutingNumber")}
            />
          </FormField>,

          <FormField size="2-6" key="bankInstitutionNumber">
            <FieldText
              value={values.bankInstitutionNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                (touched.bankInstitutionNumber &&
                  errors.bankInstitutionNumber) ||
                undefined
              }
              autoComplete="off"
              name="bankInstitutionNumber"
              label={t("account:bankInstitutionNumber")}
            />
          </FormField>,

          <FormField size="2-6" key="bankAccountNumber">
            <FieldText
              value={values.bankAccountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                (touched.bankAccountNumber && errors.bankAccountNumber) ||
                undefined
              }
              autoComplete="off"
              name="bankAccountNumber"
              label={t("account:bankAccountNumber")}
            />
          </FormField>,
        ]}
      />

      <img
        className="mt-10"
        src={`/images/connect/check-information-${getLanguage()}.svg`}
        alt=""
      />
    </OnboardingShell>
  );
}
