import { FCP_Payout } from "@/@types/billing";
import { FCP_BankAccount, FCP_ConnectedAccount } from "@/@types/user";
import { CPException } from "@/models/exceptions/CPException";
import api from "@/services/api";
import { getLanguage } from "@/utils/language";
import { QueryKey, useMutation, useQuery } from "@tanstack/react-query";

export interface GetConnectedAccountResponse {
  accountData: Partial<FCP_ConnectedAccount>;
}

export const useGetConnectedAccountQuery = () => {
  return useQuery<
    GetConnectedAccountResponse,
    CPException,
    GetConnectedAccountResponse,
    QueryKey
  >({
    queryKey: ["connectedAccounts"],
    queryFn: async () => {
      const { data } = await api.get<GetConnectedAccountResponse>(
        `/v2/users/me/connect`
      );
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export interface CreateBankAccountRequest {
  bankToken: string;
}

export const useCreateBankAccountMutation = () => {
  return useMutation({
    mutationKey: ["createBankAccount"],
    mutationFn: async ({ bankToken }: CreateBankAccountRequest) => {
      const { data } = await api.post<{ bankAccount: FCP_BankAccount }>(
        `/users/connectedAccount/bankAccounts`,
        { bankToken }
      );
      return data.bankAccount;
    },
  });
};

export interface UpdateBankAccountRequest {
  id: string;
  options?: {
    account_holder_name?: string;
    account_holder_type?: "individual" | "company";
    default_for_currency?: boolean;
  };
}

export const useUpdateBankAccountMutation = () => {
  return useMutation({
    mutationKey: ["updateBankAccount"],
    mutationFn: async ({ id, options }: UpdateBankAccountRequest) => {
      const { data } = await api.post<{ externalAccount: FCP_BankAccount }>(
        `/v2/users/me/connect/externalAccounts/${id}`,
        { accountData: options }
      );
      return data.externalAccount;
    },
  });
};

export interface DeleteBankAccountRequest {
  id: string;
}

export const useDeleteBankAccountMutation = () => {
  return useMutation({
    mutationKey: ["updateBankAccount"],
    mutationFn: async ({ id }: DeleteBankAccountRequest) => {
      const { data } = await api.delete<{ externalAccount: FCP_BankAccount }>(
        `/v2/users/me/connect/externalAccounts/${id}`
      );
      return data.externalAccount;
    },
  });
};

interface GetRevenuesRequest {
  timePeriod: "all" | "past30";
  locale?: string;
}

type GetRevenuesResponse = {
  revenues: number;
  formattedRevenues: string;
};

export const useGetRevenuesQuery = ({ timePeriod }: GetRevenuesRequest) => {
  return useQuery({
    queryKey: ["revenues" + timePeriod],
    queryFn: async () => {
      const { data } = await api.get<GetRevenuesResponse>(
        `/v2/users/me/connect/revenues`,
        {
          params: {
            timePeriod,
            locale: getLanguage() + "-CA",
          },
        }
      );
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

interface GetPayoutsRequest {
  paidOutAfter?: string;
  createdAfter?: string;
  page?: number;
  limit?: number;
}

type GetPayoutsResponse = {
  items: FCP_Payout[];
  count: number;
};

export const useGetPayoutsQuery = (params?: GetPayoutsRequest) => {
  return useQuery({
    queryKey: ["payouts"],
    queryFn: async () => {
      const { data } = await api.get<GetPayoutsResponse>(
        `/v2/users/me/connect/payouts`,
        {
          params,
        }
      );
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

// async fetchParkingReservations(
//     parkingId: string,
//     options?: {
//       endsBefore?: string;
//       endsAfter?: string;
//       startsBefore?: string;
//       startsAfter?: string;
//       statuses?: string;
//       limit?: number;
//       page?: number;
//       event?: { venueId: string; eventId: string };
//     }
//   ): Promise<HttpResult<{ count: number; items: FCP_Reservation[] }>> {
//     if (options && options.event) {
//       const { ok, problem, data }: ApiResponse<any> = await http.get(
//         `/v2/users/me/connect/venues/${options.event.venueId}/events/${options.event.eventId}/parkings/${parkingId}/reservations`
//       );

//       if (!ok) {
//         return {
//           problem,
//           error: data?.error
//             ? errors.fetchParkingReservations(data.error)
//             : unhandledError,
//         };
//       } else {
//         return {
//           data: {
//             count: data.count,
//             items: data.items.map((item: unknown) => formatReservation(item)),
//           },
//         };
//       }
//     } else {
//       const query: {
//         endsBefore?: string;
//         endsAfter?: string;
//         startsBefore?: string;
//         startsAfter?: string;
//         statuses?: string;
//         limit?: number;
//         page?: number;
//       } = {};

//       if (options) {
//         if (options.endsBefore) query.endsBefore = options.endsBefore;
//         if (options.endsAfter) query.endsAfter = options.endsAfter;
//         if (options.startsBefore) query.startsBefore = options.startsBefore;
//         if (options.startsAfter) query.startsAfter = options.startsAfter;
//         if (options.statuses) query.statuses = options.statuses;
//         if (options.limit) query.limit = options.limit;
//         if (options.page) query.page = options.page;
//       }

//       const { ok, problem, data }: ApiResponse<any> = await http.get(
//         `/v2/users/me/connect/parkings/${parkingId}/reservations`,
//         query
//       );

//       if (!ok) {
//         return {
//           problem,
//           error: data?.error
//             ? errors.fetchParkingReservations(data.error)
//             : unhandledError,
//         };
//       } else {
//         return {
//           data: {
//             count: data.count,
//             items: data.items.map((item: unknown) => formatReservation(item)),
//           },
//         };
//       }
//     }
//   },

// async fetchPayouts(
//     paidOutAfter?: FCP_ISOString,
//     createdAfter?: FCP_ISOString
//   ): Promise<HttpResult<FCP_Payout[]>> {
//     const query: {
//       paidOutAfter?: FCP_ISOString;
//       createdAfter?: FCP_ISOString;
//     } = {};

//     if (paidOutAfter) query.paidOutAfter = paidOutAfter;
//     if (createdAfter) query.createdAfter = createdAfter;

//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect/payouts",
//       query
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchPayouts(data.error) : unhandledError,
//       };
//     } else {
//       return {
//         data: data
//           .filter((d: unknown) => d.status !== "canceled" && d.status !== "failed")
//           .map((d: unknown) => formatPayout(d)),
//       };
//     }
//   },

// import http, { unhandledError } from '@/adapters/http';
// import { ApiResponse } from 'apisauce';

// import {
//   formatBankAccount,
//   formatConnectedAccount,
//   formatEvent,
//   formatException,
//   formatParking,
//   formatPayout,
//   formatReservation,
//   formatRevenue,
//   formatVenue,
// } from './dtos/connect';
// import errors from './errors/connect';

// const connectServices = {
//   async fetchConnectedAccount(): Promise<HttpResult<FCP_ConnectedAccount>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect"
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.fetchConnectedAccount(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatConnectedAccount(data) };
//     }
//   },

//   async generateIdentityLink(): Promise<HttpResult<string>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect/identityLink"
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.generateIdentityLink(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: data.url };
//     }
//   },

//   async createExternalAccount(
//     bankToken: StripeBankAccountToken
//   ): Promise<HttpResult<FCP_LinkedAccount>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       "/users/connectedAccount/bankAccounts",
//       { bankToken }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.createExternalAccount(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatBankAccount(data.bankAccount) };
//     }
//   },

//   async updateExternalAccount(
//     id: string,
//     options?: {
//       account_holder_name?: string;
//       account_holder_type?: "individual" | "company";
//       default_for_currency?: boolean;
//     }
//   ): Promise<HttpResult<FCP_LinkedAccount>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       `/v2/users/me/connect/externalAccounts/${id}`,
//       { accountData: options }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.updateExternalAccount(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatBankAccount(data.externalAccount) };
//     }
//   },

//   async deleteExternalAccount(
//     id: string
//   ): Promise<HttpResult<FCP_LinkedAccount>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.delete(
//       `/v2/users/me/connect/externalAccounts/${id}`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.deleteExternalAccount(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatBankAccount(data.externalAccount) };
//     }
//   },

//   async fetchRevenue(
//     timePeriod: "all" | "past30",
//     locale?: FCP_LocalizationLocale
//   ): Promise<HttpResult<FCP_Revenue>> {
//     const query: { timePeriod: "all" | "past30"; locale: string } = {
//       timePeriod,
//       locale: locale || "fr-CA",
//     };

//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect/revenues",
//       { ...query }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchRevenue(data.error) : unhandledError,
//       };
//     } else {
//       return { data: formatRevenue(data) };
//     }
//   },

//   async fetchParkingRevenue(
//     parkingId: string,
//     payoutAfter?: FCP_ISOString
//   ): Promise<HttpResult<FCP_Revenue>> {
//     const query: { payoutAfter?: FCP_ISOString } = {};
//     if (payoutAfter) query.payoutAfter = payoutAfter;

//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       `/v2/users/me/connect/parkings/${parkingId}/revenues`,
//       query
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.fetchParkingRevenue(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatRevenue(data) };
//     }
//   },

//   async fetchPayouts(
//     paidOutAfter?: FCP_ISOString,
//     createdAfter?: FCP_ISOString
//   ): Promise<HttpResult<FCP_Payout[]>> {
//     const query: {
//       paidOutAfter?: FCP_ISOString;
//       createdAfter?: FCP_ISOString;
//     } = {};

//     if (paidOutAfter) query.paidOutAfter = paidOutAfter;
//     if (createdAfter) query.createdAfter = createdAfter;

//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect/payouts",
//       query
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchPayouts(data.error) : unhandledError,
//       };
//     } else {
//       return {
//         data: data
//           .filter((d: unknown) => d.status !== "canceled" && d.status !== "failed")
//           .map((d: unknown) => formatPayout(d)),
//       };
//     }
//   },

//   async fetchVenues(): Promise<HttpResult<FCP_Venue[]>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect/venues"
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchVenues(data.error) : unhandledError,
//       };
//     } else {
//       return { data: data.map((d: unknown) => formatVenue(d)) };
//     }
//   },

//   async fetchEventsForVenues(
//     venueId: string
//   ): Promise<HttpResult<FCP_Event[]>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       `/v2/users/me/connect/venues/${venueId}/events`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.fetchEventsForVenues(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: data.map((d: unknown) => formatEvent(d)) };
//     }
//   },

//   async fetchEvent(
//     venueId: string,
//     eventId: string
//   ): Promise<HttpResult<FCP_Event>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       `/v2/users/me/connect/venues/${venueId}/events/${eventId}`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchEvent(data.error) : unhandledError,
//       };
//     } else {
//       return { data: formatEvent(data) };
//     }
//   },

//   async fetchEventRevenue(
//     venueId: string,
//     eventId: string
//   ): Promise<HttpResult<FCP_Revenue>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       `/v2/users/me/connect/venues/${venueId}/events/${eventId}/revenues`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.fetchEventRevenue(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatRevenue(data) };
//     }
//   },

//   async fetchParkings(options: {
//     fetchPhotos?: boolean;
//     event?: { venueId: string; eventId: string };
//   }): Promise<HttpResult<FCP_Parking[]>> {
//     const offset = new Date().getTimezoneOffset();

//     if (options.event) {
//       const { ok, problem, data }: ApiResponse<any> = await http.get(
//         `/v2/users/me/connect/venues/${options.event.venueId}/events/${options.event.eventId}/parkings`
//       );

//       if (!ok) {
//         return {
//           problem,
//           error: data?.error
//             ? errors.fetchParkings(data.error)
//             : unhandledError,
//         };
//       } else {
//         const formattedData = data.map((d: unknown) => formatParking(d));

//         formattedData.sort(
//           (d1: unknown, d2: unknown) =>
//             new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime()
//         );

//         return {
//           data: formattedData,
//         };
//       }
//     }

//     if (!options.fetchPhotos) options.fetchPhotos = false;

//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       "/v2/users/me/connect/parkings",
//       { ...options, offset }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchParkings(data.error) : unhandledError,
//       };
//     } else {
//       const formattedData = data.map((d: unknown) => formatParking(d));

//       formattedData.sort(
//         (d1: unknown, d2: unknown) =>
//           new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime()
//       );

//       return {
//         data: formattedData,
//       };
//     }
//   },

//   async fetchParking(
//     id: string,
//     options: {
//       fetchPhotos?: boolean;
//       timeTableFormat?: boolean;
//     }
//   ): Promise<HttpResult<FCP_Parking>> {
//     const offset = new Date().getTimezoneOffset();
//     if (!options.fetchPhotos) options.fetchPhotos = false;
//     if (!options.timeTableFormat) options.timeTableFormat = false;

//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       `/v2/users/me/connect/parkings/${id}`,
//       { ...options, offset }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.fetchParking(data.error) : unhandledError,
//       };
//     } else {
//       return { data: formatParking(data) };
//     }
//   },

//   async fetchParkingReservations(
//     parkingId: string,
//     options?: {
//       endsBefore?: string;
//       endsAfter?: string;
//       startsBefore?: string;
//       startsAfter?: string;
//       statuses?: string;
//       limit?: number;
//       page?: number;
//       event?: { venueId: string; eventId: string };
//     }
//   ): Promise<HttpResult<{ count: number; items: FCP_Reservation[] }>> {
//     if (options && options.event) {
//       const { ok, problem, data }: ApiResponse<any> = await http.get(
//         `/v2/users/me/connect/venues/${options.event.venueId}/events/${options.event.eventId}/parkings/${parkingId}/reservations`
//       );

//       if (!ok) {
//         return {
//           problem,
//           error: data?.error
//             ? errors.fetchParkingReservations(data.error)
//             : unhandledError,
//         };
//       } else {
//         return {
//           data: {
//             count: data.count,
//             items: data.items.map((item: unknown) => formatReservation(item)),
//           },
//         };
//       }
//     } else {
//       const query: {
//         endsBefore?: string;
//         endsAfter?: string;
//         startsBefore?: string;
//         startsAfter?: string;
//         statuses?: string;
//         limit?: number;
//         page?: number;
//       } = {};

//       if (options) {
//         if (options.endsBefore) query.endsBefore = options.endsBefore;
//         if (options.endsAfter) query.endsAfter = options.endsAfter;
//         if (options.startsBefore) query.startsBefore = options.startsBefore;
//         if (options.startsAfter) query.startsAfter = options.startsAfter;
//         if (options.statuses) query.statuses = options.statuses;
//         if (options.limit) query.limit = options.limit;
//         if (options.page) query.page = options.page;
//       }

//       const { ok, problem, data }: ApiResponse<any> = await http.get(
//         `/v2/users/me/connect/parkings/${parkingId}/reservations`,
//         query
//       );

//       if (!ok) {
//         return {
//           problem,
//           error: data?.error
//             ? errors.fetchParkingReservations(data.error)
//             : unhandledError,
//         };
//       } else {
//         return {
//           data: {
//             count: data.count,
//             items: data.items.map((item: unknown) => formatReservation(item)),
//           },
//         };
//       }
//     }
//   },

//   async uploadPhoto(file: unknown): Promise<HttpResult<string>> {
//     const formData = new FormData();
//     formData.append("file", file);

//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       "/photos/upload",
//       formData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Accept: "multipart/form-data",
//         },
//       }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.uploadPhoto(data.error) : unhandledError,
//       };
//     } else {
//       return { data: data.photoId };
//     }
//   },

//   async getPrice(options: {
//     zip?: string;
//     parkingId?: string;
//   }): Promise<HttpResult<FCP_PriceIndicator>> {
//     let url = "";

//     if (options.zip)
//       url = `/v2/parks/priceIndicator?postal=${options.zip.replace(/ /g, "")}`;

//     if (options.parkingId)
//       url = `/v2/parks/priceIndicator?parkId=${options.parkingId}`;

//     const { ok, problem, data }: ApiResponse<any> = await http.get(url);

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.getPrice(data.error) : unhandledError,
//       };
//     } else {
//       return { data };
//     }
//   },

//   async createParking(
//     bodyRequest: FCP_CreateParkBodyRequest
//   ): Promise<HttpResult<FCP_Parking>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       "/v2/parks/",
//       { ...bodyRequest, platform: "web", version: 2 }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.createParking(data.error) : unhandledError,
//       };
//     } else {
//       return { data };
//     }
//   },

//   async updateParking(
//     parking: FCP_Parking,
//     bodyRequest: FCP_UpdateParkBodyRequest
//   ): Promise<HttpResult<FCP_Parking>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       `/parks/${parking.id}`,
//       bodyRequest
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.updateParking(data.error) : unhandledError,
//       };
//     } else {
//       return { data: formatParking({ ...parking, ...data.updates }) };
//     }
//   },

//   async deleteParking(parkingId: string): Promise<HttpResult<string>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.delete(
//       `/parks/${parkingId}`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error ? errors.deleteParking(data.error) : unhandledError,
//       };
//     } else {
//       return { data: data.id };
//     }
//   },

//   async updateParkingPricingStructure(
//     parking: FCP_Parking
//   ): Promise<HttpResult<FCP_Parking>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       `/parks/${parking.id}/upgradePricingStructure/v2`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.updateParkingPricingStructure(data.error)
//           : unhandledError,
//       };
//     } else {
//       return { data: formatParking({ ...parking, ...data.updates }) };
//     }
//   },

//   async fetchAvailExceptions(
//     parkingId: string
//   ): Promise<HttpResult<FCP_AvailException[]>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.get(
//       `/parks/${parkingId}/exceptions`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.fetchAvailExceptions(data.error)
//           : unhandledError,
//       };
//     } else {
//       console.log(data);
//       return { data: data.map((exception: unknown) => formatException(exception)) };
//     }
//   },

//   async createAvailException(
//     parkingId: string,
//     dates: { start: FCP_ISOString; end: FCP_ISOString }
//   ): Promise<HttpResult<FCP_AvailException>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       `/parks/${parkingId}/exceptions`,
//       { ...dates }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.createAvailException(data.error)
//           : unhandledError,
//       };
//     } else {
//       return {
//         data: formatException(data.newException),
//       };
//     }
//   },

//   async updateAvailException(
//     parkingId: string,
//     exceptionId: string,
//     dates: { start: FCP_ISOString; end: FCP_ISOString }
//   ): Promise<HttpResult<FCP_AvailException>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.post(
//       `/parks/${parkingId}/exceptions/${exceptionId}`,
//       { ...dates }
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.updateAvailException(data.error)
//           : unhandledError,
//       };
//     } else {
//       return {
//         data: formatException(data.newException),
//       };
//     }
//   },

//   async deleteAvailException(
//     parkingId: string,
//     exceptionId: string
//   ): Promise<HttpResult<any>> {
//     const { ok, problem, data }: ApiResponse<any> = await http.delete(
//       `/parks/${parkingId}/exceptions/${exceptionId}`
//     );

//     if (!ok) {
//       return {
//         problem,
//         error: data?.error
//           ? errors.deleteAvailException(data.error)
//           : unhandledError,
//       };
//     } else {
//       return {
//         data,
//       };
//     }
//   },
// };

// export default connectServices;
