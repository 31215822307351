const keys = {
  authToken: "cnp_auth_token",
  redirectAfterAuth: "cnp_redirect_after_auth",
  authPreflightEmail: "cnp_auth_preflight_email",
  signupEmail: "cnp_signup_email",
  completingSignupProfile: "cnp_completing_signup_profile",
  bifrostEmail: "cnp_bifrost_email",
  bifrostSkipOnboarding: "cnp_bifrost_skip_onboarding",
  parkAdd: "cnp_park_add",
  parkAddPlace: "cnp_park_add_place",
  parkAddType: "cnp_park_add_type",
  hasConnectedAccount: "cnp_has_connected_account",
  hasEvents: "cnp_user_has_events",
  installationId: "cnp_installation_id",
  userEmail: "cnp_user_email",
  loginMethod: "cnp_login_method",

  // Redirect to Bifrost after completing specific actions
  redirect_to_bifrost_after_add_park: "cnp_redirect_to_bifrost_after_add_park",
  redirect_to_bifrost_after_edit_park:
    "cnp_redirect_to_bifrost_after_edit_park",
  redirect_after_action_url: "cnp_redirect_after_action_url",
};

export default keys;
