import Loader from "@/components/layout/Loader";
import { useGetParksQuery } from "@/services/park";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectHolidays() {
  const { data, isLoading } = useGetParksQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (data?.length) {
      navigate(`/parks/${data[0].objectId}/schedule`, { replace: true });
    } else {
      navigate(`/`, { replace: true });
    }
  }, [data, isLoading]);

  return <Loader />;
}
