import Loader from "@/components/layout/Loader";
import Spacer from "@/components/Spacer";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import HelpPanel from "@/containers/HelpPanel";
import OnboardingMenu, {
  OnboardingMenuItem,
} from "@/containers/OnboardingMenu";
import useEntryAnimation from "@/hooks/animation/useEntryAnimation";
import useParkAdd from "@/hooks/context/useParkAdd";
import useOnLogout from "@/hooks/use-on-logout.hook";
import {
  getAltLanguageLabel,
  getLanguage,
  switchLanguage,
} from "@/utils/language";
import { getBifrostEmail } from "@/utils/storage";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Action {
  visible: boolean;
  disabled: boolean;
  onClick: () => void;
}

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  actions: Partial<{
    exit: Partial<Action>;
    next: Partial<Action>;
  }>;
}

export default function OnboardingShell({
  children,
  loading = false,
  actions = {
    exit: {
      visible: false,
      disabled: false,
      onClick: () => {},
    },
    next: {
      visible: false,
      disabled: false,
      onClick: () => {},
    },
  },
}: Props) {
  const logout = useOnLogout();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const { launchEntryAnimation, animatingEntry, entryAnimationClassNames } =
    useEntryAnimation({
      entryAnimationDelay: 250,
    });

  useEffect(() => {
    launchEntryAnimation();
  }, []);

  const { t } = useTranslation(["onboarding", "common"]);
  const { step, canGoNext } = useParkAdd();

  const headerClassName = "h-[85px] md:h-[92px] px-5";
  const footerClassName = "h-[85px] md:h-[94px] px-5";

  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-85px-44px)] md:h-[calc(100dvh-92px-94px-44px)]"
    : "h-[calc(100dvh-85px-85px)] md:h-[calc(100dvh-92px-94px)]";

  const menuItems: OnboardingMenuItem[] = [
    {
      name: t("common:switchToLanguage", {
        language: getAltLanguageLabel(false),
      }),
      onClick: () => {
        switchLanguage();
        setSubmenuOpen(false);
      },
    },
    {
      name: t("common:logout"),
      onClick: logout,
    },
  ];

  return (
    <div className="min-h-[100dvh] flex-col bg-white">
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <header
        className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
      >
        <img
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <div className="flex items-center justify-between">
          <Button
            className="mr-3"
            variant="outline"
            onClick={() => setHelpPanelOpen(true)}
          >
            <div className="flex items-center">{t("common:help")}</div>
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <div className="flex items-center">
                {t("common:menu")}
                <IconChevronDown
                  className={`ml-1 h-[20px] w-[20px] ${
                    submenuOpen ? "rotate-180" : ""
                  } transition-all`}
                />
              </div>
            </Button>

            {submenuOpen && (
              <div className="absolute right-0 top-[50px] z-30">
                <OnboardingMenu items={menuItems} />
              </div>
            )}
          </div>
        </div>
      </header>

      <main
        className={`${mainClassName} relative mx-auto overflow-hidden bg-white opacity-0 md:max-w-[500px] md:px-0 ${
          animatingEntry && entryAnimationClassNames
        }`}
      >
        <div className="h-full overflow-auto">
          <div className="mx-auto h-full md:max-w-[500px]">
            <div className="h-full px-6 py-8 md:px-6 md:py-12">
              {children} <Spacer />
            </div>
          </div>
        </div>
      </main>

      <footer
        className={`${footerClassName} relative z-20 mx-auto flex w-full items-center justify-center border-t border-silver-800 bg-white pb-[5px] sm:static md:px-0`}
      >
        {actions?.next?.visible && (
          <Button
            size="lg"
            onClick={actions.next.disabled ? () => {} : actions.next.onClick}
            disabled={actions.next.disabled || (step < 13 && !canGoNext)}
          >
            {t("common:continue")}
          </Button>
        )}
      </footer>

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />

      {loading && <Loader />}
    </div>
  );
}
