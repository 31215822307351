import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import FormBillingEmails from "@/containers/forms/FormBillingEmails";
import FormBillingReports from "@/containers/forms/FormBillingReport";
import { useTranslation } from "react-i18next";

export default function BillingReports() {
  const { t } = useTranslation(["account", "common"]);

  return (
    <Shell>
      <PageHeader title={t("common:billing")} />

      <div className="divide-y divide-silver-800">
        <PageSection>
          <FormBillingEmails />
        </PageSection>
        <PageSection>
          <FormBillingReports />
        </PageSection>
      </div>
    </Shell>
  );
}
