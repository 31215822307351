import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

interface Props {
  title: string;
  content?: React.ReactNode;
  link?: { onClick: () => void; text: string };
  type?: "info" | "error" | "success" | "warning";
  children?: React.ReactNode;
}

export default function AlertSimple({
  title,
  content,
  link,
  type = "info",
  children,
}: Props) {
  let icon: React.ReactNode | undefined;
  let backgroundClassName = "";
  let textClassName = "";
  let buttonClassName = "";

  switch (type) {
    case "info":
      icon = (
        <InformationCircleIcon
          className="h-5 w-5 text-blue-700"
          aria-hidden="true"
        />
      );
      backgroundClassName = "bg-blue-50";
      textClassName = "text-blue-700";
      buttonClassName = "text-blue-700 hover:text-blue-600";
      break;
    case "error":
      icon = (
        <XCircleIcon className="h-5 w-5 text-red-700" aria-hidden="true" />
      );
      backgroundClassName = "bg-red-50";
      textClassName = "text-red-700";
      buttonClassName = "text-red-700 hover:text-red-600";
      break;
    case "success":
      icon = (
        <CheckCircleIcon
          className="h-5 w-5 text-green-700"
          aria-hidden="true"
        />
      );
      backgroundClassName = "bg-green-50";
      textClassName = "text-green-700";
      buttonClassName = "text-green-700 hover:text-green-600";
      break;
    case "warning":
      icon = (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-800"
          aria-hidden="true"
        />
      );
      backgroundClassName = "bg-yellow-50";
      textClassName = "text-yellow-800";
      buttonClassName = "text-yellow-800 hover:text-yellow-600";
      break;
  }

  return (
    <div className={`rounded-md ${backgroundClassName} p-4`}>
      <div className="flex">
        {icon && <div className="flex-shrink-0">{icon}</div>}

        <div className="ml-3 flex-1 items-center md:flex md:justify-between">
          <div>
            <p className={`text-sm font-semibold ${textClassName}`}>{title}</p>
            {content && (
              <p className={`mt-1 text-sm ${textClassName}`}>{content}</p>
            )}
          </div>

          {link && (
            <p className="mr-3 mt-2 text-sm md:ml-6 md:mt-0">
              <span
                onClick={link.onClick}
                className={`inline-flex cursor-pointer items-center whitespace-nowrap font-bold ${buttonClassName}`}
              >
                {link.text}
                <span aria-hidden="true" className="ml-2 mt-0.5">
                  {" "}
                  &rarr;
                </span>
              </span>
            </p>
          )}
        </div>
      </div>

      {children}
    </div>
  );
}
