import Statistics from "@/components/Statistics";
import { useGetRevenuesQuery } from "@/services/connect";
import { useTranslation } from "react-i18next";

export default function Revenues() {
  const { t } = useTranslation(["dashboard"]);
  const { data: allTimeRevenues, isLoading: isLoadingAll } =
    useGetRevenuesQuery({ timePeriod: "all" });
  const { data: past30Revenues, isLoading: isLoadingPast30 } =
    useGetRevenuesQuery({ timePeriod: "past30" });

  const allTimeRevenuesAmount = allTimeRevenues?.formattedRevenues;
  const past30RevenuesAmount = past30Revenues?.formattedRevenues;

  return (
    <>
      <Statistics
        loading={isLoadingAll || isLoadingPast30}
        title={t("dashboard:revenues")}
        stats={[
          {
            name: t("dashboard:totalRevenue"),
            value: allTimeRevenuesAmount || "-",
          },
          {
            name: t("dashboard:revenueLast30Days"),
            value: past30RevenuesAmount || "-",
          },
        ]}
      />
    </>
  );
}
