import { FCP_Park } from "@/@types/park";
import Shell from "@/components/layout/default/Shell";
import { Card } from "@/components/ui/card";
import PlateCheckerHeader from "@/containers/plate-checker/components/header";
import PlateCheckerRequestModeImage from "@/containers/plate-checker/components/mode/mode-image";
import PlateCheckerRequestModeText from "@/containers/plate-checker/components/mode/mode-text";
import PlateCheckerRequest from "@/containers/plate-checker/components/request/request";
import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";
import {
  base64ToFile,
  getRequestResultValidity,
} from "@/containers/plate-checker/utils/plate-checker.utils";
import { useMediaQuery } from "@/hooks/use-media-query.hook";
import { useVerifyLicensePlateMutation } from "@/services/plate-checker.services";
import { CPException, CPPlateCheckerOwnerResponse } from "@clicknpark/sdk";
import { t } from "i18next";
import PQueue from "p-queue";
import { useEffect, useState } from "react";

const queue = new PQueue({ concurrency: 1 });

interface Props {
  parks: Partial<FCP_Park>[];
}

export default function PlateChecker({ parks }: Props) {
  const useVerifyLicensePlate = useVerifyLicensePlateMutation();

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [requestText, setRequestText] = useState("");
  const [requestMode, setRequestMode] = useState<PlateCheckerTypes.RequestMode>(
    isMobile ? "image" : "text"
  );
  const [requestParkIds, setRequestParkIds] = useState<string[]>([]);
  const [requests, setRequests] = useState<PlateCheckerTypes.Request[]>([]);

  useEffect(() => {
    if (!parks) return;
    if (!parks[0].objectId) return;
    setRequestParkIds([parks[0].objectId]);
  }, [parks]);

  const handleUpdateRequest = (
    index: number,
    updates: Partial<PlateCheckerTypes.Request>
  ) => {
    setRequests((requests) =>
      requests.map((request) =>
        request.index === index ? { ...request, ...updates } : request
      )
    );
  };

  const initRequest = async (plate: string) => {
    const index = requests.length + 1;

    setRequests([
      {
        index,
        requestedAt: new Date(),
        status: PlateCheckerTypes.RequestStatus.Queued,
        isContentVisible: false,
        result: undefined,
      },
      ...requests,
    ]);

    queue.add(async () => {
      handleUpdateRequest(index, {
        status: PlateCheckerTypes.RequestStatus.Processing,
      });

      try {
        let res: CPPlateCheckerOwnerResponse | undefined;

        if (requestMode === "image") {
          const file = base64ToFile(plate, "image.jpg", "image/jpeg");
          if (!file) throw new Error("No file provided");
          res = await useVerifyLicensePlate.mutateAsync({
            type: "image",
            image: file,
            parkIds: requestParkIds,
          });
        } else {
          res = await useVerifyLicensePlate.mutateAsync({
            type: "text",
            licensePlate: plate,
            parkIds: requestParkIds,
          });
        }

        handleUpdateRequest(index, {
          status: PlateCheckerTypes.RequestStatus.Completed,
          result: {
            status: res.isKnownVehicle
              ? PlateCheckerTypes.RequestResultStatus.PlateFoundInSystem
              : PlateCheckerTypes.RequestResultStatus.PlateNotFoundInSystem,
            validity: res.isKnownVehicle
              ? getRequestResultValidity(res.items)
              : undefined,
            data: res,
          },
        });
      } catch (e: unknown) {
        if (e instanceof CPException) {
          if (e.code === 5001) {
            handleUpdateRequest(index, {
              status: PlateCheckerTypes.RequestStatus.Completed,
              result: {
                status:
                  PlateCheckerTypes.RequestResultStatus.PlateNotDetectedInImage,
              },
            });
          } else {
            handleUpdateRequest(index, {
              status: PlateCheckerTypes.RequestStatus.Error,
              error: e,
            });
          }
        } else {
          let error: Error;
          if (e instanceof Error) error = e;
          else error = new Error("An unknown error occurred");
          console.error(error);
          handleUpdateRequest(index, {
            status: PlateCheckerTypes.RequestStatus.Error,
            error,
          });
        }
      }
    });
  };

  return (
    <Shell sticky={false}>
      <div className="relative pb-72">
        <PlateCheckerHeader
          {...{
            requestMode,
            onModeChange: setRequestMode,
            parks: parks
              .map(({ objectId, address }) => {
                if (!objectId) return null;
                return {
                  id: objectId,
                  name: `${address?.streetNumber} ${address?.street}`,
                };
              })
              .filter((park) => park !== null) as {
              id: string;
              name: string;
            }[],
            selectedPark: requestParkIds[0],
            onParkChange: setRequestParkIds,
          }}
        />

        <div className="bg-white shadow-md">
          {requestMode === "image" ? (
            <PlateCheckerRequestModeImage
              {...{
                onSubmit: initRequest,
              }}
            />
          ) : (
            <PlateCheckerRequestModeText
              {...{
                value: requestText,
                onChange: setRequestText,
                onSubmit: initRequest,
              }}
            />
          )}
        </div>

        {requests.length > 0 ? (
          <div className="space-y-2.5 p-2.5 pt-0 md:space-y-3 md:p-5">
            {requests.map((request, index) => {
              return (
                <Card key={index} className="p-0">
                  <PlateCheckerRequest
                    {...{ ...request, parkId: requestParkIds[0] }}
                  />
                </Card>
              );
            })}
          </div>
        ) : (
          <div className="p-12">
            <p className="text-center text-sm font-semibold text-slate-500">
              {t(
                `plateChecker:emptyState${
                  requestMode === "image" ? "Image" : "Text"
                }`
              )}
            </p>
          </div>
        )}
      </div>
    </Shell>
  );
}
