import api from "@/services/api";
import { useQuery } from "@tanstack/react-query";

type GetGenerateKycLinkRequest = {
  returnUrl: string;
  refreshUrl: string;
  enabled: boolean;
};

type GetGenerateKycLinkResponse = {
  url: string;
};

export const useGenerateKycLinkQuery = ({
  returnUrl,
  refreshUrl,
  enabled,
}: GetGenerateKycLinkRequest) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["kycLink"],
    queryFn: async () => {
      const { data } = await api.get<GetGenerateKycLinkResponse>(
        `/v2/users/me/connect/identityLink?returnUrl=${encodeURIComponent(
          returnUrl
        )}&refreshUrl=${encodeURIComponent(refreshUrl)}`
      );
      return data.url;
    },
    refetchOnMount: true,
    cacheTime: 1,
    enabled,
  });
};
