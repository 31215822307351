import keys from "@/config/keys";
import { v4 as uuidv4 } from "uuid";

export function getInstallationId() {
  let id = localStorage.getItem(keys.installationId);

  if (!id) {
    id = uuidv4();
    localStorage.setItem(keys.installationId, id);
  }

  return id;
}
