import { AsYouType, CountryCode } from "libphonenumber-js";

export function formatPhoneNumber(
  value: string,
  countryCode: CountryCode
): string {
  if (!value) return "";

  const formatter = new AsYouType(countryCode);
  const formattedNumber = formatter.input(value);

  if (formattedNumber.at(formattedNumber.length - 1)?.match(/\D/g)) {
    return formattedNumber.slice(0, formattedNumber.length - 1);
  }

  return formattedNumber;
}
