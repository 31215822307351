import FieldError from "@/components/form/fields/FieldError";
import FieldErrorIcon from "@/components/form/fields/FieldErrorIcon";
import FieldLabel from "@/components/form/fields/FieldLabel";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import {
  ForwardedRef,
  Fragment,
  InputHTMLAttributes,
  forwardRef,
  useState,
} from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  LeadingIcon?: JSX.Element;
  cornerHint?: string;
  LeadingSelect?: JSX.Element;
}

const FieldSecureText = forwardRef(
  (
    {
      id,
      name,
      label,
      placeholder,
      defaultValue,
      error,
      LeadingIcon,
      cornerHint,
      LeadingSelect,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    if (!id) id = name;

    const [visible, setVisible] = useState(false);

    const normalClasses =
      "text-simple-900 ring-gray-300 placeholder:text-gray-400 focus:ring-rapide-600";

    const errorClasses =
      "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500";

    const disabledClasses =
      "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-600 disabled:ring-gray-200";

    const sharedClasses = `px-3 block w-full rounded-md border-0 pt-2.5 pb-2 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
      LeadingSelect !== undefined ? "pl-16 " : " pl-none "
    }`;

    const className = sharedClasses.concat(
      [error ? errorClasses : normalClasses, disabledClasses].join(" ")
    );

    return (
      <Fragment>
        <div className="flex justify-between">
          {label && <FieldLabel label={label} htmlFor={name} />}

          {cornerHint ? (
            <span
              className="text-sm leading-6 text-gray-600"
              id="email-optional"
            >
              {cornerHint}
            </span>
          ) : null}
        </div>

        <div className={`relative ${label ? "mt-2" : ""}`}>
          <div
            className={`relative rounded-md ${
              LeadingSelect ? "rounder-l-none" : ""
            } shadow-sm`}
          >
            {LeadingIcon ? (
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                {LeadingIcon}
              </div>
            ) : null}

            <input
              ref={ref}
              type={visible ? "text" : "password"}
              name={name}
              id={name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              className={className}
              {...props}
            />

            <div className="absolute inset-y-[5px] right-[5px] z-10 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition-all hover:bg-silver-900">
              {visible ? (
                <EyeIcon
                  className="h-5 w-5 text-silver-600"
                  onClick={() => {
                    setVisible(false);
                  }}
                />
              ) : (
                <EyeSlashIcon
                  className="h-5 w-5 text-silver-600"
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              )}
            </div>

            {error ? <FieldErrorIcon /> : null}
          </div>

          {LeadingSelect}
        </div>

        {error ? <FieldError error={error} /> : null}
      </Fragment>
    );
  }
);

FieldSecureText.displayName = "FieldSecureText";

export default FieldSecureText;
