import { Place } from "@/@types/park";
import FieldError from "@/components/form/fields/FieldError";
import FieldErrorIcon from "@/components/form/fields/FieldErrorIcon";
import FieldLabel from "@/components/form/fields/FieldLabel";
import { getLanguage } from "@/utils/language";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

interface Props {
  label: string;
  placeholder?: string;
  onSelect: (address: Place) => void;
  error?: string;
  location?: google.maps.LatLngLiteral;
  autoFocus?: boolean;
}

const FieldAddress = ({
  label,
  placeholder,
  onSelect,
  error,
  location,
  autoFocus,
  ...props
}: Props) => {
  const { t } = useTranslation("common");

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [autoFocus]);

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: getLanguage(),
      location: location ? new google.maps.LatLng(location) : undefined,
      radius: 5000,
    },
    debounce: 300,
  });

  async function handleSelect({ description }: { description: string }) {
    setValue(description, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: description });
      const { lat, lng } = await getLatLng(results[0]);

      type ClicknparkAddressComponents =
        | "streetNumber"
        | "street"
        | "city"
        | "state"
        | "country"
        | "zip";

      const address: Place = {
        streetNumber: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        coordinates: { latitude: lat, longitude: lng },
      };

      const typesToGet: {
        google: string;
        clicnpark: ClicknparkAddressComponents;
      }[] = [
        { google: "street_number", clicnpark: "streetNumber" },
        { google: "route", clicnpark: "street" },
        { google: "locality", clicnpark: "city" },
        { google: "administrative_area_level_1", clicnpark: "state" },
        { google: "country", clicnpark: "country" },
        { google: "postal_code", clicnpark: "zip" },
      ];

      typesToGet.forEach((typeToGet) => {
        results[0].address_components.forEach((component) => {
          if (component["types"].includes(typeToGet.google)) {
            address[typeToGet.clicnpark] = component.long_name;
          }
        });
      });

      onSelect(address);
    } catch (error) {
      console.error(error);
    }
  }

  const normalClasses =
    "text-simple-900 ring-gray-300 placeholder:text-gray-400 focus:ring-rapide-600";

  const errorClasses =
    "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500";

  const disabledClasses =
    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-600 disabled:ring-gray-200";

  const sharedClasses = `px-3 block w-full rounded-md border-0 pt-3 pb-2 pl-3 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`;

  const className = sharedClasses.concat(
    [error ? errorClasses : normalClasses, disabledClasses].join(" ")
  );

  return (
    <div>
      <div className="flex justify-between">
        <FieldLabel label={label} />
      </div>

      <div className={`relative ${label ? "mt-2" : ""}`}>
        <div className="relative rounded-md shadow-sm">
          <input
            ref={ref}
            placeholder={placeholder}
            autoComplete="off"
            disabled={!ready}
            onChange={(e) => setValue(e.target.value)}
            className={className}
            {...props}
          />

          {error ? <FieldErrorIcon /> : null}
        </div>
      </div>

      {error ? <FieldError error={error} /> : null}

      {status === "OK" && (
        <ul className="mt-2 rounded-md border shadow-sm">
          <li className="rounded-tl-md rounded-tr-md bg-silver-900 p-3 text-sm font-bold">
            {t("clickOnRightAddress")}
          </li>

          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <li
                key={place_id}
                onClick={() => handleSelect(suggestion)}
                className="flex cursor-pointer items-center rounded-tl-md rounded-tr-md border-b p-3 text-sm duration-100 ease-in-out hover:bg-silver-900"
              >
                <img
                  src="/images/onboarding/icon-location.svg"
                  className="mr-3"
                />
                <span>
                  <strong>{main_text}</strong> <span>{secondary_text}</span>
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default FieldAddress;
