import { PropsWithChildren } from "react";

interface Props {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  noResultLabel?: React.ReactNode;
  datalist: {
    title?: React.ReactNode;
    description?: React.ReactNode;
    action?: React.ReactNode;
    data?: React.ReactNode;
  }[];
}

export function DataList({
  title,
  subtitle,
  noResultLabel = "",
  datalist,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      {title ? (
        <div>
          <h2 className="text-base font-semibold leading-7">{title}</h2>
          {subtitle && (
            <p className="mt-1 text-sm leading-6 text-gray-600">{subtitle}</p>
          )}
        </div>
      ) : null}

      <div className="md:col-span-2">
        <div className="divide-y divide-silver-800 sm:max-w-xl">
          {datalist.length > 0 ? (
            <>
              {datalist.map((item, index) => (
                <div className="py-5 first:pt-0" key={index}>
                  <div className="flex flex-row justify-start align-middle">
                    <div className="grow">
                      {item.title && (
                        <strong className="mb-1 block text-sm font-bold">
                          {item.title}
                        </strong>
                      )}
                      {item.description ? (
                        <div className="text-sm">{item.description}</div>
                      ) : null}
                    </div>
                    {item.action ? (
                      <div className="ml-5 flex">{item.action}</div>
                    ) : null}
                  </div>
                  {item.data ? (
                    <div className="text-sm">{item.data}</div>
                  ) : null}
                </div>
              ))}
            </>
          ) : (
            <p>{noResultLabel}</p>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
