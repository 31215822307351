import Spacer from "@/components/Spacer";
import AlertSimple from "@/components/alert/AlertSimple";
import FieldUpload from "@/components/form/fields/FieldUpload";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import FormLoader from "@/components/form/layout/FormLoader";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import { CPException } from "@/models/exceptions/CPException";
import { clicknpark } from "@/services/api";
import { getCurrentPark } from "@/utils/storage";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoClose, IoCloseCircle } from "react-icons/io5";

export default function ParkAddPhotos() {
  const { t } = useTranslation(["park", "validation", "common"]);

  const [uploading, setUploading] = useState(false);
  const [deletingPhotoId, setDeletingPhotoId] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const { next, back, exit, updatePark } = useParkAdd();
  const park = getCurrentPark();

  function onBack() {
    back();
  }

  function onNext() {
    next();
  }

  function onSaveAndExit() {
    exit();
  }

  async function onPhotoUpload(file: File) {
    if (!park?.objectId) return;

    setUploading(true);

    try {
      const { result } = clicknpark.parks.uploadPhotoToGallery(park.objectId, [
        file,
      ]);

      const uploadedPhotos = await result;

      try {
        // Make sure the park is up to date
        const currentPhotoIds =
          getCurrentPark()?.photos?.map((photo) => photo.id) || [];

        await updatePark(
          {
            photos: [
              ...currentPhotoIds,
              ...uploadedPhotos.map((photo) => photo.id),
            ],
          },
          true
        );
      } catch (error) {
        if (error instanceof CPException) {
          setError(error.message);
        } else {
          setError(t("validation:genericError"));
        }
      }
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setUploading(false);
  }

  async function deletePhoto(photoId: string) {
    setDeletingPhotoId(photoId);

    // Make sure the park is up to date
    const currentPhotoIds =
      getCurrentPark()?.photos?.map((photo) => photo.id) || [];

    const filteredPhotoIds = currentPhotoIds.filter(
      (currentPhotoId) => currentPhotoId !== photoId
    );

    await updatePark(
      {
        photos: filteredPhotoIds,
      },
      true
    );

    setDeletingPhotoId(undefined);
  }

  return (
    <ParkAddShell
      actions={{
        saveAndExit: {
          visible: true,
          disabled: uploading,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: uploading,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: uploading,
          onClick: onBack,
        },
      }}
    >
      <FormHeading title={t("park:photos")} />

      {error && <FormError title={error} />}

      <p className="font-bold">{t("park:photosHint")}</p>

      <div className="my-5 flex justify-between">
        <div className="mr-4">
          <div className="flex items-center">
            <CheckCircleIcon className="mr-1 h-[20px] w-[20px] text-green-600" />
            <p className="text-sm">{t("park:goodPhotos")}</p>
          </div>
          <img src="/images/photos-good.png" className="mt-2" />
        </div>

        <div className="ml-4">
          <div className="flex items-center">
            <IoCloseCircle className="mr-1 h-[20px] w-[20px] text-red-600" />
            <p className="text-sm">{t("park:badPhotos")}</p>
          </div>
          <img src="/images/photos-bad.png" className="mt-2" />
        </div>
      </div>

      {park?.photos && park.photos.length > 0 && (
        <div>
          <ul className="relative flex flex-wrap gap-5 overflow-auto pt-5">
            {park.photos.map((photo) => (
              <li key={photo.id} className="relative">
                <div
                  className="relative h-[75px] w-[75px] rounded-md border border-silver-600 bg-cover shadow-sm"
                  style={{
                    backgroundImage: `url(${photo.files.thumbnail.path})`,
                  }}
                >
                  {deletingPhotoId === photo.id && (
                    <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-md bg-white/75">
                      <FormLoader noMargin />
                    </div>
                  )}
                </div>
                <div
                  className="absolute right-[-7px] top-[-7px] flex h-[20px] w-[20px] items-center justify-center rounded-full bg-rapide-600 hover:cursor-pointer hover:bg-rapide-600 hover:shadow-sm"
                  onClick={() => deletePhoto(photo.id)}
                >
                  <IoClose color="#FFF" size={12} />
                </div>
              </li>
            ))}
          </ul>

          <Spacer />
        </div>
      )}

      <FormFields
        fields={[
          <FormField key="photos">
            <FieldUpload
              title={t(
                park?.photos && park.photos.length > 0
                  ? park.photos.length === 1
                    ? "park:photosUploaderTitleAdd1"
                    : "park:photosUploaderTitle"
                  : "park:photosUploaderTitleAdd2"
              )}
              description={
                !park?.photos || park?.photos?.length < 2
                  ? t("park:photosUploaderDescription")
                  : ""
              }
              buttonLabel={t("park:photosUploaderButton")}
              onUpload={onPhotoUpload}
              uploading={uploading}
            />
          </FormField>,
        ]}
      />

      <AlertSimple
        title={t("park:photosHelp")}
        content={t("park:photosHelpText")}
      />
    </ParkAddShell>
  );
}
