import Loader from "@/components/layout/Loader";
import Spacer from "@/components/Spacer";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import HelpPanel from "@/containers/HelpPanel";
import OnboardingMenu, {
  OnboardingMenuItem,
} from "@/containers/OnboardingMenu";
import {
  mapLegacyStepNumberToStepName,
  resumeToStep,
} from "@/contexts/ParkAddContext";
import useExitAnimation from "@/hooks/animation/useExitAnimation";
import useOnLogout from "@/hooks/use-on-logout.hook";
import useOnboarding from "@/hooks/useOnboarding";
import {
  getAltLanguageLabel,
  getLanguage,
  switchLanguage,
} from "@/utils/language";
import { getBifrostEmail, setBifrostSkipOnboarding } from "@/utils/storage";
import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function OnboardingResume() {
  const navigate = useNavigate();
  const logout = useOnLogout();

  const { t } = useTranslation(["common", "onboarding"]);
  const { launchExitAnimation, animatingExit, exitAnimationClassNames } =
    useExitAnimation({});
  const { kycRequired, parkSubmitRequired, park, loading } = useOnboarding();
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const menuItems: OnboardingMenuItem[] = [
    {
      name: t("common:switchToLanguage", {
        language: getAltLanguageLabel(false),
      }),
      onClick: () => {
        switchLanguage();
        setSubmenuOpen(false);
      },
    },
    {
      name: t("common:logout"),
      onClick: logout,
    },
  ];

  if (getBifrostEmail()) {
    menuItems.push({
      name: t("common:bifrostLeave"),
      onClick: () => {
        setBifrostSkipOnboarding();
        navigate("/");
      },
    });
  }

  async function next() {
    if (parkSubmitRequired) {
      if (park) {
        const stepToResumeTo = park?.uxMetadata?.resumeToStep;
        const query = `?id=${park.objectId}`;

        // Handle legacy resumeToStep which was a number until 2023/09/01
        if (typeof stepToResumeTo === "number") {
          resumeToStep(mapLegacyStepNumberToStepName(stepToResumeTo), query);
        } else if (typeof stepToResumeTo === "string") {
          resumeToStep(stepToResumeTo, query);
        } else {
          // Fallback to the first step
          resumeToStep("location", query);
        }
      } else {
        navigate("/parks/add/1");
      }

      launchExitAnimation();
      return;
    }

    if (kycRequired) {
      navigate("/onboarding/kyc/start");
      launchExitAnimation();
      return;
    }

    navigate("/");
  }

  const headerClassName = "h-[85px] md:h-[92px] px-5";
  const footerClassName = "h-[85px] md:h-[94px] px-5";
  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-85px-44px)] md:h-[calc(100dvh-92px-94px)]"
    : "h-[calc(100dvh-85px-85px)] md:h-[calc(100dvh-92px-94px)]";

  return (
    <main className="block bg-white lg:flex lg:h-[100dvh] lg:flex-col">
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <header
        className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
      >
        <img
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <div className="flex items-center justify-between">
          <Button
            className="mr-3"
            variant="outline"
            onClick={() => setHelpPanelOpen(true)}
          >
            <div className="flex items-center">{t("common:help")}</div>
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <div className="inline-flex items-center">
                {t("common:menu")}
                <IconChevronDown
                  className={`ml-1 h-[20px] w-[20px] ${
                    submenuOpen ? "rotate-180" : ""
                  } transition-all`}
                />
              </div>
            </Button>

            {submenuOpen && (
              <div className="absolute right-0 top-[50px] z-30">
                <OnboardingMenu items={menuItems} />
              </div>
            )}
          </div>
        </div>
      </header>

      <main
        className={`${mainClassName} relative mx-auto overflow-hidden bg-white md:max-w-[500px] md:px-0 ${
          (animatingExit && exitAnimationClassNames) || ""
        }`}
      >
        <div className="h-full overflow-auto">
          <div className="mx-auto h-full md:max-w-[500px]">
            <div className="h-full px-6 py-8 md:px-6 md:py-12">
              <div className="animate-fade animate-delay-200 animate-alternate animate-duration-[1000ms] animate-once animate-ease-in-out">
                <h1 className="bg-gradient-to-br from-rapide-600 to-sky-200 bg-clip-text text-3xl font-bold text-transparent">
                  {t("onboarding:resumeProgress")}
                </h1>

                <p className="mb-5 mt-3 max-w-3xl text-xl font-semibold">
                  {t("onboarding:completeFollowingSteps")}
                </p>

                <ul role="list">
                  <li className="my-5 flex items-start overflow-hidden rounded-lg bg-white p-6 shadow ring-1 ring-black ring-opacity-5">
                    <img
                      src="/images/onboarding/icon-park.svg"
                      className="mr-5"
                    />
                    <div>
                      <h2 className="text-md mb-1 font-bold">
                        {t("onboarding:addParkingTitle")}
                      </h2>
                      {!parkSubmitRequired ? (
                        <p className="text-sm font-semibold text-green-600">
                          {t("onboarding:completedStep")}
                        </p>
                      ) : (
                        <p className="text-sm">
                          {t("onboarding:addParkingDescription")}
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="my-5 flex items-start overflow-hidden rounded-lg bg-white p-6 shadow ring-1 ring-black ring-opacity-5">
                    <img
                      src="/images/onboarding/icon-verification.svg"
                      className="mr-5"
                    />

                    <div>
                      <h2 className="text-md mb-1 font-bold">
                        {t("onboarding:completeKycTitle")}
                      </h2>
                      {!kycRequired ? (
                        <p className="text-sm font-semibold text-green-600">
                          {t("onboarding:completedStep")}
                        </p>
                      ) : (
                        <p className="text-sm">
                          {t("onboarding:completeKycDescription")}
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="my-5 flex items-start overflow-hidden rounded-lg bg-white p-6 shadow ring-1 ring-black ring-opacity-5">
                    <img
                      src="/images/onboarding/icon-link-bank-account.svg"
                      className="mr-5"
                    />
                    <div>
                      <h2 className="text-md mb-1 font-bold">
                        {t("onboarding:configurePaymentsTitle")}
                      </h2>
                      <p className="text-sm">
                        {t("onboarding:configurePaymentsDescription")}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <Spacer />
            </div>
          </div>
        </div>
      </main>

      <footer
        className={`${footerClassName} relative z-20 mx-auto flex w-full items-center justify-center border-t border-silver-800 bg-white pb-[5px] sm:static md:px-0`}
      >
        <Button size="lg" onClick={next}>
          {t("common:continue")}
        </Button>
      </footer>

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />

      {loading && (
        <div className="fixed bottom-0 left-0 right-0 top-0 bg-white/25">
          <Loader />
        </div>
      )}
    </main>
  );
}
