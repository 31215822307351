import i18next from "i18next";

export type Language = "fr" | "en";

export function getLanguage(): Language {
  const language = i18next.language as Language;
  return language || "en";
}

export function getAltLanguage(): Language {
  const language = i18next.language as Language;
  return language === "en" || !language ? "fr" : "en";
}

export function getCurrentLanguageLabel(translated?: boolean) {
  const language = getLanguage();

  if (language === "en") {
    return translated ? "Anglais" : "English";
  }

  if (language === "fr") {
    return translated ? "French" : "Français";
  }
}

export function getAltLanguageLabel(translated?: boolean) {
  const language = getLanguage();

  if (language === "en") {
    return translated ? "Français" : "French";
  }

  if (language === "fr") {
    return translated ? "English" : "Anglais";
  }

  return "";
}

export function chooseLanguage(language: string) {
  i18next.changeLanguage(language);
}

export function switchLanguage() {
  const language = getLanguage();
  i18next.changeLanguage(language === "en" ? "fr" : "en");
}
