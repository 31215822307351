import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";
import classNames from "@/utils/cx";
import { ParkingSquare } from "lucide-react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

interface Props {
  requestMode: PlateCheckerTypes.RequestMode;
  onModeChange: (mode: PlateCheckerTypes.RequestMode) => void;
  parks?: { id: string; name: string }[];
  selectedPark?: string;
  onParkChange: (parkIds: string[]) => void;
}

export default function PlateCheckerHeader({
  requestMode,
  onModeChange,
  parks,
  selectedPark,
  onParkChange,
}: Props) {
  const { t } = useTranslation(["plateChecker"]);

  const [isParkSelectionOpen, toggleParkSelection] = useReducer(
    (state) => !state,
    true
  );

  return (
    <Fragment>
      <div className="fixed bottom-5 left-5 right-5 z-10 flex flex-col rounded-xl border-4 border-rapide-600 bg-white shadow-xl shadow-black/10 md:static md:rounded-none md:border-none md:shadow-none">
        <div
          className={classNames(
            "flex items-center justify-between gap-2.5 pl-2.5 md:hidden",
            isParkSelectionOpen ? "border-b border-silver-600" : "border-none"
          )}
        >
          <button
            className={classNames(
              "flex-1 rounded-md p-2.5 text-center font-bold",
              requestMode === "text"
                ? "bg-rapide-600 text-white"
                : "text-rapide-600"
            )}
            onClick={() => onModeChange("text")}
          >
            {t("plateChecker:textRequest")}
          </button>

          <button
            className={classNames(
              "flex-1 rounded-md p-2.5 text-center font-bold",
              requestMode === "image"
                ? "bg-rapide-600 text-white"
                : "text-rapide-600"
            )}
            onClick={() => onModeChange("image")}
          >
            {t("plateChecker:imageRequest")}
          </button>

          {parks && parks.length > 1 && (
            <button
              onClick={toggleParkSelection}
              className={classNames(
                "border-l border-silver-800 p-5",
                isParkSelectionOpen
                  ? "rounded-none border-none bg-rapide-600 text-white"
                  : "rounded-br-md rounded-tr-md bg-white text-rapide-600"
              )}
            >
              <ParkingSquare size={24} />
            </button>
          )}
        </div>

        {isParkSelectionOpen && parks && parks.length > 1 ? (
          <select
            value={selectedPark || ""}
            className="m-2.5 flex-1 rounded-md p-2.5 md:m-5 md:mb-0"
            onChange={(e) => onParkChange([e.target.value])}
          >
            {parks.map(({ id, name }, index) => {
              return (
                <option value={id} key={id + index}>
                  {name}
                </option>
              );
            })}
          </select>
        ) : null}
      </div>
    </Fragment>
  );
}
