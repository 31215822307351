import classNames from "@/utils/cx";
import { Switch } from "@headlessui/react";

interface Props {
  label?: React.ReactNode;
  description?: React.ReactNode;
  checked?: boolean;
  alignItems?: "center" | "start" | "end";
  labelPosition?: "left" | "right";
  labelWeight?: "bold" | "normal";
  onChange?: (checked: boolean) => void;
}

export default function FieldSwitch({
  label,
  description,
  checked,
  alignItems = "center",
  labelPosition = "left",
  labelWeight = "bold",
  onChange,
}: Props) {
  let alignItemsClass = "items-center";
  let labelWeightClass = "font-bold";

  if (alignItems === "start") {
    alignItemsClass = "items-start";
  } else if (alignItems === "end") {
    alignItemsClass = "items-end";
  }

  if (labelWeight === "normal") {
    labelWeightClass = "font-normal";

    if (alignItems === "start") {
      // Adjust label position because Gilroy font has a weird baseline
      labelWeightClass += " mt-[-5px]";
    }
  }

  return (
    <Switch.Group
      as="div"
      className={`flex justify-between ${alignItemsClass}`}
    >
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? "bg-rapide-600" : "bg-gray-200",
          labelPosition === "left" ? "order-2 ml-5" : "order-1 mr-5",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-rapide-600 focus:ring-offset-2"
        )}
      >
        <span
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>

      <span
        className={classNames(
          labelPosition === "left" ? "order-1" : "order-2",
          "flex flex-grow flex-col",
          labelWeightClass,
          alignItemsClass === "items-start" ? "mt-[-2px]" : ""
        )}
      >
        {label && (
          <Switch.Label
            as="span"
            className="text-sm leading-6 text-simple-900"
            passive
          >
            {label}
          </Switch.Label>
        )}
        {description ? (
          <Switch.Description as="span" className="text-sm text-gray-600">
            {description}
          </Switch.Description>
        ) : null}
      </span>
    </Switch.Group>
  );
}
