import { PlateCheckerRequestContext } from "@/containers/plate-checker/contexts/plate-checker-request.context";
import { useReportPlateIssueMutation } from "@/services/plate-checker.services";
import { useGetUserQuery } from "@/services/user";
import classNames from "@/utils/cx";
import { CheckCircle } from "lucide-react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PlateCheckerRequestReport() {
  const context = useContext(PlateCheckerRequestContext);
  const { t } = useTranslation(["plateChecker"]);

  const [message, setMessage] = useState("");
  const reportPlateIssueMutation = useReportPlateIssueMutation();

  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data;

  if (!context) return null;

  const { request, isContentVisible } = context;

  if (!isContentVisible) return null;
  if (!request.result?.data) return null;

  function submitReport() {
    if (!request.result?.data) return null;
    reportPlateIssueMutation.mutateAsync({
      message,
      platform: "dashboard",
      userId: user?.objectId,
      parkIds: context?.parkId ? [context.parkId] : [],
      data: request.result.data,
    });
  }

  return reportPlateIssueMutation.isSuccess ? (
    <div className="p-5">
      <p className="flex items-center gap-2 font-semibold text-green-600">
        <CheckCircle size={16} strokeWidth={3} />
        {t("plateChecker:reportSent")}
      </p>
    </div>
  ) : (
    <div className="p-5">
      <div className="max-w-[600px]">
        <p className="mb-2.5 text-sm font-bold">
          {t("plateChecker:reportIssueTitle")}
        </p>

        <label htmlFor="message" className="mb-4 block text-sm font-semibold">
          {t("plateChecker:reportIssueMessage")}
        </label>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitReport();
          }}
        >
          <textarea
            id="message"
            name="message"
            value={message}
            className="mb-2.5 min-h-[80px] w-full rounded-md border border-silver-600"
            onChange={(e) => setMessage(e.target.value)}
          />

          <button
            className={classNames(
              "w-full rounded-md bg-red-500 p-2.5 text-center font-semibold text-white shadow-md hover:bg-red-600",
              reportPlateIssueMutation.isPending ? "pointer-events-none" : ""
            )}
          >
            {t("plateChecker:reportIssue")}
          </button>
        </form>
      </div>
    </div>
  );
}
