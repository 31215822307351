import { clicknpark } from "@/services/api";
import {
  CPException,
  CPPlateCheckerOwnerResponse,
  PlateCheckerValidateRequest,
} from "@clicknpark/sdk";
import { useMutation } from "@tanstack/react-query";

// Verify License Plate (POST)
// ========================================

export const PLATE_CHECKER_MUTATION_KEY = "create-park-rate";

export const verifyLicensePlate = async (
  request: PlateCheckerValidateRequest
) => {
  return clicknpark.owner.verifyLicensePlate(request);
};

export const useVerifyLicensePlateMutation = () => {
  return useMutation<
    CPPlateCheckerOwnerResponse,
    CPException,
    PlateCheckerValidateRequest
  >({
    mutationKey: [PLATE_CHECKER_MUTATION_KEY],
    mutationFn: (request) => verifyLicensePlate(request),
  });
};

// Report License Plate Issue (POST)
// ========================================

interface ReportPlateIssueRequest {
  message?: string;
  platform: string;
  userId?: string;
  parkIds?: string[];
  data: CPPlateCheckerOwnerResponse;
}

export const REPORT_PLATE_ISSUE_MUTATION_KEY = "report-plate-issue";

export const reportPlateIssue = async (request: ReportPlateIssueRequest) => {
  return fetch("https://clicknpark.com/api/report-plate-checker-issue", {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
};

export const useReportPlateIssueMutation = () => {
  return useMutation<
    CPPlateCheckerOwnerResponse,
    CPException,
    ReportPlateIssueRequest
  >({
    mutationKey: [REPORT_PLATE_ISSUE_MUTATION_KEY],
    mutationFn: (request) => reportPlateIssue(request),
  });
}

