import { FCP_Park } from "@/@types/park";
import { useGetParkQuery } from "@/services/park";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";

export type ParkEditContextProps = {
  park?: FCP_Park;
};

const ParkEditContext = createContext<ParkEditContextProps>({});

interface Props {
  children: React.ReactNode;
}

const ParkEditProvider = ({ children }: Props) => {
  const { id: parkId } = useParams();

  if (!parkId) return <>{children}</>;

  const { data } = useGetParkQuery({ parkId });

  return (
    <ParkEditContext.Provider value={{ park: data as FCP_Park }}>
      {children}
    </ParkEditContext.Provider>
  );
};

export { ParkEditProvider };
export default ParkEditContext;
