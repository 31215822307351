import Spacer from "@/components/Spacer";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import useExitAnimation from "@/hooks/animation/useExitAnimation";
import { useUpdateOnboardingStepMutation } from "@/services/owner";
import { getBifrostEmail } from "@/utils/storage";
import * as tracker from "@/utils/tracker";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default function OnboardingEnd() {
  const { t } = useTranslation(["onboarding", "common"]);
  const navigate = useNavigate();
  const { mutateAsync: updateOnboadingStep } =
    useUpdateOnboardingStepMutation();

  const { launchExitAnimation, animatingExit, exitAnimationClassNames } =
    useExitAnimation({});

  useEffectOnce(() => {
    tracker.track.onboarding.completed();
    updateOnboadingStep("completed");
  });

  const footerClassName = "h-[85px] md:h-[94px] px-5";
  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-44px)] md:h-[calc(100dvh-94px-44px)]"
    : "h-[calc(100dvh-85px)] md:h-[calc(100dvh-94px)]";

  return (
    <>
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <main
        className={`${mainClassName} relative mx-auto flex max-w-[1800px] flex-1 overflow-hidden bg-white md:items-center md:justify-center md:px-0 ${
          animatingExit && exitAnimationClassNames
        }`}
      >
        <div className="h-full overflow-auto px-8 py-8">
          <div className="h-full flex-1 flex-wrap md:flex md:content-center md:items-center md:justify-center">
            <div className="animate-fade animate-delay-200 animate-alternate animate-duration-[1000ms] animate-once animate-ease-in-out">
              <h1 className="lg:text-7xl 2xl:text-8xl bg-gradient-to-br from-rapide-600 to-sky-200 bg-clip-text text-4xl font-bold text-transparent sm:text-5xl">
                {t("onboarding:onboardingFinishedTitle")}
              </h1>

              <p className="mt-6 max-w-3xl text-xl font-semibold sm:text-2xl">
                {t("onboarding:onboardingFinishedDescription")}
              </p>

              <p className="mt-6 max-w-3xl text-xl font-semibold sm:text-2xl">
                {t("onboarding:onboardingFinishedDescription2")}
              </p>

              <ul className="mt-6 max-w-3xl text-xl sm:text-2xl">
                <p className="mb-5">
                  {t("onboarding:onboardingFinishedListTitle")}
                </p>
                <li>- {t("onboarding:onboardingFinishedListItem1")}</li>
                <li>- {t("onboarding:onboardingFinishedListItem2")}</li>
                <li>- {t("onboarding:onboardingFinishedListItem3")}</li>
                <li>- {t("onboarding:onboardingFinishedListItem4")}</li>
              </ul>
            </div>
          </div>
        </div>

        <Spacer />
      </main>

      <footer
        className={`${footerClassName} border-silver-90 relative z-20 mx-auto flex w-full items-center justify-center border-t bg-white pb-[5px] sm:static md:px-0`}
      >
        <Button
          size="lg"
          onClick={async () => {
            await launchExitAnimation();

            navigate("/");
          }}
        >
          {t("onboarding:continueToDashboard")}
        </Button>
      </footer>
    </>
  );
}
