// import { log } from "@/services/sentry.services";
import i18n from "@/i18n";
import { CPError } from "@/models/errors/CPError";
import { CPException } from "@/models/exceptions/CPException";
import { loadStripe } from "@stripe/stripe-js";

const l = "services/generateStripeBankAccountToken";

export async function generateStripeBankAccountToken(
  country: string,
  currency: string,
  account_holder_name: string,
  account_holder_type: string,
  routing_number: string,
  account_number: string
): Promise<any> {
  const { t } = i18n;
  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

  if (stripe) {
    // @ts-ignore
    const res = await stripe.createToken("bank_account", {
      bank_account: {
        country,
        currency,
        account_holder_name,
        account_holder_type,
        routing_number,
        account_number,
      },
    });

    if (res.error) {
      let cpError: CPError = {
        code: "DEFAULT_ERROR",
        message: t("validation:genericError"),
      };

      if (res.error.message) {
        cpError.message = "Unhandled: " + res.error.message;
      }

      if (res.error.code === "bank_account[routing_number]") {
        cpError.code = "STRIPE_ROUTING_NUMBER_INVALID";
        cpError.message = t("validation:routingNumberInvalid");
      }

      if (res.error.code === "routing_number_invalid") {
        cpError.code = "STRIPE_ROUTING_NUMBER_INVALID";
        cpError.message = t("validation:routingNumberInvalid");
      }

      if (res.error.code === "account_number_invalid") {
        cpError.code = "STRIPE_ROUTING_NUMBER_INVALID";
        cpError.message = t("validation:accountNumberInvalid");
      }

      return Promise.reject(new CPException(cpError));
    }

    return { data: res.token?.id };
  } else {
    const cpError: CPError = {
      code: "STRIPE_SDK_NOT_LOADED",
      message: t("validation:genericError"),
    };

    return Promise.reject(new CPException(cpError));
  }
}
