import { CPOnSitePaymentMethod } from "@/@types/park";
import FieldCheckboxesCards from "@/components/form/fields/FieldCheckboxesCards";
import FieldCheckboxesInline from "@/components/form/fields/FieldCheckboxesInline";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import {
  convertArrayToFeatures,
  convertFeaturesToArray,
} from "@/helpers/park.helpers";
import useParkAdd from "@/hooks/context/useParkAdd";
import { CPException } from "@/models/exceptions/CPException";
import { getBifrostEmail, getCurrentPark } from "@/utils/storage";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ParkAddFeatures() {
  const { t } = useTranslation(["common", "park", "validation"]);

  const [formError, setFormError] = useState<string | undefined>();
  const [formAction, setFormAction] = useState<"next" | "exit">("next");
  const { next, back, exit, updatePark } = useParkAdd();
  const park = getCurrentPark();

  const initialValues: {
    features: string[];
    maximumHeight?: number;
    onSitePaymentMethods: CPOnSitePaymentMethod[];
  } = {
    features: park?.featuresV3
      ? convertFeaturesToArray(park.featuresV3)
      : ["allowsMultiUse", "allowsStuddedTires"],
    maximumHeight: park?.featuresV3?.maximumHeight
      ? park.featuresV3.maximumHeight
      : undefined,
    onSitePaymentMethods: park?.featuresV3?.onSitePaymentMethods
      ? park.featuresV3.onSitePaymentMethods
      : [],
  };

  const { values, submitForm, isSubmitting, setFieldValue, handleChange } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        if (!park) return;

        setFormError(undefined);

        try {
          await updatePark({
            featuresV3: {
              ...park.featuresV3,
              ...convertArrayToFeatures(values.features),
              maximumHeight: values.maximumHeight,
              onSitePaymentMethods: values.onSitePaymentMethods,
            },
          });

          formAction === "next" ? next() : exit();
        } catch (error) {
          if (error instanceof CPException) {
            setFormError(error.message);
          } else {
            setFormError(t("validation:genericError"));
          }
        }
      },
    });

  function onBack() {
    back();
  }

  function onNext() {
    setFormAction("next");
    submitForm();
  }

  function onSaveAndExit() {
    setFormAction("exit");
    submitForm();
  }

  const ExtraBifrostOptions = getBifrostEmail() ? (
    <div className="rounded-xl border border-rapide-600 px-10 py-5">
      <div className="mb-3 mt-5">
        <p className="text-md font-semibold leading-5 text-indigo-500">
          {t("common:extraOptionsForBifrostAgent")}
        </p>
      </div>

      <FormFields
        fields={[
          <FormField key="allowsMultiUse">
            <FieldCheckboxesCards
              selectedValues={values.features}
              onCheck={(option) =>
                setFieldValue("features", [...values.features, option.value])
              }
              onUncheck={(option) =>
                setFieldValue(
                  "features",
                  values.features.filter((feature) => feature !== option.value)
                )
              }
              options={[
                {
                  name: t("park:multiUse"),
                  description: t("park:multiUseDescription"),
                  value: "allowsMultiUse",
                },
                {
                  name: t("park:allowsStuddedTires"),
                  description: t("park:allowsStuddedTiresDescription"),
                  value: "allowsStuddedTires",
                },
                {
                  name: t("park:hasCarWash"),
                  description: t("park:hasCarWashDescription"),
                  value: "hasCarWash",
                },
              ]}
            />
          </FormField>,
          <FormField key="onSitePaymentMethods">
            <p className="text-sm font-semibold leading-5 text-gray-600">
              {t("park:onSitePaymentMethods")}
            </p>
            <FieldCheckboxesInline
              direction="column"
              options={[
                {
                  name: "American Express",
                  value: "amex",
                },
                {
                  name: "Visa",
                  value: "visa",
                },
                {
                  name: "Mastercard",
                  value: "mastercard",
                },
                {
                  name: "Interact",
                  value: "interact",
                },
                {
                  name: "Cash",
                  value: "cash",
                },
              ]}
              selectedValues={values.onSitePaymentMethods}
              onCheck={(option) => {
                setFieldValue("onSitePaymentMethods", [
                  ...values.onSitePaymentMethods,
                  option.value,
                ]);
              }}
              onUncheck={(option) => {
                setFieldValue(
                  "onSitePaymentMethods",
                  values.onSitePaymentMethods.filter(
                    (method) => method !== option.value
                  )
                );
              }}
            />
          </FormField>,
          <FormField key="maximumHeight">
            <FieldText
              name="maximumHeight"
              label={t("park:maximumHeight")}
              value={values.maximumHeight}
              onChange={handleChange}
              type="number"
              step="0.01"
            />
          </FormField>,
        ]}
      />
    </div>
  ) : null;

  return (
    <ParkAddShell
      loading={isSubmitting}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: isSubmitting,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      {formError && <FormError title={formError} />}

      <FormFields
        fields={[
          <FormField key="features">
            <FormHeading title={t("park:whatFeatures")} />
            <FieldCheckboxesCards
              selectedValues={values.features}
              onCheck={(option) =>
                setFieldValue("features", [...values.features, option.value])
              }
              onUncheck={(option) =>
                setFieldValue(
                  "features",
                  values.features.filter((feature) => feature !== option.value)
                )
              }
              options={[
                {
                  name: t("park:wide"),
                  description: t("park:wideDescription"),
                  value: "wide",
                  image: <img src="/images/features/wide.svg" width={50} />,
                },
                {
                  name: t("park:covered"),
                  description: t("park:coveredDescription"),
                  value: "covered",
                  image: <img src="/images/features/covered.svg" width={50} />,
                },
                {
                  name: t("park:video"),
                  description: t("park:videoDescription"),
                  value: "video",
                  image: <img src="/images/features/video.svg" width={50} />,
                },
                {
                  name: t("park:onsiteStaff"),
                  description: t("park:onsiteStaffDescription"),
                  value: "onsiteStaff",
                  image: (
                    <img
                      src="/images/features/onsiteStaff.svg"
                      width={50}
                      className="mt-1"
                    />
                  ),
                },
              ]}
            />
          </FormField>,
        ]}
      />

      {ExtraBifrostOptions}
    </ParkAddShell>
  );
}
