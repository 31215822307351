import App from "@/App";
import "@/i18n";
import "@/main.scss";
import "@clicknpark/react/dist/css/clicknpark.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PostHogProvider } from "posthog-js/react";
import "react-day-picker/dist/style.css";
import ReactDOM from "react-dom/client";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <PostHogProvider
    apiKey={import.meta.env.VITE_POSTHOG_KEY}
    options={{ api_host: import.meta.env.VITE_POSTHOG_HOST }}
  >
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </PostHogProvider>
);
