interface Props {
  error: string;
}

export default function FieldError({ error }: Props) {
  return (
    <p className="mt-2 text-sm text-red-600" id="email-error">
      {error}
    </p>
  );
}
