import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import Monitoring from "@/containers/dashboard/Monitoring";
import Payouts from "@/containers/dashboard/Payouts";
import Revenues from "@/containers/dashboard/Revenues";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation(["common"]);

  return (
    <Shell>
      <PageHeader title={t("overview")} />

      <div className="divide-y divide-silver-800">
        <PageSection padding="lg">
          <Revenues />
        </PageSection>
        <PageSection padding="lg">
          <Monitoring />
        </PageSection>
        <PageSection padding="lg">
          <Payouts />
        </PageSection>
      </div>
    </Shell>
  );
}
