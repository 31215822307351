import { IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
}

export default function FormSuccess({ visible }: Props) {
  const { t } = useTranslation(["common"]);

  return (
    <div
      className={`ml-5 flex items-center text-sm font-semibold text-green-600 transition-opacity ${
        visible ? "opacity-1" : "opacity-0"
      }`}
    >
      <IconCheck className="mr-1 w-[16px]" /> {t("common:saved")}
    </div>
  );
}
