import { getLanguage } from "@/utils/language";

const companyData = {
  phoneNumber: "18559797275",
  formattedPhoneNumber: "1-855-979-7275",
  email: "info@clicknpark.com",
  termsOfUse: `https://clicknpark.com/${
    getLanguage() === "fr" ? "fr-CA/conditions-d-utilisation" : "terms-of-use"
  }`,
  privacyPolicy: `https://clicknpark.com/${
    getLanguage() === "fr"
      ? "fr-CA/politique-de-confidentialite"
      : "privacy-policy"
  }`,
};

export default companyData;
