import { useState } from "react";

interface Props {
  entryAnimationDelay?: number;
  entryAnimationClassNames?: string;
  entryAnimationSpeed?: "fast" | "slow";
}

export default function useEntryAnimation({
  entryAnimationDelay = 1500,
  entryAnimationSpeed = "fast",
  entryAnimationClassNames = "animate-fade animate-once animate-ease-in-out",
}: Props) {
  const [animatingEntry, setanimatingEntry] = useState(false);

  entryAnimationClassNames += ` animate-duration-${
    entryAnimationSpeed === "fast" ? "[750ms]" : "[1500ms]"
  }`;

  async function launchEntryAnimation() {
    setTimeout(() => {
      setanimatingEntry(true);
    }, entryAnimationDelay);
  }

  return {
    launchEntryAnimation,
    animatingEntry,
    entryAnimationClassNames,
  };
}
