import { FCP_Exception, FCP_ExceptionConflict } from "@/@types/park";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

interface GetExceptionsRequest {
  parkId: string;
}

type GetExceptionsResponse = {
  conflicts: FCP_ExceptionConflict[];
  exception: FCP_Exception;
}[];

export const useLazyGetExceptions = () => {
  const queryClient = useQueryClient();
  return async ({ parkId }: GetExceptionsRequest) => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyExceptions" + parkId],
      queryFn: async () => {
        const { data } = await api.get<GetExceptionsResponse>(
          `/parks/${parkId}/exceptions`
        );
        return data;
      },
    });
  };
};
