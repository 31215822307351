import { getLanguage } from "@/utils/language";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";

const displayName = new Intl.DisplayNames([getLanguage()], { type: "region" });

export const phoneCountries = getCountries().map((countryCode) => ({
  countryName: displayName.of(countryCode),
  callingCode: getCountryCallingCode(countryCode),
  countryCode,
}));
