import Loader from "@/components/layout/Loader";
import useParkAdd from "@/hooks/context/useParkAdd";
import * as tracker from "@/utils/tracker";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default function ParkAdd() {
  const { loading, step } = useParkAdd();
  const [progressPercent, setProgressPercent] = useState(0);

  useEffectOnce(() => {
    tracker.track.parkAdd.started();
  });

  useEffect(() => {
    if (loading) return;
    setProgressPercent(Math.floor((step / 13) * 100));
  }, [step, loading]);

  return (
    <div>
      {loading ? <Loader /> : <Outlet />}

      <div className={`fixed bottom-0 z-40 h-[5px] w-full bg-silver-800`}>
        <div
          className={`h-[5px] bg-rapide-600 duration-300 ease-in-out`}
          style={{
            width: `${progressPercent}%`,
          }}
        />
      </div>
    </div>
  );
}
