import { CPParkFeature } from "@/@types/park";

export function convertFeaturesToArray(features: CPParkFeature): string[] {
  const array: string[] = [];
  if (features.allowsStuddedTires) array.push("allowsStuddedTires");
  if (features.allowsMultiUse) array.push("allowsMultiUse");
  if (features.wideness) array.push("wideness");
  if (features.hasStaff) array.push("hasStaff");
  if (features.hasCCTV) array.push("hasCCTV");
  if (features.hasCarWash) array.push("hasCarWash");
  return array;
}

export function convertArrayToFeatures(array: string[]): CPParkFeature {
  const features: CPParkFeature = {
    allowsStuddedTires: false,
    allowsMultiUse: false,
    onSitePaymentMethods: [],
  };
  if (array.includes("allowsStuddedTires")) features.allowsStuddedTires = true;
  if (array.includes("allowsMultiUse")) features.allowsMultiUse = true;
  if (array.includes("wideness")) features.wideness = "wide";
  if (array.includes("hasStaff")) features.hasStaff = true;
  if (array.includes("hasCCTV")) features.hasCCTV = true;
  if (array.includes("hasCarWash")) features.hasCarWash = true;
  return features;
}
