import FieldSelect from "@/components/form/fields/FieldSelect";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import { CPException } from "@/models/exceptions/CPException";
import { useGetUserQuery, useUpdateUserLocale } from "@/services/user";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export type UserLocale = "fr-ca" | "en-ca";

export default function FormNotificationsLocale() {
  const { t } = useTranslation(["account", "common", "validation"]);
  const { data: user } = useGetUserQuery();
  const { mutateAsync: updateLocaleAsync } = useUpdateUserLocale();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues: {
    locale: UserLocale;
  } = {
    locale: (user?.locale as UserLocale) || "fr-ca",
  };

  const validationSchema: unknown = Yup.object().shape({
    locale: Yup.string().required(t("validation:localeRequired")),
  });

  const { submitForm, values, isValid, isSubmitting, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnBlur: true,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: async () => {
        setFormError(undefined);
        setFormSuccess(false);

        try {
          await updateLocaleAsync(values.locale);
          setFormSuccess(true);
          setFormError(undefined);
          setTimeout(() => {
            setFormSuccess(false);
          }, 5000);
        } catch (error) {
          if (error instanceof CPException) {
            setFormError(error.message);
          } else {
            setFormError(t("validation:genericError"));
          }
        }
      },
    });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("account:notificationsLanguage")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("account:notificationsLanguageDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField key="directionsFr">
                <FieldSelect
                  options={[
                    { value: "fr-ca", name: "Français" },
                    { value: "en-ca", name: "English" },
                  ]}
                  onSelect={({ value }) => setFieldValue("locale", value)}
                  selectedOptionValue={values.locale}
                />
              </FormField>,
            ]}
          />
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
