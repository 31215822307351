import { Place } from "@/@types/park";
import FieldAddress from "@/components/form/fields/FieldAddress";
import FormHeading from "@/components/form/layout/FormHeading";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import useGetLocationFromIP from "@/hooks/useGetLocationFromIP";
import { getCurrentPark, saveParkPlace } from "@/utils/storage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ParkAddAddress() {
  const navigate = useNavigate();
  const { next, exit } = useParkAdd();
  const { location, getLocation } = useGetLocationFromIP();
  const { t } = useTranslation(["park", "validation"]);

  useEffect(() => {
    const park = getCurrentPark();

    if (park) {
      navigate(`/parks/add/2?id=${park.objectId}`);
      return;
    } else {
      getLocation();
    }
  }, []);

  function onSelectPlace(place: Place) {
    saveParkPlace(place);
    next();
  }

  return (
    <ParkAddShell
      displayFooter={false}
      actions={{
        exit: {
          visible: true,
          onClick: () => exit(),
        },
      }}
    >
      <FormHeading title={t("park:whereIsTheSpot")} />
      <FieldAddress
        autoFocus={true}
        label={t("park:enterAnAddress")}
        onSelect={onSelectPlace}
        location={location}
      />
    </ParkAddShell>
  );
}
