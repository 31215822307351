import Loader from "@/components/layout/Loader";
import { cookieOptions } from "@/config/cookie.config";
import keys from "@/config/keys";
import { useLazyGetUser } from "@/hooks/query/useLazyGetUser";
import { setHttpAuthToken } from "@/services/api";
import { setBifrostEmail } from "@/utils/storage";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function RedirectBifrost() {
  const [searchParams] = useSearchParams();
  const [_, setCookie] = useCookies([keys.authToken]);
  const onBehalfToken = searchParams.get("onbehalf");
  const parkingId = searchParams.get("parkingId"); // Tied to legacy-central-client, we be replaced with 'redirect_to'
  const redirectTo = searchParams.get("redirect_to");

  /* For certain actions like adding a parking, we want to redirect to the page they were on before. */
  const redirectAfterAction = searchParams.get("redirect_after_action");

  const fetchUser = useLazyGetUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectAfterAction) {
      const parsedRedirectAfterAction = JSON.parse(redirectAfterAction);

      if (parsedRedirectAfterAction.action === "ADD-PARK") {
        localStorage.setItem(keys.redirect_to_bifrost_after_add_park, "true");
        localStorage.setItem(
          keys.redirect_after_action_url,
          parsedRedirectAfterAction.url
        );
      }

      if (parsedRedirectAfterAction.action === "EDIT-PARK") {
        localStorage.setItem(keys.redirect_to_bifrost_after_edit_park, "true");
        localStorage.setItem(
          keys.redirect_after_action_url,
          parsedRedirectAfterAction.url
        );
      }
    }
  }, [redirectAfterAction]);

  useEffect(() => {
    (async () => {
      if (onBehalfToken) {
        setCookie(keys.authToken, onBehalfToken, cookieOptions);
        setHttpAuthToken(onBehalfToken);

        try {
          const user = await fetchUser();
          if (user.email) {
            setBifrostEmail(user.email);
            setCookie(keys.bifrostEmail, user.email, cookieOptions);
            if (parkingId) {
              navigate(`/parks/${parkingId}`, { replace: true });
            } else if (redirectTo) {
              navigate(redirectTo, { replace: true });
            } else {
              navigate("/", { replace: true });
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        navigate("/", { replace: true });
      }
    })();
  }, [onBehalfToken]);

  return <Loader />;
}
