import { FCP_Reservation } from "@/@types/reservation";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

interface GetParkReservationsRequest {
  parkId: string;
  options?: {
    endsBefore?: string;
    endsAfter?: string;
    startsBefore?: string;
    startsAfter?: string;
    statuses?: string;
    limit?: number;
    page?: number;
    event?: { venueId: string; eventId: string };
  };
}

type GetParkReservationsResponse = {
  count: number;
  items: FCP_Reservation[];
};

export const useLazyGetParkReservations = () => {
  const queryClient = useQueryClient();
  return async ({ parkId, options }: GetParkReservationsRequest) => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyParkReservations" + parkId],
      queryFn: async () => {
        const offset = new Date().getTimezoneOffset();

        if (options?.event) {
          const { data } = await api.get<GetParkReservationsResponse>(
            `/v2/users/me/connect/venues/${options.event.venueId}/events/${options.event.eventId}/parkings/${parkId}/reservations`,
            {
              params: {
                ...options,
                offset,
                statuses: options.statuses ?? "valid,canceled",
              },
            }
          );

          return data;
        }


        const { data } = await api.get<GetParkReservationsResponse>(
          `/v2/users/me/connect/parkings/${parkId}/reservations`,
          {
            params: {
              ...options,
              offset,
              statuses: options?.statuses ?? "valid,canceled",
            },
          }
        );

        return data;
      },
    });
  };
};
