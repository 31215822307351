import Loader from "@/components/layout/Loader";
import Spacer from "@/components/Spacer";
import TopBanner from "@/components/TopBanner";
import { Button } from "@/components/ui/button";
import HelpPanel from "@/containers/HelpPanel";
import OnboardingMenu, {
  OnboardingMenuItem,
} from "@/containers/OnboardingMenu";
import useEntryAnimation from "@/hooks/animation/useEntryAnimation";
import useOnLogout from "@/hooks/use-on-logout.hook";
import useOnboarding from "@/hooks/useOnboarding";
import { useUpdateOnboardingStepMutation } from "@/services/owner";
import { useUpdateUserMutation } from "@/services/user";
import {
  getAltLanguageLabel,
  getLanguage,
  switchLanguage,
} from "@/utils/language";
import { getBifrostEmail, setBifrostSkipOnboarding } from "@/utils/storage";
import * as tracker from "@/utils/tracker";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function KycEnd() {
  const navigate = useNavigate();
  const logout = useOnLogout();

  const { t } = useTranslation(["onboarding", "park", "common"]);
  const { loading, kycRequired } = useOnboarding();
  const { mutateAsync: updateUserAsync } = useUpdateUserMutation();
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const { launchEntryAnimation, animatingEntry, entryAnimationClassNames } =
    useEntryAnimation({
      entryAnimationDelay: 250,
    });

  useEffect(() => {
    launchEntryAnimation();
  }, []);

  const { mutateAsync: updateOnboadingStep } =
    useUpdateOnboardingStepMutation();

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (kycRequired) {
          navigate("/onboarding", { replace: true });
        } else {
          updateOnboadingStep("kyc-completed");
          // Flag the user as having completed onboarding
          await updateUserAsync({ uxMetadata: { onboardingComplete: true } });
          tracker.track.onboarding.completedKyc();
        }
      }
    })();
  }, [loading, kycRequired]);

  const headerClassName = "h-[85px] md:h-[92px] px-5";
  const footerClassName = "h-[85px] md:h-[94px] px-5";
  const mainClassName = getBifrostEmail()
    ? "h-[calc(100dvh-85px-85px-44px)] md:h-[calc(100dvh-92px-94px-44px)]"
    : "h-[calc(100dvh-85px-85px)] md:h-[calc(100dvh-92px-94px)]";

  const menuItems: OnboardingMenuItem[] = [
    {
      name: t("common:switchToLanguage", {
        language: getAltLanguageLabel(false),
      }),
      onClick: () => {
        switchLanguage();
        setSubmenuOpen(false);
      },
    },
    {
      name: t("common:logout"),
      onClick: logout,
    },
  ];

  if (getBifrostEmail()) {
    menuItems.push({
      name: t("common:bifrostLeave"),
      onClick: () => {
        setBifrostSkipOnboarding();
        navigate("/");
      },
    });
  }

  if (loading) {
    return <Loader backgroundStyle="opaque" />;
  }

  return (
    <>
      {getBifrostEmail() && (
        <TopBanner
          title={t("common:loggedInAs", {
            email: getBifrostEmail(),
          })}
        />
      )}

      <header
        className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
      >
        <img
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />

        <div className="flex items-center justify-between">
          <Button
            className="mr-3"
            variant="outline"
            onClick={() => setHelpPanelOpen(true)}
          >
            <div className="flex items-center">{t("common:help")}</div>
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <div className="flex items-center">
                {t("common:menu")}
                <IconChevronDown
                  className={`ml-1 h-[20px] w-[20px] ${
                    submenuOpen ? "rotate-180" : ""
                  } transition-all`}
                />
              </div>
            </Button>

            {submenuOpen && (
              <div className="absolute right-0 top-[50px] z-30">
                <OnboardingMenu items={menuItems} />
              </div>
            )}
          </div>
        </div>
      </header>

      <main
        className={`${mainClassName} relative mx-auto overflow-hidden bg-white opacity-0 md:max-w-[500px] md:px-0 ${
          animatingEntry && entryAnimationClassNames
        }`}
      >
        <div className="h-full overflow-auto">
          <div className="mx-auto h-full md:max-w-[500px]">
            <div className="h-full px-6 py-8 md:px-6 md:py-12">
              <div className="animate-fade animate-delay-200 animate-alternate animate-duration-[1000ms] animate-once animate-ease-in-out">
                <h1 className="bg-gradient-to-br from-rapide-600 to-sky-200 bg-clip-text text-3xl font-bold text-transparent">
                  {t("onboarding:kycCompleted")}
                </h1>

                <p className="mt-3 max-w-3xl text-xl font-semibold">
                  {t("onboarding:kycCompletedText")}
                </p>

                <ul role="list">
                  <li className="my-5 flex items-start overflow-hidden rounded-lg bg-white p-6 shadow ring-1 ring-black ring-opacity-5">
                    <img
                      src="/images/onboarding/icon-park.svg"
                      className="mr-5"
                    />
                    <div>
                      <h2 className="text-md mb-1 font-bold">
                        {t("onboarding:addParkingTitle")}
                      </h2>
                      <p className="text-sm font-semibold text-green-600">
                        {t("onboarding:completedStep")}
                      </p>
                    </div>
                  </li>
                  <li className="my-5 flex items-start overflow-hidden rounded-lg bg-white p-6 shadow ring-1 ring-black ring-opacity-5">
                    <img
                      src="/images/onboarding/icon-verification.svg"
                      className="mr-5"
                    />

                    <div>
                      <h2 className="text-md mb-1 font-bold">
                        {t("onboarding:completeKycTitle")}
                      </h2>
                      <p className="text-sm font-semibold text-green-600">
                        {t("onboarding:completedStep")}
                      </p>
                    </div>
                  </li>
                  <li className="my-5 flex items-start overflow-hidden rounded-lg bg-white p-6 shadow ring-1 ring-black ring-opacity-5">
                    <img
                      src="/images/onboarding/icon-link-bank-account.svg"
                      className="mr-5"
                    />
                    <div>
                      <h2 className="text-md mb-1 font-bold">
                        {t("onboarding:configurePaymentsTitle")}
                      </h2>
                      <p className="text-sm">
                        {t("onboarding:configurePaymentsDescription")}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <Spacer />
            </div>
          </div>
        </div>
      </main>

      <footer
        className={`${footerClassName} relative z-20 mx-auto flex w-full items-center justify-center border-t border-silver-800 bg-white pb-[5px] sm:static md:px-0`}
      >
        <Button
          size="lg"
          onClick={() => navigate("/onboarding/link-bank-account")}
        >
          {t("common:continue")}
        </Button>
      </footer>

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />
    </>
  );
}
