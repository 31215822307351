import FieldNumber from "@/components/form/fields/FieldNumber";
import FieldTextarea from "@/components/form/fields/FieldTextarea";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import FormHint from "@/components/form/layout/FormHint";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import { CPException } from "@/models/exceptions/CPException";
import { useGoogleTranslateMutation } from "@/services/gtranslate";
import { getAltLanguage, getLanguage } from "@/utils/language";
import { getCurrentPark } from "@/utils/storage";
import { useFormik } from "formik";
import { decode } from "html-entities";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ParkAddDirections() {
  const { t } = useTranslation(["park", "validation"]);

  const [formError, setFormError] = useState<string | undefined>();
  const [formAction, setFormAction] = useState<"next" | "exit">("next");
  const { next, back, exit, updatePark } = useParkAdd();
  const park = getCurrentPark();

  const parkDescription = park?.localizedDirections
    ? park.localizedDirections[getLanguage()]
    : undefined;

  const initialValues = {
    spaceQuantity: park?.spaceQuantity?.toString() || "1", // Needs to be a string for the FieldNumber component
    description: parkDescription || "",
  };

  const { mutateAsync: translateAsync } = useGoogleTranslateMutation();

  const {
    values,
    submitForm,
    isValid,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues,
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.description)
        errors.description = t("validation:descriptionRequired");
      if (!values.spaceQuantity)
        errors.spaceQuantity = t("validation:spaceQuantityRequired");
      if (values.spaceQuantity && parseInt(values.spaceQuantity) < 1)
        errors.spaceQuantity = t("validation:spaceQuantityMin");
      return errors;
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setFormError(undefined);

      let translatedText = "";

      try {
        const { data } = await translateAsync({
          sourceLanguage: getLanguage(),
          targetLanguage: getAltLanguage(),
          text: values.description.trim(),
        });

        translatedText = decode(data.translations[0].translatedText);
      } catch (error) {
        console.error(error);
      }

      try {
        await updatePark({
          spaceQuantity: parseInt(values.spaceQuantity),
          localizedDirections: [
            {
              languageCode: "fr",
              localizedString:
                getLanguage() === "fr"
                  ? values.description.trim()
                  : translatedText.trim(),
            },
            {
              languageCode: "en",
              localizedString:
                getLanguage() === "en"
                  ? values.description.trim()
                  : translatedText.trim(),
            },
            {
              languageCode: "default",
              localizedString: values.description.trim(),
            },
          ],
        });

        formAction === "next" ? next() : exit();
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  function onBack() {
    back();
  }

  function onNext() {
    setFormAction("next");
    submitForm();
  }

  function onSaveAndExit() {
    setFormAction("exit");
    submitForm();
  }

  return (
    <ParkAddShell
      loading={isSubmitting}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: !isValid || isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: !isValid || isSubmitting,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      {formError && <FormError title={formError} />}

      <FormFields
        gapY="lg"
        fields={[
          <FormField key="howManySpaceAvailableToRent">
            <FormHeading title={t("park:howManySpaceAvailableToRent")} />
            <FieldNumber
              name="spaceQuantity"
              fieldValue={values.spaceQuantity.toString()}
              onNumberChange={(value) => setFieldValue("spaceQuantity", value)}
              error={touched && errors.spaceQuantity}
            />
          </FormField>,
          <FormField key="howToGetThere">
            <FormHeading
              title={t("park:howToGetThere")}
              subtitle={t("park:howToGetThereSubtitle")}
              marginBottom="sm"
            />
            <FieldTextarea
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={(touched.description && errors.description) || undefined}
              style={{ minHeight: 150 }}
            />
            <FormHint text={t("park:howToGetThereHint")} />
          </FormField>,
        ]}
      />
    </ParkAddShell>
  );
}
