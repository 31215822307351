import FieldLabel from "@/components/form/fields/FieldLabel";
import { ForwardedRef, forwardRef } from "react";

interface Props {
  label: string;
  value?: string;
  options?: { value: string; label: string }[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const FieldTextLeadingSelect = forwardRef(
  (
    { label, value, options, onChange }: Props,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    return (
      <div className="absolute inset-y-0 left-0 flex items-center">
        <FieldLabel label={label} htmlFor="country" className="sr-only" />

        <select
          ref={ref}
          value={value}
          autoComplete="country"
          onChange={onChange}
          className="h-full rounded-md border-0 bg-transparent py-0 pl-3 text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rapide-600 sm:text-sm"
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

FieldTextLeadingSelect.displayName = "FieldTextLeadingSelect";

export default FieldTextLeadingSelect;
