import { OwnerNotifications } from "@/@types/owner";
import { DataList } from "@/components/DataList";
import FieldCheckboxesInline from "@/components/form/fields/FieldCheckboxesInline";
import FieldSwitch from "@/components/form/fields/FieldSwitch";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import Loader from "@/components/layout/Loader";
import { Button } from "@/components/ui/button";
import {
  useGetOwnerNotifications,
  useUpdateOwnerNotifications,
} from "@/services/owner";

import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormNotificationsPreferences() {
  const { t } = useTranslation(["account", "common"]);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const { mutateAsync: updateSettingsAsync, error: updateError } =
    useUpdateOwnerNotifications();

  const {
    isLoading,
    data: ownerNotifications,
    error,
  } = useGetOwnerNotifications();

  const SectionTitle = ({ title }: { title: string }) => {
    return (
      <div className="flex items-center">
        <strong className="mr-2 text-sm font-bold">{title}</strong>
      </div>
    );
  };

  const initialValues: OwnerNotifications = ownerNotifications ?? {
    sms: {
      newReservation: false,
      reservationCanceled: false,
      reservationUpdated: false,
    },
    email: {
      newReservation: false,
      reservationCanceled: false,
      reservationUpdated: false,
    },
  };

  const { values, submitForm, isSubmitting, setFieldValue, isValid } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values) => {
        await updateSettingsAsync(values);
        setFormSuccess(true);

        setTimeout(() => {
          setFormSuccess(false);
        }, 5000);
      },
    });

  const newReservation = [
    ...(values?.sms?.newReservation ? ["sms.newReservation"] : []),
    ...(values?.email?.newReservation ? ["email.newReservation"] : []),
  ];

  const newReservationChecked = newReservation.some((x) => !!x);

  const reservationUpdated = [
    ...(values?.sms?.reservationUpdated ? ["sms.reservationUpdated"] : []),
    ...(values?.email?.reservationUpdated ? ["email.reservationUpdated"] : []),
  ];

  const reservationUpdatedChecked = reservationUpdated.some((x) => !!x);

  const reservationCanceled = [
    ...(values?.sms?.reservationCanceled ? ["sms.reservationCanceled"] : []),
    ...(values?.email?.reservationCanceled
      ? ["email.reservationCanceled"]
      : []),
  ];

  const reservationCanceledChecked = reservationCanceled.some((x) => !!x);

  return (
    <div className="relative">
      {error || updateError ? (
        <div className="mb-5">
          <FormError title={t("common:generalError")} />
        </div>
      ) : null}

      <FormFields
        fields={[
          <FormField key="notifications">
            <DataList
              title={t("account:notificationsTitle")}
              subtitle={t("account:notificationsDescription")}
              datalist={[
                {
                  title: (
                    <SectionTitle
                      title={t("account:newReservationNotificationTitle")}
                    />
                  ),
                  description: t(
                    "account:newReservationNotificationDescription"
                  ),
                  action: (
                    <FieldSwitch
                      checked={newReservationChecked}
                      onChange={() => {
                        setFieldValue(
                          "sms.newReservation",
                          !newReservationChecked
                        );
                        setFieldValue(
                          "email.newReservation",
                          !newReservationChecked
                        );
                      }}
                    />
                  ),
                  data: newReservationChecked ? (
                    <FieldCheckboxesInline
                      options={[
                        {
                          name: t("common:sms"),
                          value: "sms.newReservation",
                        },
                        {
                          name: t("common:email"),
                          value: "email.newReservation",
                        },
                      ]}
                      selectedValues={newReservation}
                      onCheck={(option) => {
                        setFieldValue(option.value, true);
                      }}
                      onUncheck={(option) => {
                        setFieldValue(option.value, false);
                      }}
                    />
                  ) : null,
                },
                {
                  title: (
                    <SectionTitle
                      title={t("account:updatedReservationNotificationTitle")}
                    />
                  ),
                  description: t(
                    "account:updatedReservationNotificationDescription"
                  ),
                  action: (
                    <FieldSwitch
                      checked={reservationUpdatedChecked}
                      onChange={() => {
                        setFieldValue(
                          "sms.reservationUpdated",
                          !reservationUpdatedChecked
                        );
                        setFieldValue(
                          "email.reservationUpdated",
                          !reservationUpdatedChecked
                        );
                      }}
                    />
                  ),
                  data: reservationUpdatedChecked ? (
                    <FieldCheckboxesInline
                      options={[
                        {
                          name: t("common:sms"),
                          value: "sms.reservationUpdated",
                        },
                        {
                          name: t("common:email"),
                          value: "email.reservationUpdated",
                        },
                      ]}
                      selectedValues={reservationUpdated}
                      onCheck={(option) => {
                        setFieldValue(option.value, true);
                      }}
                      onUncheck={(option) => {
                        setFieldValue(option.value, false);
                      }}
                    />
                  ) : null,
                },
                {
                  title: (
                    <SectionTitle
                      title={t("account:cancelledReservationNotificationTitle")}
                    />
                  ),
                  description: t(
                    "account:cancelledReservationNotificationDescription"
                  ),
                  action: (
                    <FieldSwitch
                      checked={reservationCanceledChecked}
                      onChange={() => {
                        setFieldValue(
                          "sms.reservationCanceled",
                          !reservationCanceledChecked
                        );
                        setFieldValue(
                          "email.reservationCanceled",
                          !reservationCanceledChecked
                        );
                      }}
                    />
                  ),
                  data: reservationCanceledChecked ? (
                    <FieldCheckboxesInline
                      options={[
                        {
                          name: t("common:sms"),
                          value: "sms.reservationCanceled",
                        },
                        {
                          name: t("common:email"),
                          value: "email.reservationCanceled",
                        },
                      ]}
                      selectedValues={reservationCanceled}
                      onCheck={(option) => {
                        setFieldValue(option.value, true);
                      }}
                      onUncheck={(option) => {
                        setFieldValue(option.value, false);
                      }}
                    />
                  ) : null,
                },
              ]}
            >
              <div className="flex items-center">
                <Button
                  onClick={submitForm}
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                >
                  {t("common:save")}
                </Button>

                <FormSuccess visible={formSuccess} />
              </div>
            </DataList>
          </FormField>,
        ]}
      />

      {isLoading ? <Loader /> : null}
    </div>
  );
}
