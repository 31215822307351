import { DetailedHTMLProps, FC, FormHTMLAttributes, Fragment } from "react";

interface Props
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  children: React.ReactNode;
}

const FormStacked: FC<Props> = ({ children, ...props }) => {
  return <form {...props}>{children}</form>;
};

interface FormStackedSectionProps {
  children: React.ReactNode;
}

function FormStackedSection({ children }: FormStackedSectionProps) {
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">{children}</div>
    </div>
  );
}

interface FormStackedHeaderProps {
  title: string;
  description?: string;
}

function FormStackedHeader({ title, description }: FormStackedHeaderProps) {
  return (
    <>
      <h2 className="text-base font-semibold leading-7 text-simple-900">
        {title}
      </h2>
      {description ? (
        <p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>
      ) : null}
    </>
  );
}

interface FormStackedFooterProps {
  children: React.ReactNode;
}

function FormStackedFooter({ children }: FormStackedFooterProps) {
  return (
    <div className="mt-6 flex items-center justify-end gap-x-6">{children}</div>
  );
}

interface FormStackedFieldsProps {
  fields: React.ReactNode[];
  gapY?: "sm" | "md" | "lg";
  gapX?: "sm" | "md" | "lg";
}

function FormStackedFields({
  fields,
  gapY = "sm",
  gapX = "sm",
}: FormStackedFieldsProps) {
  fields = fields.filter((field) => field !== null);

  let gapXClassName = "";
  let gapYClassName = "";

  switch (gapX) {
    case "sm":
      gapXClassName = "gap-x-6";
      break;
    case "md":
      gapXClassName = "gap-x-8";
      break;
    case "lg":
      gapXClassName = "gap-x-12";
      break;
  }

  switch (gapY) {
    case "sm":
      gapYClassName = "gap-y-3";
      break;
    case "md":
      gapYClassName = "gap-y-6";
      break;
    case "lg":
      gapYClassName = "gap-y-8";
      break;
  }

  return (
    <div
      className={`mb-5 grid grid-cols-1 sm:grid-cols-6 ${gapXClassName} ${gapYClassName}`}
    >
      {fields.map((field, index) => (
        <Fragment key={index}>{field}</Fragment>
      ))}
    </div>
  );
}

interface FormStackedFieldProps {
  size?: "full" | "2-6" | "3-6" | "4-6";
  children: React.ReactNode;
}

function FormStackedField({ size = "full", children }: FormStackedFieldProps) {
  let className = "col-span-full";

  switch (size) {
    case "2-6":
      className = "sm:col-span-2";
      break;
    case "3-6":
      className = "sm:col-span-3";
      break;
    case "4-6":
      className = "sm:col-span-4";
      break;
  }

  return <div className={className}>{children}</div>;
}

export {
  FormStacked as Form,
  FormStackedField as FormField,
  FormStackedFields as FormFields,
  FormStackedFooter as FormFooter,
  FormStackedHeader as FormHeader,
  FormStackedSection as FormSection,
};

