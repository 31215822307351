import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import FormAccount from "@/containers/forms/FormAccount";
import FormNewPassword from "@/containers/forms/FormNewPassword";
import { useTranslation } from "react-i18next";

export default function Account() {
  const { t } = useTranslation(["account", "common"]);

  return (
    <Shell>
      <PageHeader
        title={t("common:account")}
        tabs={[
          {
            name: t("account:personalInformationFormTitle"),
            href: `/account`,
            current: true,
          },
          {
            name: t("account:vehiclesWhitelist"),
            href: `/account/vehicles-whitelist`,
            current: false,
          },
          {
            name: t("account:preferences"),
            href: `/account/preferences`,
            current: false,
          },
        ]}
      />

      <div className="divide-y divide-silver-800">
        <PageSection>
          <FormAccount />
        </PageSection>
        <PageSection>
          <FormNewPassword />
        </PageSection>
      </div>
    </Shell>
  );
}
