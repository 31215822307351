interface Props {
  title: string;
}

export default function TopBanner({ title }: Props) {
  return (
    <div className="bg-rapide-600 py-3 text-center text-sm font-semibold text-white">
      {title}
    </div>
  );
}
