import { OwnerNotificationsRecipient } from "@/@types/owner";
import FieldSelect from "@/components/form/fields/FieldSelect";
import FieldSwitch from "@/components/form/fields/FieldSwitch";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import Loader from "@/components/layout/Loader";
import { Button } from "@/components/ui/button";
import {
  useGetOwnerNotificationsRecipients,
  useUpdateOwnerNotificationsRecipients,
} from "@/services/owner";

import { FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdEmail, MdPhoneAndroid } from "react-icons/md";

export default function FormNotificationsRecipients() {
  const { t } = useTranslation(["account", "common"]);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [customError, setCustomError] = useState<string | undefined>();
  const { mutateAsync: updateRecipients, error: updateError } =
    useUpdateOwnerNotificationsRecipients();

  /* Should be handed directly by the SDK... will get there eventually */
  useEffect(() => {
    if (updateError) {
      // @ts-ignore - this is a hack to get the error code from the API
      switch (updateError.code) {
        case 2100:
          setCustomError(t("account:recipientCannotBeYourself"));
          break;
        case 2101:
          setCustomError(t("account:recipientPhoneInvalid"));
          break;
        case 2102:
          setCustomError(t("account:recipientEmailInvalid"));
          break;
      }
    }
  }, [updateError]);

  const {
    isLoading,
    data: recipients,
    error,
  } = useGetOwnerNotificationsRecipients();

  const initialValues: {
    recipients: OwnerNotificationsRecipient[];
  } = {
    recipients: recipients ?? [],
  };

  const recipientTemplate: OwnerNotificationsRecipient = {
    type: "email",
    recipient: "",
    notifications: {
      newReservation: false,
      reservationCanceled: false,
      reservationUpdated: false,
      showRevenues: false,
    },
  };

  const verifyRecipientValidity = (
    recipients: OwnerNotificationsRecipient[]
  ) => {
    let isMissing = false;

    recipients.forEach((recipient) => {
      if (recipient.recipient === "") {
        isMissing = true;
      }
    });

    return !isMissing;
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("account:notificationsRecipients")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("account:notificationsRecipientsDescription")}
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="sm:max-w-xl">
          {error || updateError || customError ? (
            <div className="mb-5">
              <FormError title={customError || t("common:generalError")} />
            </div>
          ) : null}

          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              setCustomError(undefined);
              if (!verifyRecipientValidity(values.recipients)) {
                setCustomError(t("account:recipientInfoRequired"));
                return;
              }

              const recipients: OwnerNotificationsRecipient[] = [];

              values.recipients.forEach((recipient) => {
                if (recipient.type === "email") {
                  recipients.push({
                    type: recipient.type,
                    recipient: recipient.recipient,
                    notifications: {
                      newReservation: recipient.notifications.newReservation,
                      reservationCanceled:
                        recipient.notifications.reservationCanceled,
                      reservationUpdated:
                        recipient.notifications.reservationUpdated,
                      showRevenues: recipient.notifications.showRevenues,
                    },
                  });
                } else {
                  recipients.push({
                    type: recipient.type,
                    recipient: recipient.recipient,
                    notifications: {
                      newReservation: recipient.notifications.newReservation,
                      reservationCanceled:
                        recipient.notifications.reservationCanceled,
                      reservationUpdated:
                        recipient.notifications.reservationUpdated,
                    },
                  });
                }
              });

              await updateRecipients(recipients);
              setFormSuccess(true);
              setTimeout(() => {
                setFormSuccess(false);
              }, 5000);
            }}
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
          >
            {({
              values,
              handleChange,
              handleBlur,
              submitForm,
              isSubmitting,
              errors,
            }) => {
              return (
                <FieldArray
                  name="recipients"
                  validateOnChange={false}
                  render={(arrayHelpers) => (
                    <div>
                      {values.recipients && values.recipients.length > 0 ? (
                        values.recipients.map((recipient, index) => (
                          <div
                            key={index}
                            className="mb-5 overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow ring-1 ring-black ring-opacity-5 sm:p-6"
                          >
                            <FormFields
                              fields={[
                                <FormField
                                  size="2-6"
                                  key={`recipient-type-${index}`}
                                >
                                  <FieldSelect
                                    label={t("account:recipientType")}
                                    options={[
                                      {
                                        name: "Email",
                                        value: "email",
                                      },
                                      {
                                        name: "SMS",
                                        value: "sms",
                                      },
                                    ]}
                                    onSelect={(option) => {
                                      arrayHelpers.replace(index, {
                                        ...recipient,
                                        type: option.value,
                                      });
                                    }}
                                    selectedOptionValue={recipient.type}
                                  />
                                </FormField>,
                                <FormField
                                  size="4-6"
                                  key={`recipient-value-${index}`}
                                >
                                  {recipient.type === "email" ? (
                                    <FieldText
                                      label={t("account:recipientEmail")}
                                      name={`recipients.${index}`}
                                      placeholder={t(
                                        "account:enterRecipientEmail"
                                      )}
                                      type="email"
                                      value={recipient.recipient}
                                      LeadingIcon={
                                        <MdEmail className="text-rapide-600" />
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        arrayHelpers.replace(index, {
                                          ...recipient,
                                          recipient: e.target.value,
                                        });
                                      }}
                                      error={errors?.recipients?.[
                                        index
                                      ]?.toString()}
                                    />
                                  ) : (
                                    <FieldText
                                      id="phone"
                                      label={t("account:recipientPhone")}
                                      name={`recipients.${index}`}
                                      placeholder={t(
                                        "account:enterRecipientPhone"
                                      )}
                                      LeadingIcon={
                                        <MdPhoneAndroid className="text-rapide-600" />
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        arrayHelpers.replace(index, {
                                          ...recipient,
                                          recipient: e.target.value,
                                        });
                                      }}
                                      onBlur={handleBlur}
                                      value={recipient.recipient}
                                      autoComplete="tel"
                                      error={errors?.recipients?.[
                                        index
                                      ]?.toString()}
                                    />
                                  )}
                                </FormField>,
                              ]}
                            />

                            <ul className="divide-y divide-silver-800">
                              <li className="flex items-center justify-between pb-3 pt-2">
                                <div>
                                  <strong className="mr-2 text-sm font-bold">
                                    {t(
                                      "account:newReservationNotificationTitle"
                                    )}
                                  </strong>
                                  <div className="text-md text-xs">
                                    {t(
                                      "account:newReservationNotificationDescription"
                                    )}
                                  </div>
                                </div>

                                <FieldSwitch
                                  checked={
                                    recipient.notifications.newReservation
                                  }
                                  onChange={() => {
                                    arrayHelpers.replace(index, {
                                      ...recipient,
                                      notifications: {
                                        ...recipient.notifications,
                                        newReservation:
                                          !recipient.notifications
                                            .newReservation,
                                      },
                                    });
                                  }}
                                />
                              </li>
                              <li className="flex items-center justify-between pb-3 pt-2">
                                <div>
                                  <strong className="mr-2 text-sm font-bold">
                                    {t(
                                      "account:updatedReservationNotificationTitle"
                                    )}
                                  </strong>
                                  <div className="text-md text-xs">
                                    {t(
                                      "account:updatedReservationNotificationDescription"
                                    )}
                                  </div>
                                </div>
                                <FieldSwitch
                                  checked={
                                    recipient.notifications.reservationUpdated
                                  }
                                  onChange={() => {
                                    arrayHelpers.replace(index, {
                                      ...recipient,
                                      notifications: {
                                        ...recipient.notifications,
                                        reservationUpdated:
                                          !recipient.notifications
                                            .reservationUpdated,
                                      },
                                    });
                                  }}
                                />
                              </li>
                              <li className="flex items-center justify-between pb-3 pt-2">
                                <div>
                                  <strong className="mr-2 text-sm font-bold">
                                    {t(
                                      "account:cancelledReservationNotificationTitle"
                                    )}
                                  </strong>
                                  <div className="text-md text-xs">
                                    {t(
                                      "account:cancelledReservationNotificationDescription"
                                    )}
                                  </div>
                                </div>
                                <FieldSwitch
                                  checked={
                                    recipient.notifications.reservationCanceled
                                  }
                                  onChange={() => {
                                    arrayHelpers.replace(index, {
                                      ...recipient,
                                      notifications: {
                                        ...recipient.notifications,
                                        reservationCanceled:
                                          !recipient.notifications
                                            .reservationCanceled,
                                      },
                                    });
                                  }}
                                />
                              </li>
                              {recipient.type === "email" ? (
                                <li className="flex items-center justify-between pb-3 pt-2">
                                  <div>
                                    <strong className="mr-2 text-sm font-bold">
                                      {t(
                                        "account:showRevenuesNotificationTitle"
                                      )}
                                    </strong>
                                    <div className="text-md text-xs">
                                      {t(
                                        "account:showRevenuesNotificationDescription"
                                      )}
                                    </div>
                                  </div>
                                  <FieldSwitch
                                    checked={
                                      recipient.notifications.showRevenues
                                    }
                                    onChange={() => {
                                      arrayHelpers.replace(index, {
                                        ...recipient,
                                        notifications: {
                                          ...recipient.notifications,
                                          showRevenues:
                                            !recipient.notifications
                                              .showRevenues,
                                        },
                                      });
                                    }}
                                  />
                                </li>
                              ) : null}
                            </ul>

                            <Button
                              variant="outline"
                              color="danger"
                              onClick={() => arrayHelpers.remove(index)}
                              className="mt-4"
                            >
                              {t("common:remove")}
                            </Button>
                          </div>
                        ))
                      ) : (
                        <p className="mb-5">{t("account:noRecipients")}</p>
                      )}

                      <div className="flex items-center">
                        <Button
                          variant="outline"
                          onClick={() => arrayHelpers.push(recipientTemplate)}
                        >
                          {t("common:add")}
                        </Button>

                        <Button
                          onClick={submitForm}
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          className="ml-3"
                        >
                          {t("common:save")}
                        </Button>

                        <FormSuccess visible={formSuccess} />
                      </div>
                    </div>
                  )}
                />
              );
            }}
          </Formik>

          {isLoading ? <Loader /> : null}
        </div>
      </div>
    </div>
  );
}
