export const keys = {
  AUTH_TOKEN: "cnp_auth_token",
  INSTALLATION_ID: "cnp_installation_id",
  LANGUAGE: "cnp_language",
  AUTH_EMAIL: "cnp_auth_email",
  AUTH_REDIRECT: "cnp_auth_redirect",
  AUTH_REFERRER: "cnp_auth_referrer",
  AUTH_NEEDS_COMPLETION: "cnp_auth_needs_completion",
  CAPTURED_UTM_CONTENT: "cnp_captured_utm_content",
  CAPTURED_UTM_SOURCE: "cnp_captured_utm_source",
  CAPTURED_UTM_MEDIUM: "cnp_captured_utm_medium",
  CAPTURED_UTM_CAMPAIGN: "cnp_captured_utm_campaign",
  CAPTURED_UTM_TERM: "cnp_captured_utm_term",
} as const;

export type StorageKey = (typeof keys)[keyof typeof keys];

export class StorageUtils {
  /* Local storage */

  public static getItem(key: StorageKey): string | null {
    return localStorage.getItem(key);
  }

  public static setItem(key: StorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  public static removeItem(key: StorageKey): void {
    localStorage.removeItem(key);
  }

  public static clear(): void {
    localStorage.clear();
  }

  /* Session storage */

  public static getSessionItem(key: StorageKey): string | null {
    return sessionStorage.getItem(key);
  }

  public static setSessionItem(key: StorageKey, value: string): void {
    sessionStorage.setItem(key, value);
  }

  public static removeSessionItem(key: StorageKey): void {
    sessionStorage.removeItem(key);
  }

  public static clearSession(): void {
    sessionStorage.clear();
  }
}
