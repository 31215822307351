import { PlateCheckerRequestContext } from "@/containers/plate-checker/contexts/plate-checker-request.context";
import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";
import {
  getVehicleName,
  hasAtLeastOneReservationWithinRange,
  isVehicleWhitelistedOnCurrentPark,
  isWithinRange,
  userHasAssociatedWrongPlateToReservation,
} from "@/containers/plate-checker/utils/plate-checker.utils";
import { DateUtils } from "@/utils/date.utils";
import { CPPlateCheckerResult } from "@clicknpark/sdk";
import dayjs from "dayjs";
import { ChevronDown, CircleCheck, CircleX, TriangleAlert } from "lucide-react";
import { useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

interface IconContainer {
  status: PlateCheckerTypes.RequestResultValidity;
  children: React.ReactNode;
}

function IconContainer({ status, children }: IconContainer) {
  switch (status) {
    case PlateCheckerTypes.RequestResultValidity.PlateDisallowed:
      return <div className="rounded-md bg-yellow-100 p-2">{children}</div>;
    case PlateCheckerTypes.RequestResultValidity.PlateAllowed:
      return <div className="rounded-md bg-green-100 p-2">{children}</div>;
    case PlateCheckerTypes.RequestResultValidity.PlateDiscretionary:
      return <div className="rounded-md bg-yellow-100 p-2">{children}</div>;
    default:
      return null;
  }
}

interface IconProps {
  status: PlateCheckerTypes.RequestResultValidity;
  size: number;
}

function Icon({ status, size }: IconProps) {
  switch (status) {
    case PlateCheckerTypes.RequestResultValidity.PlateDisallowed:
      return (
        <div>
          <CircleX size={size} className="text-red-800" />
        </div>
      );
    case PlateCheckerTypes.RequestResultValidity.PlateAllowed:
      return (
        <div>
          <CircleCheck size={size} className="text-green-600" />
        </div>
      );
    case PlateCheckerTypes.RequestResultValidity.PlateDiscretionary:
      return (
        <div>
          <TriangleAlert size={size} className="text-yellow-600" />
        </div>
      );
    default:
      return null;
  }
}

interface Props {
  result: CPPlateCheckerResult;
}

export default function PlateCheckerRequestResult({ result }: Props) {
  const { t } = useTranslation(["plateChecker"]);
  const context = useContext(PlateCheckerRequestContext);
  const parkId = context?.parkId;

  const [reservationsListOpened, toggleReservationsList] = useReducer(
    (state) => !state,
    false
  );

  const hasAssociatedWrongPlateToReservation =
    userHasAssociatedWrongPlateToReservation(result);

  if (!parkId) return null;

  const reservations = [
    ...result.upcomingReservations,
    ...result.currentReservations,
    ...result.pastReservations,
  ];

  return (
    <li
      key={result.vehicle.id}
      className="my-3 cursor-auto rounded-md bg-white shadow-md ring-2 ring-transparent transition-all hover:shadow-lg hover:ring-rapide-600 md:my-0"
    >
      <div className="mb-2 flex justify-between gap-4 p-5 pb-0 pt-4">
        <div className="text-lg font-bold text-black">
          {getVehicleName(result.vehicle)}
        </div>

        {hasAssociatedWrongPlateToReservation.hasWrongPlate ? (
          <div className="flex gap-2">
            <div className="flex items-center justify-center rounded-sm border border-rapide-600 p-1 pb-0.5 text-sm font-bold uppercase leading-none text-rapide-600">
              {hasAssociatedWrongPlateToReservation.plate}
            </div>
            <div className="relative flex items-center justify-center rounded-sm border border-slate-400 p-1 pb-0.5 text-sm font-bold uppercase leading-none text-slate-400">
              <div className="absolute left-0 right-0 top-1/2 h-[2px] bg-slate-400" />
              {result.vehicle.plate}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center rounded-sm border border-rapide-600 p-1 pb-0.5 text-sm font-bold uppercase leading-none text-rapide-600">
            {result.vehicle.plate}
          </div>
        )}
      </div>

      {isVehicleWhitelistedOnCurrentPark(parkId, result.whitelistedParks) ? (
        <div className="flex items-center gap-2 px-5 pb-4">
          <Icon
            status={PlateCheckerTypes.RequestResultValidity.PlateAllowed}
            size={16}
          />
          <p className="flex-shrink text-sm font-bold text-green-500">
            {t("plateChecker:requestResultValidityWhitelisted")}
          </p>
        </div>
      ) : (
        <ul className="mt-2">
          {reservations?.length === 0 ? (
            <div className="flex items-center gap-2 px-5 pb-4">
              <Icon
                status={PlateCheckerTypes.RequestResultValidity.PlateDisallowed}
                size={16}
              />
              <p className="flex-1 text-sm font-bold text-red-500">
                {t("plateChecker:requestResultValidityDisallowed")}
              </p>
            </div>
          ) : (
            <Fragment>
              <div className="px-5">
                {hasAtLeastOneReservationWithinRange(reservations) ? (
                  <div className="flex items-center gap-2 pb-4">
                    <Icon
                      status={
                        PlateCheckerTypes.RequestResultValidity.PlateAllowed
                      }
                      size={16}
                    />
                    <p className="flex-shrink text-sm font-bold text-green-500">
                      {hasAssociatedWrongPlateToReservation.hasWrongPlate
                        ? t(
                            "plateChecker:requestResultValidityAllowedButWrongPlate"
                          )
                        : t("plateChecker:requestResultValidityAllowed")}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-shrink items-center gap-2 pb-4">
                    <Icon
                      status={
                        PlateCheckerTypes.RequestResultValidity
                          .PlateDiscretionary
                      }
                      size={16}
                    />
                    <p className="text-sm font-bold text-yellow-600">
                      {hasAssociatedWrongPlateToReservation.hasWrongPlate
                        ? t(
                            "plateChecker:requestResultValidityDiscretionaryButWrongPlate"
                          )
                        : t("plateChecker:requestResultValidityDiscretionary")}
                    </p>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </ul>
      )}

      {reservations.length > 0 ? (
        <Fragment>
          <button
            onClick={toggleReservationsList}
            className="flex w-full items-center justify-center gap-2 border-t p-2.5 text-center text-sm"
          >
            {reservationsListOpened
              ? t("plateChecker:hideReservations")
              : t("plateChecker:showReservations")}

            {reservationsListOpened ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronDown size={16} />
            )}
          </button>

          {reservationsListOpened ? (
            <div className="space-y-2 p-5 pt-0">
              {reservations.map((reservation) => (
                <li
                  key={reservation.id}
                  className="flex items-center gap-4 rounded-md border p-4"
                >
                  <div className="flex-1 text-sm font-semibold text-slate-500">
                    <div className="mb-2 flex">
                      <div className="basis-[60px] text-black">
                        {t("plateChecker:start")}
                      </div>
                      <div className="flex-1">
                        {DateUtils.formatDate(dayjs(reservation.startDate))}
                      </div>
                    </div>

                    <div className="flex">
                      <div className="basis-[60px] text-black">
                        {t("plateChecker:end")}
                      </div>
                      <div className="flex-1">
                        {DateUtils.formatDate(dayjs(reservation.endDate))}
                      </div>
                    </div>
                  </div>

                  {!isWithinRange(
                    new Date(reservation.startDate),
                    new Date(reservation.endDate)
                  ) ? (
                    <IconContainer
                      status={
                        PlateCheckerTypes.RequestResultValidity
                          .PlateDiscretionary
                      }
                    >
                      <Icon
                        status={
                          PlateCheckerTypes.RequestResultValidity
                            .PlateDiscretionary
                        }
                        size={20}
                      />
                    </IconContainer>
                  ) : null}
                </li>
              ))}
            </div>
          ) : null}
        </Fragment>
      ) : null}
    </li>
  );
}
