import { GoogleMap } from "@react-google-maps/api";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

interface MapProps {
  height: string;
  location: google.maps.LatLngLiteral;
  onSelect: (location: google.maps.LatLngLiteral) => void;
}

const FieldMap = ({ location, height, onSelect }: MapProps) => {
  const { t } = useTranslation("common");
  const [mapref, setMapRef] = useState<google.maps.Map | null>(null);

  const handleOnLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      newCenter && onSelect({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  return (
    <>
      <GoogleMap
        tilt={0}
        zoom={20}
        center={location}
        onLoad={handleOnLoad}
        onCenterChanged={handleCenterChanged}
        options={{
          mapTypeId: "satellite",
          fullscreenControl: false,
          panControl: false,
          rotateControl: false,
          scaleControl: false,
          streetViewControl: false,
          gestureHandling: "greedy",
          mapTypeControlOptions: {
            mapTypeIds: ["satellite", "roadmap"],
            position: google.maps.ControlPosition.TOP_LEFT,
          },
          zoomControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT,
          },
        }}
        mapContainerClassName="google-map"
        mapContainerStyle={{
          height,
          width: "100%",
          borderRadius: 6,
        }}
      />

      <div className="relative top-[-30px] ml-3 mr-3 flex rounded-lg bg-white px-5 py-5 md:ml-10 md:mr-10">
        <img src="/images/icon-hand-move.svg" alt="move" />
        <p className="px-2 text-center text-xs font-bold text-rapide-600 md:text-sm">
          {t("mapHelp")}
        </p>
      </div>
    </>
  );
};

export default memo(FieldMap);
