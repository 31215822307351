export interface Props {
  isValid?: boolean;
  children: React.ReactNode;
}

export function FormRequirement({ children, isValid }: Props) {
  return (
    <div className="flex items-center py-[1px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill={isValid ? "green" : "silver"}
        className="mb-[2px] mr-1 h-4 w-4"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
          clipRule="evenodd"
        />
      </svg>

      <span className="block text-xs leading-5 text-simple-900">
        {children}
      </span>
    </div>
  );
}
