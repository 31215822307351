import FieldError from "@/components/form/fields/FieldError";
import FieldErrorIcon from "@/components/form/fields/FieldErrorIcon";
import FieldLabel from "@/components/form/fields/FieldLabel";
import { ForwardedRef, Fragment, InputHTMLAttributes, forwardRef } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  type?: "email" | "text" | "number" | "password" | "search" | "tel" | "url";
  LeadingIcon?: JSX.Element;
  TrailingIcon?: JSX.Element;
  isTrailingIconInteractive?: boolean;
  cornerHint?: string;
  LeadingSelect?: JSX.Element;
}

const FieldText = forwardRef(
  (
    {
      id,
      name,
      type = "text",
      label,
      placeholder,
      defaultValue,
      error,
      LeadingIcon,
      TrailingIcon,
      isTrailingIconInteractive,
      cornerHint,
      LeadingSelect,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    if (!id) id = name;

    const normalClasses =
      "text-simple-900 ring-gray-300 placeholder:text-gray-400 focus:ring-rapide-600";

    const errorClasses =
      "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500";

    const disabledClasses =
      "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-600 disabled:ring-gray-200";

    const sharedClasses = `px-3 block w-full rounded-md border-0 pt-2.5 pb-2 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
      LeadingSelect !== undefined ? "pl-16 " : " pl-none "
    } ${LeadingIcon !== undefined ? "pl-9 " : " pl-none "}`;



    const className = sharedClasses.concat(
      [error ? errorClasses : normalClasses, disabledClasses].join(" ")
    );

    return (
      <Fragment>
        {label ? (
          <div className="flex justify-between">
            <FieldLabel label={label} />

            {cornerHint ? (
              <span
                className="text-sm leading-6 text-gray-600"
                id="email-optional"
              >
                {cornerHint}
              </span>
            ) : null}
          </div>
        ) : null}

        <div className={`relative ${label ? "mt-2" : ""}`}>
          <div
            className={`relative rounded-md ${
              LeadingSelect ? "rounder-l-none" : ""
            } shadow-sm`}
          >
            {LeadingIcon ? (
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                {LeadingIcon}
              </div>
            ) : null}

            <input
              ref={ref}
              type={type}
              name={name}
              id={name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              className={className}
              {...props}
            />

            {TrailingIcon ? (
              <div
                className={`pointer-events-${
                  isTrailingIconInteractive ? "auto" : "none"
                } absolute inset-y-0 right-0 flex items-center pr-3`}
              >
                {TrailingIcon}
              </div>
            ) : null}

            {error ? <FieldErrorIcon /> : null}
          </div>

          {LeadingSelect}
        </div>

        {error ? <FieldError error={error} /> : null}
      </Fragment>
    );
  }
);

FieldText.displayName = "FieldText";

export default FieldText;
