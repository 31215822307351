import { FCP_Park } from "@/@types/park";
import Loader from "@/components/layout/Loader";
import Redirect from "@/components/Redirect";
import PlateChecker from "@/containers/plate-checker/plate-checker";
import { useGetParksQuery } from "@/services/park";
import { useGetUserQuery } from "@/services/user";

export default function PlateCheckerRoute() {
  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data;

  const getParksQuery = useGetParksQuery();
  const parks = getParksQuery.data;

  if (getUserQuery.isLoading || getParksQuery.isLoading) {
    return <Loader />;
  }

  // @ts-ignore
  if (!user.plateCheckerEnabled) {
    return <Redirect to="/" />;
  }

  if (!user) {
    return null;
  }

  return <PlateChecker parks={parks as FCP_Park[]} />;
}
