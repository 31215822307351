import FieldText from "@/components/form/fields/FieldText";
import FieldTextLeadingSelect from "@/components/form/fields/FieldTextLeadingSelect";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import { CPException } from "@/models/exceptions/CPException";
import { useGetUserQuery, useUpdateUserMutation } from "@/services/user";
import { phoneCountries } from "@/utils/phone";
import { formatPhoneNumber } from "@/utils/validation";
import { useFormik } from "formik";
import { getCountryCallingCode, isValidPhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmailValidator from "validator/lib/isEmail";
import * as Yup from "yup";

export default function FormAccount() {
  const { t } = useTranslation(["account", "common", "validation"]);
  const { data } = useGetUserQuery();
  const { mutateAsync: updateAsync } = useUpdateUserMutation();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues = {
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    phone: data?.phone?.formatted || "",
    countryCode: data?.phone?.countryCode || "CA",
  };

  const validationSchema: unknown = Yup.object().shape({
    firstName: Yup.string().required(t("validation:firstNameRequired")),
    lastName: Yup.string().required(t("validation:lastNameRequired")),
    email: Yup.string()
      .email(t("validation:emailInvalid"))
      .required(t("validation:emailRequired"))
      .test("is-valid", (value) =>
        value
          ? isEmailValidator(value)
          : new Yup.ValidationError(t("validation:emailInvalid"))
      ),
    phone: Yup.string().required(t("validation:phoneRequired")),
  });

  const {
    submitForm,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      if (!isValidPhoneNumber(values.phone, values.countryCode))
        errors.phone = t("validation:phoneInvalid");
      return errors;
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        setFormError(undefined);
        setFormSuccess(false);

        await updateAsync({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: {
            number: `+${getCountryCallingCode(values.countryCode)}${
              values.phone
            }`,
            countryCode: values.countryCode,
          },
        });

        setFormSuccess(true);
        setFormError(undefined);

        setTimeout(() => {
          setFormSuccess(false);
        }, 5000);
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("account:personalInformationFormTitle")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("account:personalInformationFormDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField key="firstName">
                <FieldText
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t("account:firstName")}
                  error={(touched.firstName && errors.firstName) || undefined}
                />
              </FormField>,
              <FormField key="lastName">
                <FieldText
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t("account:lastName")}
                  error={(touched.lastName && errors.lastName) || undefined}
                />
              </FormField>,
              <FormField key="email">
                <FieldText
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t("account:email")}
                  error={(touched.email && errors.email) || undefined}
                />
              </FormField>,
              <FormField key="phone">
                <FieldText
                  id="phone"
                  name="phone"
                  label={t("account:phoneNumber")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatPhoneNumber(values.phone, values.countryCode)}
                  error={(touched.phone && errors.phone) || undefined}
                  autoComplete="tel"
                  LeadingSelect={
                    <FieldTextLeadingSelect
                      label={t("account:countryCode")}
                      value={values.countryCode}
                      onChange={(e) => {
                        setFieldValue("countryCode", e.target.value);
                        setFieldValue("phone", "");
                      }}
                      options={Array.from(phoneCountries).map(
                        ({ countryCode }) => ({
                          value: countryCode,
                          label: countryCode,
                        })
                      )}
                    />
                  }
                />
              </FormField>,
            ]}
          />
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
