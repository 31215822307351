import { useTranslation } from "react-i18next";

interface Props {
  noMargin?: boolean;
}

export default function FormLoader({ noMargin }: Props) {
  const { t } = useTranslation("common");

  return (
    <div className={`mt-[3px] flex justify-center ${noMargin ? "" : "mr-3"}`}>
      <div
        className={`inline-block h-4 w-4 animate-spin rounded-full border-[2px] border-current border-t-transparent text-rapide-600`}
        role="status"
      >
        <span className="sr-only">{t("pleaseWait")}</span>
      </div>
    </div>
  );
}
