import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";
import { createContext } from "react";

export interface PlateCheckerRequestContextProps {
  parkId: string;
  request: PlateCheckerTypes.Request;
  isContentVisible: boolean;
  toggleContentVisibility: () => void;
}

export const PlateCheckerRequestContext = createContext<
  PlateCheckerRequestContextProps | undefined
>(undefined);
