import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { getLanguage } from "@/utils/language";
import enLocale from "./en/_";
import frLocale from "./fr/_";

export const DEFAULT_NS = "common";

export const RESOURCES = {
  fr: frLocale,
  en: enLocale,
} as const;

i18next.use(initReactI18next).init(
  {
    load: "all",
    lng: getLanguage(),
    defaultNS: DEFAULT_NS,
    fallbackLng: ["en"],
    supportedLngs: ["en", "fr"],
    returnObjects: true,
    resources: RESOURCES,
    debug: false,
    keySeparator: ".",
  },
  () => {}
);

export default i18next;
