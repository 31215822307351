import { FCP_Reservation } from "@/@types/reservation";
import Badge from "@/components/Badge";
import Pagination from "@/components/Pagination";
import AlertSimple from "@/components/alert/AlertSimple";
import FieldSelect from "@/components/form/fields/FieldSelect";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import Loader from "@/components/layout/Loader";
import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import { useLazyGetParkReservations } from "@/hooks/query/useLazyGetParkReservations";
import { useGetParksQuery } from "@/services/park";
import { formatDate } from "@/utils/date";
import { formatAddress } from "@/utils/formatter";
import { getLanguage } from "@/utils/language";
import { ArrowSmallRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function ReservationsList() {
  const { t } = useTranslation(["reservations", "common", "park"]);
  const [params, setSearchParams] = useSearchParams();
  const dateFormat =
    getLanguage() === "en" ? "MMM do yyyy H:mm" : "d MMM yyyy H:mm";
  const [loadingReservations, setLoadingReservations] = useState<boolean>(true);
  const [parkOptions, setParkOptions] = useState<
    { name: string; value: string }[]
  >([]);
  const [reservations, setReservations] = useState<FCP_Reservation[]>([]);
  const [reservationsTotal, setReservationsTotal] = useState<number>(0);
  const [noParksError, setNoParksError] = useState<boolean>(false);

  const parkId = params.get("parkId") || "";
  const page = params.get("page") || "1";
  const limit = params.get("limit") || "25";
  const status = params.get("status") || "ongoing";

  const { data: parks, isLoading: isLoadingParks } = useGetParksQuery();
  const fetchReservations = useLazyGetParkReservations();

  useEffect(() => {
    if (parks) {
      setParkOptions(
        parks.map((p) => ({
          name: (p.address && formatAddress(p.address)) || "",
          value: p.objectId || "",
        }))
      );

      if (parks.length === 0) {
        setNoParksError(true);
        return;
      }

      if (!parkId && parks?.[0].objectId) {
        setSearchParams({ parkId: parks[0].objectId, page, limit, status });
      }
    }
  }, [parks]);

  useEffect(() => {
    setLoadingReservations(true);

    let options: { [key: string]: string } = {};

    if (parkId) {
      if (page) {
        options.page = page;
      }

      if (limit) {
        options.limit = limit;
      }

      if (status) {
        if (status === "ongoing") {
          options.startsBefore = new Date().toISOString();
          options.endsAfter = new Date().toISOString();
        } else if (status === "upcoming") {
          options.startsAfter = new Date().toISOString();
        } else if (status === "past") {
          options.endsBefore = new Date().toISOString();
        }
      }

      (async () => {
        try {
          const { items, count } = await fetchReservations({
            parkId,
            options,
          });

          setReservations(items);
          setReservationsTotal(count);
          setLoadingReservations(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [parkId, page, limit, status]);

  if (isLoadingParks) return <Loader />;

  return (
    <Shell>
      <PageHeader title={t("common:reservations")} />

      {!noParksError ? (
        <PageSection>
          <FormFields
            fields={[
              <FormField size="2-6">
                <FieldSelect
                  label={t("common:parking")}
                  options={parkOptions}
                  selectedOptionValue={parkId || parkOptions?.[0]?.value || ""}
                  onSelect={({ value }) =>
                    setSearchParams({ parkId: value, page, limit, status })
                  }
                />
              </FormField>,
              <FormField size="2-6">
                <FieldSelect
                  label={t("common:status")}
                  selectedOptionValue={status || "ongoing"}
                  onSelect={({ value }) =>
                    setSearchParams({ status: value, page, limit, parkId })
                  }
                  options={[
                    { name: "Ongoing", value: "ongoing" },
                    { name: "Upcoming", value: "upcoming" },
                    { name: "Past", value: "past" },
                  ]}
                />
              </FormField>,
              <FormField size="2-6">
                <FieldSelect
                  label={t("common:resultsPerPage")}
                  selectedOptionValue={limit || "25"}
                  onSelect={({ value }) =>
                    setSearchParams({ limit: value, page, status, parkId })
                  }
                  options={[
                    {
                      name: "25",
                      value: "25",
                    },
                    {
                      name: "50",
                      value: "50",
                    },
                    {
                      name: "100",
                      value: "100",
                    },
                  ]}
                />
              </FormField>,
            ]}
          />

          {loadingReservations ? (
            <div className="relative py-20">
              <Loader backgroundStyle="transparent" />
            </div>
          ) : (
            <div className="mt-8 flow-root">
              {reservations.length === 0 ? (
                <p>{t("reservations:noReservations")}</p>
              ) : (
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-simple-900 sm:pl-4"
                            >
                              {t("reservations:reservationId")}
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-simple-900"
                            >
                              {t("reservations:vehicle")}
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-center text-sm font-semibold text-simple-900"
                            >
                              {t("reservations:startDate")}
                            </th>
                            <th scope="col" className="px-3 py-3.5" />
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-center text-sm font-semibold text-simple-900"
                            >
                              {t("reservations:endDate")}
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-right text-sm font-semibold text-simple-900"
                            >
                              {t("reservations:revenues")}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {reservations?.map((r) => {
                            const isCancelled = r.status === "Canceled";

                            return (
                              <tr key={r.objectId}>
                                <td
                                  className={`font-regular whitespace-nowrap py-4 pl-4 pr-3 text-sm text-simple-900 sm:pl-4 ${
                                    isCancelled ? "opacity-75" : ""
                                  }`}
                                >
                                  <Badge
                                    variant={isCancelled ? "neutral" : "info"}
                                  >
                                    {r.objectId}
                                  </Badge>
                                </td>
                                <td
                                  className={`font-regular whitespace-nowrap py-4 pl-4 pr-3 text-sm text-simple-900 sm:pl-4 ${
                                    isCancelled ? "opacity-75" : ""
                                  }`}
                                >
                                  {r?.vehicle ? (
                                    <div className="mr-2 flex">
                                      {r?.vehicle?.plate && (
                                        <div className="mr-2">
                                          <Badge
                                            variant={
                                              isCancelled ? "neutral" : "info"
                                            }
                                          >
                                            {r?.vehicle?.plate}
                                          </Badge>
                                        </div>
                                      )}

                                      {(r?.vehicle?.model?.model ||
                                        r?.vehicle?.model?.make) && (
                                        <Badge
                                          variant={
                                            isCancelled ? "neutral" : "info"
                                          }
                                        >
                                          {r?.vehicle?.model?.model}{" "}
                                          {r?.vehicle?.model?.make}
                                        </Badge>
                                      )}

                                      {r?.vehicle?.noModelString && (
                                        <Badge
                                          variant={
                                            isCancelled ? "neutral" : "info"
                                          }
                                        >
                                          {r.vehicle.noModelString}
                                        </Badge>
                                      )}
                                    </div>
                                  ) : (
                                    <p>{t("park:vehicleNotSet")}</p>
                                  )}
                                </td>
                                <td
                                  className={`font-regular w-[200px] whitespace-nowrap px-3 py-4 text-center text-sm text-gray-600 ${
                                    isCancelled ? "opacity-75" : ""
                                  }`}
                                >
                                  {formatDate(
                                    new Date(r.startDate.iso),
                                    dateFormat
                                  )}
                                </td>
                                <td
                                  className={`font-regular w-[50px] whitespace-nowrap px-3 py-4 text-center text-sm text-gray-600 ${
                                    isCancelled ? "opacity-75" : ""
                                  }`}
                                >
                                  <ArrowSmallRightIcon className="w-[25px] text-rapide-600" />
                                </td>
                                <td
                                  className={`font-regular w-[200px] whitespace-nowrap px-3 py-4 text-center text-sm text-gray-600 ${
                                    isCancelled ? "opacity-75" : ""
                                  }`}
                                >
                                  {formatDate(
                                    new Date(r.endDate.iso),
                                    dateFormat
                                  )}
                                </td>
                                <td
                                  className={`font-regular whitespace-nowrap px-3 py-4 text-right text-sm text-gray-600 ${
                                    isCancelled ? "opacity-75" : ""
                                  }`}
                                >
                                  {isCancelled
                                    ? `(${t("reservations:cancelled")})`
                                    : `${
                                        r.formattedTotalEstimatedPayout ||
                                        t("reservations:na")
                                      }`}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      total={reservationsTotal}
                      perPage={parseInt(limit || "25")}
                      currentPage={parseInt(page || "1")}
                      onPageChange={(updatedPage: number) =>
                        params.set("page", updatedPage.toString())
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </PageSection>
      ) : (
        <PageSection>
          <AlertSimple type="info" title={t("reservations:noParks")} />
        </PageSection>
      )}
    </Shell>
  );
}
