import { FCP_Exception, FCP_Localized } from "@/@types/park";
import ExceptionDatePicker from "@/components/ExceptionDatePicker";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import { Button } from "@/components/ui/button";
import getHolidays from "@/config/holidaysData";
import useParkAdd from "@/hooks/context/useParkAdd";
import { useLazyGetExceptions } from "@/hooks/query/useLazyGetExceptions";
import { CPException } from "@/models/exceptions/CPException";
import {
  useAddExceptionMutation,
  useDeleteExceptionMutation,
} from "@/services/park";
import { addTimeStringToDate, adjustEndTime, formatDate } from "@/utils/date";
import { getLanguage } from "@/utils/language";
import { getCurrentPark } from "@/utils/storage";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import {
  addDays,
  addMinutes,
  format,
  isEqual,
  isSameDay,
  parse,
} from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose, MdEditCalendar } from "react-icons/md";
import { useEffectOnce } from "react-use";

interface ExtendedException extends FCP_Exception {
  deleting: boolean;
}

export default function ParkAddScheduleExceptions() {
  const { t } = useTranslation(["common", "park", "dates", "validation"]);

  const park = getCurrentPark();
  const [loading, setLoading] = useState(true);
  const [exceptions, setExceptions] = useState<ExtendedException[]>([]);
  const [error, setError] = useState<string | undefined>();

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [showStartDatepicker, setShowStartDatepicker] = useState(false);
  const [showEndDatepicker, setShowEndDatepicker] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 1));
  const [startTime, setStartTime] = useState<string>("00:00");
  const [endTime, setEndTime] = useState<string>("00:00");

  const { next, back, exit } = useParkAdd();
  const { mutateAsync: addExceptionAsync } = useAddExceptionMutation();
  const { mutateAsync: deleteExceptionAsync } = useDeleteExceptionMutation();
  const getExceptions = useLazyGetExceptions();

  const holidays = useMemo(
    () => getHolidays(exceptions.map((e) => new Date(e.startDate.iso))),
    [exceptions]
  );

  const yesterday = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }, []);

  async function fetchExceptions() {
    if (!park?.objectId) return;
    const data = await getExceptions({ parkId: park.objectId });
    setExceptions(data.map((d) => ({ ...d.exception, deleting: false })));
    if (loading) setLoading(false);
  }

  useEffectOnce(() => {
    (async () => {
      fetchExceptions();
    })();
  });

  async function deleteException(exceptionId: string) {
    if (!park?.objectId) return;

    const cloned = [...exceptions];
    const index = cloned.findIndex((e) => e.objectId === exceptionId);

    cloned[index].deleting = true;
    setExceptions(cloned);

    await deleteExceptionAsync({
      parkId: park.objectId,
      exceptionId: exceptionId,
    });

    fetchExceptions();
  }

  async function addException(
    start: Date,
    end: Date,
    isHoliday = false,
    labels?: FCP_Localized
  ) {
    if (!park?.objectId) return;

    try {
      setLoading(true);

      const exceptionObject: {
        parkId: string;
        start: Date;
        end: Date;
        localizedLabel?: { languageCode: string; localizedString: string }[];
        editable?: boolean;
      } = {
        parkId: park.objectId,
        start: isHoliday ? start : addTimeStringToDate(start, startTime),
        end: isHoliday ? end : addTimeStringToDate(end, endTime),
      };

      if (labels) {
        exceptionObject.localizedLabel = [
          { languageCode: "fr", localizedString: labels.fr },
          { languageCode: "en", localizedString: labels.en },
          { languageCode: "default", localizedString: labels.default },
        ];
      }

      await addExceptionAsync(exceptionObject);
      await fetchExceptions();
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setLoading(false);
  }

  async function addHolidayExceptions() {
    holidays.map((holiday) => {
      const start = new Date(holiday.date);
      start.setHours(0, 0, 0); // Set start time to 00:00:00
      const end = new Date(holiday.date);
      end.setHours(23, 59, 59); // Set end time to 23:59:59
      addException(start, end, true, {
        fr: holiday.name.fr,
        en: holiday.name.en,
        default: holiday.name.fr,
      });
    });
  }

  function onBack() {
    back();
  }

  function onNext() {
    next();
  }

  function onSaveAndExit() {
    exit();
  }

  return (
    <ParkAddShell
      loading={loading}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: loading,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: loading,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: loading,
          onClick: onBack,
        },
      }}
    >
      {error && <FormError title={error} />}

      <FormFields
        fields={[
          <FormField key="exceptions">
            <FormHeading title={t("park:exceptions")} />

            <div className="rounded-md border border-green-500 bg-green-100/10 p-4 md:p-6">
              <p className="text-sm">{t("park:preventRentalsDescription")}</p>
              <p className="mt-2 text-[12px]">
                <strong>{t("park:preventRentalsHint")}</strong>
              </p>
            </div>

            {exceptions.length > 0 ? (
              <div className="mt-4">
                <ul className="list-inside list-disc">
                  {exceptions.map((exception, index) => {
                    const start = new Date(exception.startDate.iso);
                    const end = new Date(exception.endDate.iso);

                    let formattedDate: React.ReactNode = (
                      <div className="text-[13px] font-normal md:text-sm">
                        <div className="mb-0.5 mt-0.5 text-xs font-bold text-green-600">
                          {exception.localizedLabel &&
                            exception.localizedLabel[getLanguage()]}
                        </div>

                        <div className="flex">
                          <div className="font-bold">{formatDate(start)}</div>
                          <div className="ml-1">
                            {formatDate(start, "h:mm aaaaa'm'").toUpperCase()}
                          </div>
                          <ArrowRightIcon className="mx-2 w-[15px]" />
                          <div className="font-bold">{formatDate(end)}</div>
                          <div className="mx-1">
                            {formatDate(end, "h:mm aaaaa'm'").toUpperCase()}
                          </div>
                        </div>
                      </div>
                    );

                    if (isSameDay(start, end)) {
                      formattedDate = (
                        <div className="text-[13px] font-normal md:text-sm">
                          <div className="mb-0.5 mt-0.5 text-xs font-bold text-green-600">
                            {exception.localizedLabel &&
                              exception.localizedLabel[getLanguage()]}
                          </div>

                          <div className="flex">
                            <div className="font-bold">{formatDate(start)}</div>
                            <div className="ml-1">
                              {formatDate(start, "h:mm aaaaa'm'").toUpperCase()}
                            </div>
                            <ArrowRightIcon className="mx-2 w-[15px]" />
                            <div className="mr-1">
                              {formatDate(end, "h:mm aaaaa'm'").toUpperCase()}
                            </div>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <li
                        key={index}
                        className="my-3 flex items-center justify-between rounded-lg border border-silver-800 bg-white py-3 pl-3 pr-2 text-sm font-bold shadow-sm md:pl-5 md:pr-2"
                      >
                        {formattedDate}
                        <Button
                          variant="subtle"
                          color="danger"
                          size="sm"
                          onClick={() => deleteException(exception.objectId)}
                          loading={exception.deleting}
                          className="pl-2 pr-2"
                        >
                          <MdClose size={18} className="text-red-500" />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <p className="mt-4">{t("park:noBlockedDates")}</p>
            )}

            {showDatepicker ? (
              <div className="mt-4 rounded-lg bg-white px-6 py-5 shadow ring-1 ring-black ring-opacity-5">
                <p className="text-md font-bold">
                  {t("park:blockNewCustomDate")}
                </p>

                <div className="my-5 flex">
                  <div className="flex-1">
                    <label className="mb-1 block text-xs font-bold">
                      {t("park:blockFrom")}
                    </label>

                    <div className="relative">
                      <Button
                        fullSize
                        shape="rounded"
                        variant="light"
                        onClick={() => {
                          setShowStartDatepicker(!showStartDatepicker);
                          if (showEndDatepicker) setShowEndDatepicker(false);
                        }}
                        className="flex items-center"
                      >
                        <MdEditCalendar className="mr-2 mt-[-1px] hidden md:block" />
                        {formatDate(startDate)} {startTime}
                      </Button>

                      <ExceptionDatePicker
                        visible={showStartDatepicker}
                        date={startDate}
                        minDate={yesterday}
                        minTime={startTime}
                        selectedTime={startTime}
                        onClose={setShowStartDatepicker}
                        onTimeSelect={(time) => {
                          setStartTime(time);
                          adjustEndTime(
                            startDate,
                            endDate,
                            time,
                            endTime,
                            setEndTime
                          );
                          if (time === "23:30" && isSameDay(startDate, endDate))
                            setEndDate(addDays(startDate, 1));
                        }}
                        onDateSelect={(date) => {
                          setStartDate(date);
                          if (date > endDate) setEndDate(date);
                          adjustEndTime(
                            date,
                            endDate,
                            startTime,
                            endTime,
                            setEndTime
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="ml-5 flex-1">
                    <label className="mb-1 block text-xs font-bold">
                      {t("park:blockUntil")}
                    </label>

                    <div className="relative">
                      <Button
                        fullSize
                        shape="rounded"
                        variant="light"
                        onClick={() => {
                          setShowEndDatepicker(!showEndDatepicker);
                          if (showStartDatepicker)
                            setShowStartDatepicker(false);
                        }}
                        className="flex items-center"
                      >
                        <MdEditCalendar className="mr-2 mt-[-1px] hidden md:block" />
                        {formatDate(endDate)} {endTime}
                      </Button>

                      <ExceptionDatePicker
                        visible={showEndDatepicker}
                        date={endDate}
                        minDate={
                          startTime === "23:30"
                            ? addDays(startDate, 1)
                            : startDate
                        }
                        minTime={format(
                          addMinutes(parse(startTime, "HH:mm", new Date()), 30),
                          "HH:mm"
                        )}
                        selectedTime={endTime}
                        onClose={setShowEndDatepicker}
                        onDateSelect={(date) => {
                          setEndDate(date);
                          adjustEndTime(
                            startDate,
                            date,
                            startTime,
                            endTime,
                            setEndTime
                          );
                        }}
                        onTimeSelect={(time) => {
                          if (
                            isSameDay(endDate, startDate) &&
                            isEqual(
                              parse(time, "HH:mm", new Date()),
                              parse(startTime, "HH:mm", new Date())
                            )
                          ) {
                            setEndTime(
                              format(
                                addMinutes(
                                  parse(time, "HH:mm", new Date()),
                                  30
                                ),
                                "HH:mm"
                              )
                            );
                          } else {
                            setEndTime(time);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <Button
                  onClick={() => {
                    if (!startDate || !endDate) return;

                    let startMonth = (startDate.getMonth() + 1).toString();
                    if (startMonth.length === 1) startMonth = `0${startMonth}`;

                    let startDay = startDate.getDate().toString();
                    if (startDay.length === 1) startDay = `0${startDay}`;

                    let endMonth = (endDate.getMonth() + 1).toString();
                    if (endMonth.length === 1) endMonth = `0${endMonth}`;

                    let endDay = endDate.getDate().toString();
                    if (endDay.length === 1) endDay = `0${endDay}`;

                    addException(
                      new Date(
                        `${`${startDate.getFullYear()}-${startMonth}-${startDay}`}T00:00:00`
                      ),
                      new Date(
                        `${`${endDate.getFullYear()}-${endMonth}-${endDay}`}T00:00:00`
                      ),
                      false
                    );

                    setShowDatepicker(false);
                  }}
                  disabled={!(startDate && endDate)}
                >
                  {t("common:add")}
                </Button>

                <Button
                  className="ml-2"
                  variant="subtle"
                  onClick={() => setShowDatepicker(false)}
                >
                  {t("common:cancel")}
                </Button>

                <div className="mt-8 border-t border-t-silver-800 pt-6" />

                {holidays.length > 0 ? (
                  <>
                    <p className="text-md mb-2 font-bold">
                      {t("park:blockAHoliday")}
                    </p>

                    <ul className="-ml-2">
                      {holidays.map((holiday) => (
                        <li
                          className="group m-2 inline-flex cursor-pointer items-center rounded-full bg-green-100 py-1.5 pl-2 pr-4 text-[11px] font-bold ring-1 ring-green-500 transition-all hover:ring-2"
                          key={holiday.date.toISOString()}
                          onClick={() => {
                            const start = new Date(holiday.date);
                            start.setHours(0, 0, 0); // Set start time to 00:00:00
                            const end = new Date(holiday.date);
                            end.setHours(23, 59, 59); // Set end time to 23:59:59
                            addException(start, end, true, {
                              fr: holiday.name.fr,
                              en: holiday.name.en,
                              default: holiday.name.fr,
                            });
                          }}
                        >
                          <PlusCircleIcon className="mr-2 h-[25px] w-[25px] text-green-500" />
                          <div>
                            <div className="mt-[1px] text-[10px]">
                              {holiday.name[getLanguage()]}
                            </div>
                            <div className="mt-[-2px] text-[9px] font-normal">
                              {formatDate(holiday.date)}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <button
                      onClick={addHolidayExceptions}
                      className="mt-4 inline-flex items-center rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-simple-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {t("park:blockAllHolidays")}
                    </button>
                  </>
                ) : null}
              </div>
            ) : (
              <div className="mt-4">
                <Button onClick={() => setShowDatepicker(true)}>
                  {t("park:blockNewDate")}
                </Button>
              </div>
            )}
          </FormField>,
        ]}
      />
    </ParkAddShell>
  );
}
