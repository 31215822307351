import { FCP_Park } from "@/@types/park";
import Badge from "@/components/Badge";
import Loader from "@/components/layout/Loader";
import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import { Button } from "@/components/ui/button";
import {
  mapLegacyStepNumberToStepName,
  resumeToStep,
} from "@/contexts/ParkAddContext";
import { useDeleteParkMutation, useGetParksQuery } from "@/services/park";
import { formatAddress } from "@/utils/formatter";
import * as tracker from "@/utils/tracker";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPencilAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface ExtendedPark extends Partial<FCP_Park> {
  deleting?: boolean;
}

export default function ParksList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common"]);
  const { data } = useGetParksQuery();
  const { mutateAsync: deleteParkAsync } = useDeleteParkMutation();

  const [extendedParks, setExtendedParks] = useState<ExtendedPark[]>([]);

  useEffect(() => {
    if (data) {
      setExtendedParks(
        data.map((park) => ({
          ...park,
          deleting: false,
        }))
      );

      tracker.addUserProperties({ numberOfParks: data.length });
    }
  }, [data]);

  function handleEdit(id: string) {
    navigate(`/parks/${id}`);
  }

  async function handleContinue(park: ExtendedPark) {
    const stepToResumeTo = park?.uxMetadata?.resumeToStep;
    const query = `?id=${park.objectId}`;

    // Handle legacy resumeToStep which was a number until 2023/09/01
    if (typeof stepToResumeTo === "number") {
      resumeToStep(mapLegacyStepNumberToStepName(stepToResumeTo), query);
    } else if (typeof stepToResumeTo === "string") {
      resumeToStep(stepToResumeTo, query);
    }
  }

  async function handleDelete(id: string) {
    try {
      setExtendedParks((prev) => {
        return prev.map((park) => {
          if (park.objectId === id) {
            return { ...park, deleting: true };
          }
          return park;
        });
      });

      await deleteParkAsync({ parkId: id });
      queryClient.invalidateQueries({ queryKey: ["parks"] });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Shell>
      <PageHeader
        title={t("common:parks")}
        actions={[
          <Button key="create" onClick={() => navigate("/parks/add")}>
            {t("common:addNewPark")}
          </Button>,
        ]}
      />

      <PageSection>
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 pb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
        >
          {extendedParks?.map((park, index) => {
            const isPublished =
              park?.status === "submitted" && park?.validationObject?.valid;

            return (
              <li
                key={index}
                className="col-span-1 flex flex-col divide-y rounded-xl bg-white text-center shadow ring-1 ring-black ring-opacity-5"
              >
                <div className="flex flex-1 flex-col">
                  <div
                    onClick={() => park.objectId && handleEdit(park.objectId)}
                    className="h-[225px] w-full rounded-tl-xl rounded-tr-xl bg-silver-900 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${park?.photos?.[0]?.files.thumbnail.path})`,
                    }}
                  />

                  <div
                    className="p-8"
                    onClick={() => park.objectId && handleEdit(park.objectId)}
                  >
                    <h3 className="text-sm font-bold text-simple-900">
                      {park?.address && formatAddress(park.address, "short")}
                    </h3>

                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dd className="text-sm text-gray-600">
                        {park?.address?.city}, {park?.address?.state},{" "}
                        {park?.address?.country}
                      </dd>

                      {!isPublished && (park?.status || park?.isInvisible) && (
                        <dd className="mt-3 gap-x-1">
                          {park?.status && (
                            <Badge>{t(`common:${park.status}`)}</Badge>
                          )}

                          {park?.isInvisible && (
                            <Badge>{t("common:invisible")}</Badge>
                          )}
                        </dd>
                      )}
                    </dl>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="-ml-px flex w-0 flex-1">
                      {park?.status === "draft" ? (
                        <button
                          onClick={() => park.objectId && handleContinue(park)}
                          className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-simple-900 hover:bg-silver-900/25"
                        >
                          <FaPencilAlt className="h-3 w-3 text-silver-600" />
                          {t("common:continue")}
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            park.objectId && handleEdit(park.objectId)
                          }
                          className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-simple-900 hover:bg-silver-900/25"
                        >
                          <FaPencilAlt className="h-3 w-3 text-silver-600" />
                          {t("common:modify")}
                        </button>
                      )}
                    </div>
                    {park?.status === "draft" && (
                      <div className="flex w-0 basis-16">
                        <button
                          onClick={() =>
                            park.objectId && handleDelete(park.objectId)
                          }
                          className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-simple-900 hover:bg-red-100/50"
                        >
                          {park.deleting ? (
                            <Loader />
                          ) : (
                            <MdDeleteForever className="h-5 w-5 text-red-500" />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </PageSection>
    </Shell>
  );
}
