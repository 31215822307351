import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import FormVehiclesWhitelist from "@/containers/forms/FormVehiclesWhitelist";
import { useTranslation } from "react-i18next";

export default function AccountVehiclesWhitelist() {
  const { t } = useTranslation(["account", "common"]);

  return (
    <Shell>
      <PageHeader
        title={t("common:account")}
        tabs={[
          {
            name: t("account:personalInformationFormTitle"),
            href: `/account`,
            current: false,
          },
          {
            name: t("account:vehiclesWhitelist"),
            href: `/account/vehicles-whitelist`,
            current: true,
          },
          {
            name: t("account:preferences"),
            href: `/account/preferences`,
            current: false,
          },
        ]}
      />

      <div className="divide-y divide-silver-800">
        <PageSection>
          <FormVehiclesWhitelist />
        </PageSection>
      </div>
    </Shell>
  );
}
