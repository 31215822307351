import FieldNumber from "@/components/form/fields/FieldNumber";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormParkNumberOfSpaces() {
  const { park } = useParkEdit();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues = {
    spaceQuantity: park?.spaceQuantity?.toString() || "1", // Needs to be a string for the FieldNumber component
  };

  const {
    submitForm,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validate: (values) => {
      const errors: Partial<typeof values> = {};
      if (!values.spaceQuantity)
        errors.spaceQuantity = t("validation:spaceQuantityRequired");
      if (values.spaceQuantity && parseInt(values.spaceQuantity) < 1)
        errors.spaceQuantity = t("validation:spaceQuantityMin");
      return errors;
    },
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async () => {
      if (!park) return;

      setFormError(undefined);
      setFormSuccess(false);

      try {
        await updateParkAsync({
          parkId: park.objectId,
          updates: { spaceQuantity: parseInt(values.spaceQuantity) },
        });

        setFormSuccess(true);
        setFormError(undefined);

        setTimeout(() => {
          setFormSuccess(false);
        }, 5000);
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:numberOfSpaces")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:editNumberOfSpacesDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField key="spaceQuantity">
                <FieldNumber
                  name="spaceQuantity"
                  fieldValue={values.spaceQuantity.toString()}
                  onNumberChange={(value) =>
                    setFieldValue("spaceQuantity", value)
                  }
                  error={touched && errors.spaceQuantity}
                />
              </FormField>,
            ]}
          />
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
