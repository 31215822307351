import { FCP_Photo } from "@/@types/park";
import FieldMinimalUpload from "@/components/form/fields/FieldMinimalUpload";
import FormError from "@/components/form/layout/FormError";
import FormLoader from "@/components/form/layout/FormLoader";
import Spacer from "@/components/Spacer";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { clicknpark } from "@/services/api";
import { useUpdateParkMutation } from "@/services/park";
import { IconDragDrop } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { arrayMoveImmutable } from "array-move";
import { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";

export default function FormParkPhotos() {
  const { park } = useParkEdit();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();
  const [error, setError] = useState<string | undefined>();
  const [sorting, setSorting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deletingPhotoId, setDeletingPhotoId] = useState<string | undefined>();
  const [sortedPhotos, setSortedPhotos] = useState<FCP_Photo[]>();

  useEffect(() => {
    if (park?.photos) setSortedPhotos(park.photos);
  }, [park]);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setSortedPhotos((photos) =>
      photos ? arrayMoveImmutable(photos, oldIndex, newIndex) : []
    );
  };

  async function updatePark(updates: unknown) {
    if (!park) return;
    await updateParkAsync({
      parkId: park.objectId,
      updates,
    });
  }

  async function onPhotoUpload(file: File) {
    setUploading(true);

    if (!park?.objectId) return;

    try {
      const { result } = clicknpark.parks.uploadPhotoToGallery(park.objectId, [
        file,
      ]);

      const uploadedPhotos = await result;

      try {
        // Make sure the park is up to date
        const currentPhotoIds = park?.photos?.map((photo) => photo.id) || [];

        await updatePark({
          photos: [
            ...currentPhotoIds,
            ...uploadedPhotos.map((photo) => photo.id),
          ],
        });

        queryClient.invalidateQueries({ queryKey: ["park" + park?.objectId] });
      } catch (error) {
        if (error instanceof CPException) {
          setError(error.message);
        } else {
          setError(t("validation:genericError"));
        }
      }
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setUploading(false);
  }

  async function deletePhoto(photoId: string) {
    setDeletingPhotoId(photoId);

    if (window.confirm(t("park:deletePhotoConfirmation"))) {
      // Make sure the park is up to date
      const currentPhotoIds = park?.photos?.map((photo) => photo.id) || [];

      const filteredPhotoIds = currentPhotoIds.filter(
        (currentPhotoId) => currentPhotoId !== photoId
      );

      await updatePark({
        photos: filteredPhotoIds,
      });

      queryClient.invalidateQueries({ queryKey: ["park" + park?.objectId] });
    }

    setDeletingPhotoId(undefined);
  }

  async function saveSorting() {
    if (!sortedPhotos) return;

    setSaving(true);

    const photoIds = sortedPhotos.map((photo) => photo.id);

    await updatePark({
      photos: photoIds,
    });

    queryClient.invalidateQueries({ queryKey: ["park" + park?.objectId] });

    setSorting(false);
    setSaving(false);
  }

  const Photos = (
    <div>
      <ul className="relative flex flex-wrap gap-5 overflow-auto">
        <FieldMinimalUpload
          className="h-[100px] w-[100px] sm:h-[175px] sm:w-[175px]"
          onUpload={onPhotoUpload}
          uploading={uploading}
        />

        {park?.photos && park?.photos?.length > 0 && (
          <>
            {park.photos.map((photo) => (
              <li key={photo.id} className="relative">
                <div
                  className="relative  h-[100px] w-[100px] rounded-md border border-silver-600 bg-cover shadow-sm sm:h-[175px] sm:w-[175px]"
                  style={{
                    backgroundImage: `url(${photo.files.thumbnail.path})`,
                  }}
                >
                  {deletingPhotoId === photo.id && (
                    <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-md bg-white/75">
                      <FormLoader noMargin />
                    </div>
                  )}
                </div>

                <div className="flex items-center">
                  <div
                    className="mr-3 mt-2 flex items-center text-xs text-rapide-600 hover:cursor-pointer hover:text-rapide-600"
                    onClick={() => deletePhoto(photo.id)}
                  >
                    <AiFillDelete
                      size={12}
                      className="-mt-0.5 mr-1 text-rapide-600"
                    />
                    {t("park:deletePhoto")}
                  </div>

                  {/* <a
                  className="mt-2 flex items-center text-xs text-rapide-600 hover:cursor-pointer hover:text-rapide-600"
                  href={photo.files.fullSize.path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <MdFullscreen
                    size={15}
                    className="-mt-0.5 mr-1 text-rapide-600"
                  />
                  {t("park:viewPhoto")}
                </a> */}
                </div>
              </li>
            ))}
          </>
        )}
      </ul>

      {park?.photos && park?.photos?.length > 0 && (
        <>
          <Spacer />

          <Button onClick={() => setSorting(true)} variant="outline">
            {t("park:sortPhotos")}
          </Button>
        </>
      )}
    </div>
  );

  const SortPhotos = (
    <div>
      <p className="mb-4 flex items-center">
        <IconDragDrop className="mr-2 w-[20px] text-rapide-600" />
        <strong className="mt-1 text-sm text-rapide-600">
          <span className="hidden sm:block">{t("park:dragToReorder")}</span>
          <span className="block sm:hidden">
            {t("park:holdAnddragToReorder")}
          </span>
        </strong>
      </p>

      <ul className="t-5 relative flex flex-wrap gap-5 overflow-auto">
        {sorting && sortedPhotos && (
          <SortableList
            as="ul"
            onSortEnd={onSortEnd}
            className="relative flex flex-wrap gap-5 overflow-auto"
            draggedItemClassName="shadow-lg"
          >
            {sortedPhotos.map((photo) => (
              <SortableItem key={photo.id}>
                <li key={photo.id}>
                  <div
                    className="h-[100px] w-[100px] rounded-md border border-silver-600 bg-cover shadow-sm transition-all hover:border-silver-600 hover:shadow-lg sm:h-[175px] sm:w-[175px]"
                    style={{
                      backgroundImage: `url(${photo.files.thumbnail.path})`,
                    }}
                  />
                </li>
              </SortableItem>
            ))}
          </SortableList>

          // <SortableList
          //   horizontal
          //   items={sortedPhotos}
          //   setItems={(items) => setSortedPhotos(items as FCP_Photo[])}
          //   itemRender={({ item }: ItemRenderProps) => (
          //     <li key={item.id}>
          //       <div
          //         className="relative  h-[100px] w-[100px] rounded-md border border-silver-600 bg-cover shadow-sm transition-all hover:border-silver-600 hover:shadow-lg sm:h-[175px] sm:w-[175px]"
          //         style={{
          //           backgroundImage: `url(${item.files.thumbnail.path})`,
          //         }}
          //       />
          //     </li>
          //   )}
          // />
        )}
      </ul>

      <Spacer />

      <Button onClick={saveSorting} loading={saving}>
        {t("park:savePhotosOrder")}
      </Button>
    </div>
  );

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:photosTitle")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:photosDescription")}
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="sm:max-w-xl">
          {error && (
            <div className="mb-5">
              <FormError title={error} />
            </div>
          )}

          {sorting ? SortPhotos : Photos}
        </div>
      </div>
    </div>
  );
}
