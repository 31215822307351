import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import i18next from "i18next";

dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.locale(i18next.language);

export class DateUtils {
  public static getLocalizedFormat(
    format:
      | "MM/DD/YYYY"
      | "MMM DD, YYYY"
      | "MMM DD YYYY, HH:mm"
      | "MMM DD YYYY, HH:mm:ss"
      | "MMM DD, HH:mm:ss"
      | "MMM DD, HH:mm"
      | "MMM DD, HH:mm:ss"
  ) {
    dayjs.locale(i18next.language);

    switch (format) {
      case "MM/DD/YYYY":
        return i18next.language === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY";
      case "MMM DD, YYYY":
        return i18next.language === "en" ? "MMM DD, YYYY" : "DD MMM, YYYY";
      case "MMM DD YYYY, HH:mm":
        return i18next.language === "en"
          ? "MMM DD YYYY, HH:mm"
          : "DD MMM YYYY, HH:mm";
      case "MMM DD YYYY, HH:mm:ss":
        return i18next.language === "en"
          ? "MMM DD YYYY, HH:mm:ss"
          : "DD MMM YYYY, HH:mm:ss";
      case "MMM DD, HH:mm":
        return i18next.language === "en" ? "MMM DD, HH:mm" : "DD MMM, HH:mm";
      case "MMM DD, HH:mm:ss":
        return i18next.language === "en"
          ? "MMM DD, HH:mm:ss"
          : "DD MMM, HH:mm:ss";
      default:
        return "MMM DD, YYYY";
    }
  }

  public static formatDate(date: dayjs.Dayjs) {
    dayjs.locale(i18next.language);
    const minutes = date.minute();
    const roundedMinutes = Math.round(minutes / 15) * 15;
    const roundedDate = date.minute(roundedMinutes).second(0);

    if (dayjs().isSame(roundedDate, "day")) {
      return i18next.t("dates:todayAtTime", {
        time:
          i18next.language === "en"
            ? roundedDate.format("hh:mm A")
            : roundedDate.format("HH:mm"),
      });
    } else if (dayjs().add(1, "day").isSame(roundedDate, "day")) {
      return i18next.t("dates:tomorrowAtTime", {
        time:
          i18next.language === "en"
            ? roundedDate.format("hh:mm A")
            : roundedDate.format("HH:mm"),
      });
    } else {
      return i18next.language === "en"
        ? roundedDate.format("ll hh:mm A")
        : roundedDate.format("ll HH:mm");
    }
  }

  public static getClosestQuarter(date: dayjs.Dayjs) {
    const minutes = date.minute();
    const closestQuarter = Math.floor(minutes / 15) * 15;
    return date.minute(closestQuarter).second(0);
  }

  public static getClosestHalf(date: dayjs.Dayjs) {
    const minutes = date.minute();
    const closestHalf = Math.floor(minutes / 30) * 30;
    return date.minute(closestHalf).second(0);
  }
}
