import classNames from "@/utils/cx";
import { getLanguage } from "@/utils/language";
import { IconMenu2 } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface Props {
  sticky?: boolean;
  toggleMenu: VoidFunction;
}

export default function MobileHeader({ sticky = true, toggleMenu }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        "top-0 z-20 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden",
        sticky ? "sticky" : ""
      )}
    >
      <div className="flex-1 text-sm font-semibold leading-6 text-simple-900">
        <img
          onClick={() => navigate("/")}
          className="h-[32px] w-[175px]"
          src={`/images/layout/logo-${getLanguage()}-CA.svg`}
          alt="clicknpark"
        />
      </div>

      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={toggleMenu}
      >
        <span className="sr-only">Open sidebar</span>
        <IconMenu2 className="text-simple-900" />
      </button>
    </div>
  );
}
