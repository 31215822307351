import { FCP_User } from "@/@types/user";
import { getBifrostEmail } from "@/utils/storage";
import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";
import TagManager from "react-gtm-module";

type ParkingCreationStep =
  | "started"
  | "resumed"
  | "enter-address"
  | "confirm-address"
  | "entry-location"
  | "park-type"
  | "description"
  | "vehicle-size"
  | "features"
  | "photos"
  | "availabilities"
  | "exceptions"
  | "pricing"
  | "summary"
  | "saved-and-exited";

export const initialize = () => {
  if (import.meta.env.VITE_ENV === "production" && !getBifrostEmail()) {
    TagManager.initialize({ gtmId: import.meta.env.VITE_GTM });
  }
};

export const identify = (user: Partial<FCP_User>) => {
  if (getBifrostEmail()) return;

  TagManager.dataLayer({ dataLayer: { user_id: user.objectId } });
  mixpanel?.identify(user.objectId);
  posthog.identify(user.objectId);

  const firstName = user.firstName || "";
  const lastName = user.lastName || "";
  const name = firstName && lastName ? firstName + " " + lastName : "";

  addUserProperties({ email: user.email, name, locale: user.locale });
};

export const addUserProperties = (data: unknown) => {
  if (getBifrostEmail()) return;
  mixpanel?.people.set(data);
  posthog.people.set(data);
};

const trackParkAdd = (step: ParkingCreationStep, id?: string) => {
  TagManager.dataLayer({
    dataLayer: { event: "dash_park_spot_added", step, id },
  });
};

export const track = {
  onboarding: {
    started: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("started_onboarding");
      posthog.capture("dashboard_started_onboarding");
      TagManager.dataLayer({ dataLayer: { event: "dash_onboarding_started" } });
    },
    landedOnOnKyc: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("landed_kyc");
      posthog.capture("dashboard_landed_kyc");
      TagManager.dataLayer({
        dataLayer: { event: "dash_landed_on_verification" },
      });
    },
    startedKyc: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("started_kyc");
      posthog.capture("dashboard_started_kyc");
      TagManager.dataLayer({
        dataLayer: { event: "dash_started_verification" },
      });
    },
    completedKyc: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("completed_kyc");
      posthog.capture("dashboard_completed_kyc");
      TagManager.dataLayer({ dataLayer: { event: "dash_verification" } });
    },
    landedOnLinkBankAccount: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("landed_link_bank_account");
      posthog.capture("dashboard_landed_link_bank_account");
      TagManager.dataLayer({
        dataLayer: { event: "dash_landed_on_link_bank_account" },
      });
    },
    linkedBankAccount: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("completed_link_bank_account");
      posthog.capture("dashboard_completed_link_bank_account");
      TagManager.dataLayer({ dataLayer: { event: "dash_bank_acc_added" } });
    },
    completed: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("completed_onboarding");
      posthog.capture("dashboard_completed_onboarding");
      TagManager.dataLayer({
        dataLayer: { event: "dash_onboarding_completed" },
      });
    },
  },
  auth: {
    loggedIn: (login_method: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("logged_in", { login_method: login_method });
      posthog.capture("dashboard_logged_in", { login_method: login_method });
      TagManager.dataLayer({ dataLayer: { event: "login", login_method } });
    },
    startedSignup: (signup_method: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("started_signup", { signup_method: signup_method });
      posthog.capture("dashboard_started_signup", { signup_method: signup_method });
      TagManager.dataLayer({
        dataLayer: { event: "dash_account_creation_started", signup_method },
      });
    },
    signedUp: (signup_method: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("signed_up", { signup_method: signup_method });
      posthog.capture("dashboard_signed_up", { signup_method: signup_method });
      TagManager.dataLayer({
        dataLayer: { event: "dash_account_creation", signup_method },
      });
    },
    completedProfile: (signup_method: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("completed_profile", { signup_method: signup_method });
      TagManager.dataLayer({
        dataLayer: { event: "dash_account_profile_completed", signup_method },
      });
    },
  },
  parkAdd: {
    started: (id?: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_started", { park_id: id });
      posthog.capture("dashboard_new_park_started", { park_id: id });
      trackParkAdd("started", id);
    },
    enteredAddress: (id?: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_entered_address", { park_id: id });
      posthog.capture("dashboard_new_park_entered_address", { park_id: id });
      trackParkAdd("enter-address", id);
    },
    confirmedAddress: (id?: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_confirmed_address", { park_id: id });
      posthog.capture("dashboard_new_park_confirmed_address", { park_id: id });
      trackParkAdd("confirm-address", id);
    },
    enteredLocation: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_confirmed_location", { park_id: id });
      posthog.capture("dashboard_new_park_confirmed_location", { park_id: id });
      trackParkAdd("entry-location", id);
    },
    selectedType: (id: string, type: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_selected_type", {
        park_id: id,
        park_type: type,
      });
      posthog.capture("dashboard_new_park_selected_type", {
        park_id: id,
        park_type: type,
      });
      trackParkAdd("park-type", id);
    },
    enteredDescription: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_entered_description", { park_id: id });
      posthog.capture("dashboard_new_park_entered_description", { park_id: id });
      trackParkAdd("description", id);
    },
    selectedVehicleSize: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_selected_max_vehicle_size", { park_id: id });
      posthog.capture("dashboard_new_park_selected_max_vehicle_size", { park_id: id });
      trackParkAdd("vehicle-size", id);
    },
    selectedFeatures: (id: string, features: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_selected_features", {
        park_id: id,
        park_features: features,
      });
      posthog.capture("dashboard_new_park_selected_features", {
        park_id: id,
        park_features: features,
      });
      trackParkAdd("features", id);
    },
    uploadedPhotos: (id: string, numberOfPhotos: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_uploaded_photos", {
        park_id: id,
        park_number_of_photos: numberOfPhotos,
      });
      posthog.capture("dashboard_new_park_uploaded_photos", {
        park_id: id,
        park_number_of_photos: numberOfPhotos,
      });
      trackParkAdd("photos", id);
    },
    enteredAvailabilities: (id: string, scheduleType: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_set_availabilities", {
        park_id: id,
        schedule_type: scheduleType,
      });
      posthog.capture("dashboard_new_park_set_availabilities", {
        park_id: id,
        schedule_type: scheduleType,
      });
      trackParkAdd("availabilities", id);
    },
    enteredExceptions: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_set_exceptions", { park_id: id });
      trackParkAdd("exceptions", id);
    },
    enteredPricing: (id: string, pricingMode: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_set_pricing", {
        park_id: id,
        pricing_mode: pricingMode,
      });
      posthog.capture("dashboard_new_park_set_pricing", {
        park_id: id,
        pricing_mode: pricingMode,
      });
      trackParkAdd("pricing", id);
    },
    reviewed: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_reviewed_summary", { park_id: id });
      posthog.capture("dashboard_new_park_reviewed_summary", { park_id: id });
      trackParkAdd("summary", id);
    },
    setVehiclesWhitelist: () => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_set_vehicles_whitelist");
      posthog.capture("dashboard_new_park_set_vehicles_whitelist");
    },
    exited: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_exited", { park_id: id });
      posthog.capture("dashboard_new_park_exited", { park_id: id });
      trackParkAdd("saved-and-exited", id);
    },
    submitted: (id: string) => {
      if (getBifrostEmail()) return;
      mixpanel?.track("new_park_submitted", { park_id: id });
      posthog.capture("dashboard_new_park_submitted", { park_id: id });
      TagManager.dataLayer({
        dataLayer: { event: "dash_park_spot_submitted", id },
      });
    },
  },
};
