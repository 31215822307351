interface Props {
  size?: "sm" | "md";
  variant?: "neutral" | "error" | "warning" | "info" | "success";
  upperCase?: boolean;
  bordered?: boolean;
  children: React.ReactNode;
}

export default function Badge({
  size = "sm",
  variant = "neutral",
  upperCase = false,
  bordered = false,
  children,
}: Props) {
  let className = "inline-flex items-center rounded px-2 pt-1 pb-0.5 font-bold";

  switch (variant) {
    case "error":
      className += " bg-red-100 px-2 py-0.5 text-red-800";
      bordered && (className += " border border-red-300");
      break;
    case "warning":
      className += " bg-yellow-100 px-2 py-0.5 text-yellow-800";
      bordered && (className += " border border-yellow-300");
      break;
    case "info":
      className += " bg-blue-100 px-2 py-0.5 text-blue-800";
      bordered && (className += " border border-blue-300");
      break;
    case "success":
      className += " bg-green-100 px-2 py-0.5 text-green-800";
      bordered && (className += " border border-green-300");
      break;
    default:
      className += " bg-gray-100 px-2 py-0.5 text-gray-800";
      bordered && (className += " border border-gray-300");
  }

  switch (size) {
    case "sm":
      className += " text-[10px] px-0.5 pt-1 pb-0.5";
      break;
    case "md":
      className += " text-sm";
      break;
  }

  if (upperCase) {
    className += " uppercase";
  }

  return <span className={className}>{children}</span>;
}
