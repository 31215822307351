import axios from "axios";
import { useState } from "react";

const api_key: string = import.meta.env.VITE_ABSTRACT_API_KEY;

export default function useGetLocationFromIP() {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<
    google.maps.LatLngLiteral | undefined
  >();

  async function getLocation() {
    try {
      const response = await axios.get(
        "https://ipgeolocation.abstractapi.com/v1/",
        {
          params: {
            api_key,
          },
        }
      );

      setLocation({
        lng: response.data.longitude,
        lat: response.data.latitude,
      });
    } catch (error) {
      setError("Something went wrong getting Geolocation from API!");
    }

    setLoading(false);
  }
  return { getLocation, location, loading, error };
}
