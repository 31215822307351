import { FCP_Payout } from "@/@types/billing";
import api from "@/services/api";
import { getLanguage } from "@/utils/language";
import { useQueryClient } from "@tanstack/react-query";

interface GetPayoutsRequest {
  paidOutAfter?: string;
  createdAfter?: string;
  page?: number;
  limit?: number;
}

type GetPayoutsResponse = {
  count: number;
  items: FCP_Payout[];
};

export const useLazyGetPayouts = () => {
  const queryClient = useQueryClient();
  return async (params: GetPayoutsRequest) => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyPayouts"],
      queryFn: async () => {
        const { data } = await api.get<GetPayoutsResponse>(
          `/v2/users/me/connect/payouts`,
          {
            params,
            locale: getLanguage(),
          }
        );

        return data;
      },
    });
  };
};
