import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export interface GoogleTranslateRequest {
  sourceLanguage: string;
  targetLanguage: string;
  text: string;
}

export interface GoogleTranslateResponse {
  data: {
    translations: { translatedText: string }[];
  };
}

export const useGoogleTranslateMutation = () =>
  useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: async ({
      sourceLanguage,
      targetLanguage,
      text,
    }: GoogleTranslateRequest) => {
      const { data } = await axios.post<GoogleTranslateResponse>(
        `https://translation.googleapis.com/language/translate/v2?key=${
          import.meta.env.VITE_GOOGLE_API_KEY
        }&q=${encodeURI(
          text
        )}&source=${sourceLanguage}&target=${targetLanguage}`
      );
      return data;
    },
  });
