import { FCP_Park } from "@/@types/park";
import { FCP_Reservation } from "@/@types/reservation";
import Badge from "@/components/Badge";
import Loader from "@/components/layout/Loader";
import { Button } from "@/components/ui/button";
import { useLazyGetParkReservations } from "@/hooks/query/useLazyGetParkReservations";
import { CPException } from "@/models/exceptions/CPException";
import { useGetParksQuery } from "@/services/park";
import { formatDate } from "@/utils/date";
import { formatAddress } from "@/utils/formatter";
import { getLanguage } from "@/utils/language";
import { ArrowSmallRightIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ExtendedPark extends Partial<FCP_Park> {
  ongoingReservations: FCP_Reservation[];
  upcomingReservations: FCP_Reservation[];
  fetchingReservations: boolean;
  fetchedReservations: boolean;
  open: boolean;
}

export default function Monitoring() {
  const navigate = useNavigate();
  const { t } = useTranslation(["dashboard"]);
  const { data: parks } = useGetParksQuery();
  const [extendedParks, setExtendedParks] = useState<ExtendedPark[]>([]);
  const fetchReservations = useLazyGetParkReservations();

  const dateFormat =
    getLanguage() === "en" ? "MMM do yyyy H:mm" : "d MMM yyyy H:mm";

  useEffect(() => {
    if (parks) {
      setExtendedParks(
        parks.map((park) => ({
          ...park,
          ongoingReservations: [],
          upcomingReservations: [],
          fetchingReservations: false,
          fetchedReservations: false,
          open: false,
        }))
      );
    }
  }, [parks]);

  async function handleToggle(park: ExtendedPark) {
    if (!park.objectId) return;

    if (park.fetchedReservations) {
      setExtendedParks((previousExtendedParks) =>
        previousExtendedParks.map((p) =>
          p.objectId === park.objectId
            ? {
                ...p,
                open: !p.open,
              }
            : p
        )
      );
    } else {
      setExtendedParks((previousExtendedParks) =>
        previousExtendedParks.map((p) =>
          p.objectId === park.objectId
            ? {
                ...p,
                open: !p.open,
                fetchingReservations: true,
              }
            : p
        )
      );

      let ongoing: FCP_Reservation[] = [];
      let upcoming: FCP_Reservation[] = [];

      try {
        const { items } = await fetchReservations({
          parkId: park.objectId,
          options: {
            endsAfter: new Date().toISOString(),
            startsBefore: new Date().toISOString(),
            statuses: "valid",
          },
        });

        ongoing = items;
      } catch (error) {
        if (error instanceof CPException) {
          // toast
        } else {
          // toast
        }
      }

      try {
        const { items } = await fetchReservations({
          parkId: park.objectId,
          options: {
            startsAfter: new Date().toISOString(),
            statuses: "valid",
          },
        });

        upcoming = items;
      } catch (error) {
        if (error instanceof CPException) {
          // toast
        } else {
          // toast
        }
      }

      setExtendedParks((previousExtendedParks) =>
        previousExtendedParks.map((p) =>
          p.objectId === park.objectId
            ? {
                ...p,
                fetchingReservations: false,
                fetchedReservations: true,
                ongoingReservations: ongoing,
                upcomingReservations: upcoming,
              }
            : p
        )
      );
    }
  }

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-simple-900">
        {t("dashboard:mySpots")}
      </h3>

      <ul role="list" className="mt-5 space-y-3">
        {extendedParks.map((park) => (
          <li
            key={park.objectId}
            className="overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5"
          >
            <div
              className="group flex cursor-pointer flex-wrap items-center"
              onClick={() => handleToggle(park)}
            >
              <div
                className="h-[225px] w-full shrink rounded-tl-lg rounded-tr-lg bg-silver-900 bg-cover bg-center sm:m-3 sm:h-[85px] sm:w-[85px] sm:rounded-lg"
                style={{
                  backgroundImage: `url(${park?.photos?.[0]?.files.thumbnail.path})`,
                }}
              />

              <div className="grow px-6 py-4">
                <div>
                  <strong className="text-md font-semibold transition-colors group-hover:text-rapide-600 sm:text-lg">
                    {park.address && formatAddress(park.address, "short")}
                  </strong>
                  <p className="sm:text-md mt-0.5 text-sm">
                    {park?.address?.state}, {park?.address?.country},{" "}
                    {park?.address?.zip}
                  </p>
                </div>
              </div>

              <button className="mr-5 sm:mr-10">
                <ChevronDownIcon
                  className={`w-[30px] transition-all group-hover:text-rapide-600 ${
                    park.open ? "rotate-180" : ""
                  }`}
                />
              </button>
            </div>

            {park.open && (
              <>
                <div className="grow border-t p-5">
                  {park.fetchingReservations ? (
                    <div className="relative h-[50px]">
                      <Loader />
                    </div>
                  ) : (
                    <div>
                      <div className="pb-3">
                        <strong className="mb-1 text-sm font-semibold">
                          {t("dashboard:ongoingReservations")}
                        </strong>
                        {park.ongoingReservations.length > 0 ? (
                          <ul>
                            {park.ongoingReservations.map((r) => (
                              <li
                                className="mb-2 flex items-center justify-between border-b pb-2 first:mt-2 last:mb-0 last:border-0 sm:justify-start"
                                key={"ongoingReservations" + r.objectId}
                              >
                                {r?.vehicle && (
                                  <div className="mr-2 flex">
                                    {r?.vehicle?.plate && (
                                      <div className="mr-2">
                                        <Badge variant="info">
                                          {r?.vehicle?.plate}
                                        </Badge>
                                      </div>
                                    )}

                                    {(r?.vehicle?.model?.model ||
                                      r?.vehicle?.model?.make) && (
                                      <Badge variant="info">
                                        {r?.vehicle?.model?.model}{" "}
                                        {r?.vehicle?.model?.make}
                                      </Badge>
                                    )}

                                    {r?.vehicle?.noModelString && (
                                      <Badge variant="info">
                                        {r.vehicle.noModelString}
                                      </Badge>
                                    )}
                                  </div>
                                )}

                                <div className="flex items-center text-sm font-semibold">
                                  {formatDate(
                                    new Date(r.startDate.iso),
                                    dateFormat
                                  )}
                                  <ArrowSmallRightIcon className="mx-2 w-[25px] text-rapide-600" />
                                  {formatDate(
                                    new Date(r.endDate.iso),
                                    dateFormat
                                  )}
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>{t("dashboard:noOngoingReservations")}</p>
                        )}
                      </div>

                      <div className="pt-2">
                        <strong className="mb-1 text-sm font-semibold">
                          {t("dashboard:upcomingReservations")}
                        </strong>
                        {park.upcomingReservations.length > 0 ? (
                          <ul>
                            {park.upcomingReservations.map((r) => (
                              <li
                                className="mb-2 flex items-center justify-between border-b pb-2 first:mt-2 last:mb-0 last:border-0 sm:justify-start"
                                key={"upcomingReservations" + r.objectId}
                              >
                                {r?.vehicle && (
                                  <div className="mr-2 flex">
                                    {r?.vehicle?.plate && (
                                      <div className="mr-2">
                                        <Badge variant="info">
                                          {r?.vehicle?.plate}
                                        </Badge>
                                      </div>
                                    )}

                                    {(r?.vehicle?.model?.model ||
                                      r?.vehicle?.model?.make) && (
                                      <Badge variant="info">
                                        {r?.vehicle?.model?.model}{" "}
                                        {r?.vehicle?.model?.make}
                                      </Badge>
                                    )}

                                    {r?.vehicle?.noModelString && (
                                      <Badge variant="info">
                                        {r.vehicle.noModelString}
                                      </Badge>
                                    )}
                                  </div>
                                )}

                                <div className="flex items-center text-sm font-semibold">
                                  {formatDate(
                                    new Date(r.startDate.iso),
                                    dateFormat
                                  )}
                                  <ArrowSmallRightIcon className="mx-2 w-[25px] text-rapide-600" />
                                  {formatDate(
                                    new Date(r.endDate.iso),
                                    dateFormat
                                  )}
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>{t("dashboard:noUpcomingReservations")}</p>
                        )}
                      </div>

                      <div className="mt-5">
                        <div>
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() =>
                              navigate(
                                `/reservations/?parkId=${park.objectId}&status=past&page=1&limit=25`
                              )
                            }
                          >
                            {t("dashboard:viewPastReservations")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
