interface Option {
  name: string;
  description?: string;
  image?: React.ReactNode;
  value: string;
}

interface Props {
  options: Option[];
  selectedValues: string[];
  onCheck: (option: Option) => void;
  onUncheck: (option: Option) => void;
}

export default function FieldCheckboxesCards({
  options,
  selectedValues,
  onCheck,
  onUncheck,
}: Props) {
  return (
    <fieldset className="border-b border-t border-gray-200">
      <div className="divide-y divide-gray-200">
        {options.map((option) => (
          <div
            className="relative flex items-center pb-3.5 pt-3.5"
            key={option.name}
          >
            {option.image && (
              <span className="mr-5 flex-shrink-0">{option.image}</span>
            )}

            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor={option.name} className="font-bold">
                {option.name}
              </label>

              {option.description && (
                <p className="text-gray-600">{option.description}</p>
              )}
            </div>

            <div className="ml-3 flex h-6 items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 text-rapide-600 focus:ring-rapide-600"
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    onCheck(option);
                  } else {
                    onUncheck(option);
                  }
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
