import FieldError from "@/components/form/fields/FieldError";
import FieldLabel from "@/components/form/fields/FieldLabel";
import { Button } from "@/components/ui/button";
import { Fragment, InputHTMLAttributes } from "react";
import { MdAdd, MdRemove } from "react-icons/md";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  min?: number;
  max?: number;
  increment?: number;
  incrementType?: "int" | "float";
  prefix?: string;
  suffix?: string;
  defaultValue?: string;
  error?: string;
  fieldValue?: string;
  onNumberChange: (value: string) => void;
}

function roundToDecimal(value: number, step: number): number {
  step || (step = 1.0);
  const inv = 1.0 / step;
  return Math.ceil(value * inv) / inv;
}

function normalizeNumber(
  value: number,
  incrementType: "int" | "float",
  increment: number
): number {
  if (incrementType === "int") {
    return value;
  }

  return parseFloat(
    roundToDecimal(parseFloat(value.toFixed(2)), increment).toFixed(2)
  );
}

function isValidNumber(value: string): boolean {
  return !isNaN(Number(value));
}

function convertToNumber(value: string): number {
  return Number(value);
}

function convertToString(
  value: number,
  incrementType: "int" | "float"
): string {
  if (incrementType === "int") {
    return value.toString();
  }

  return value.toFixed(2);
}

export default function FieldNumber({
  name,
  label,
  min = 1,
  max,
  increment = 1,
  incrementType = "int",
  prefix,
  suffix,
  defaultValue,
  error,
  fieldValue,
  onNumberChange,
  ...props
}: Props) {
  const normalClasses =
    "text-simple-900 ring-gray-300 placeholder:text-gray-400 focus:ring-rapide-600";

  const errorClasses =
    "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500";

  const disabledClasses =
    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-600 disabled:ring-gray-200";

  const sharedClasses = `px-3 rounded-md border-0 pt-3 pb-2 pr-5 pl-5 w-[75px] ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 text-center `;

  const className = sharedClasses.concat(
    [error ? errorClasses : normalClasses, disabledClasses].join(" ")
  );

  const buttonClassName = "rounded-full";

  return (
    <Fragment>
      {label && (
        <FieldLabel label={label} />
        // <label
        //   htmlFor={name}
        //   className="mb-3 block text-sm font-semibold leading-5 text-rapide-600"
        // >
        //   {label}
        // </label>
      )}

      <div className="flex items-center">
        <Button
          type="button"
          className={
            buttonClassName +
            ` mr-4 ${
              fieldValue &&
              isValidNumber(fieldValue) &&
              convertToNumber(fieldValue) <= min &&
              "opacity-50"
            }`
          }
          onClick={() => {
            if (!fieldValue || !isValidNumber(fieldValue)) {
              onNumberChange(convertToString(min, incrementType));
            } else {
              const value = normalizeNumber(
                convertToNumber(fieldValue),
                incrementType,
                increment
              );

              onNumberChange(
                convertToString(
                  value > min
                    ? normalizeNumber(
                        value - increment,
                        incrementType,
                        increment
                      )
                    : min,
                  incrementType
                )
              );
            }
          }}
        >
          <MdRemove />
        </Button>

        <div className={`relative rounded-md shadow-sm`}>
          {prefix && <div className="prefix">{prefix}</div>}

          <input
            name={name}
            id={name}
            defaultValue={defaultValue}
            className={className}
            value={fieldValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const nativeEvent = e.nativeEvent;
              // @ts-ignore: Can't find how to type this
              const inputType = nativeEvent.inputType;
              const inputValue: string = e.currentTarget.value;

              const numberOfDots = inputValue.match(/\./g) || [];

              if (
                inputType === "insertText" &&
                numberOfDots.length > 1 &&
                inputValue.endsWith(".")
              ) {
                onNumberChange(inputValue.slice(0, -1));
                return;
              }

              if (incrementType === "float" && inputValue.endsWith(".")) {
                onNumberChange(inputValue);
                return;
              }

              if (inputType === "deleteContentBackward") {
                onNumberChange(inputValue);
                return;
              } else if (inputType === "deleteContentForward") {
                onNumberChange(inputValue);
                return;
              } else if (inputValue.match(/^[0-9]*\.?[0-9]*$/)) {
                onNumberChange(
                  convertToString(
                    normalizeNumber(
                      parseFloat(inputValue),
                      incrementType,
                      increment
                    ),
                    incrementType
                  )
                );
              }

              if (parseFloat(inputValue) <= min) {
                onNumberChange(convertToString(min, incrementType));
              } else if (max && parseFloat(inputValue) >= max) {
                onNumberChange(convertToString(max, incrementType));
              }
            }}
            {...props}
          />

          {suffix && <div className="suffix">{suffix}</div>}
        </div>

        <Button
          type="button"
          className={`${buttonClassName} ml-4 ${
            fieldValue &&
            isValidNumber(fieldValue) &&
            convertToNumber(fieldValue) === max &&
            "opacity-50"
          }`}
          onClick={() => {
            if (!fieldValue || !isValidNumber(fieldValue)) {
              onNumberChange(convertToString(min, incrementType));
            } else {
              const value = normalizeNumber(
                convertToNumber(fieldValue),
                incrementType,
                increment
              );

              onNumberChange(
                convertToString(
                  (max && value < max) || !max
                    ? normalizeNumber(
                        value + increment,
                        incrementType,
                        increment
                      )
                    : max,
                  incrementType
                )
              );
            }
          }}
        >
          <MdAdd />
        </Button>
      </div>

      {error ? <FieldError error={error} /> : null}
    </Fragment>
  );
}
