import FieldSelect from "@/components/form/fields/FieldSelect";
import Loader from "@/components/layout/Loader";
import { Button } from "@/components/ui/button";
import {
  BillingReport,
  useDownloadBillinReportMutation,
  useGetBillingReportsQuery,
} from "@/services/user";
import { formatDate } from "@/utils/date";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormBillingReports() {
  const { t } = useTranslation(["financial", "common"]);

  const downloadBillingMutation = useDownloadBillinReportMutation();
  const getBillingReportsQuery = useGetBillingReportsQuery();
  const reports = getBillingReportsQuery.data?.data;

  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedReportId, setSelectedReportId] = useState<string>("_");

  function formatReportName(report: BillingReport) {
    return t("financial:reportOption", {
      dates: `${formatDate(new Date(report.periodStart), "dd MMM yyyy")} ${t(
        "financial:to"
      )} ${formatDate(new Date(report.periodEnd), "dd MMM yyyy")}`,
    });
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("financial:downloadReports")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("financial:downloadReportsDescription")}
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="sm:max-w-xl">
          {reports?.length === 0 ? (
            <p>{t("financial:noReports")}</p>
          ) : (
            <>
              <FieldSelect
                options={[
                  {
                    name: t("financial:selectReport"),
                    value: "_",
                  },
                  ...(reports
                    ?.sort(
                      (a, b) =>
                        new Date(b.periodStart).getTime() -
                        new Date(a.periodStart).getTime()
                    )
                    .map((report) => ({
                      name: formatReportName(report),
                      value: report.id,
                    })) || []),
                ]}
                onSelect={({ value }) => setSelectedReportId(value)}
                selectedOptionValue={selectedReportId}
              />

              <div className="my-4" />

              <Button
                onClick={async () => {
                  const report = reports?.find(
                    (r) => r.id === selectedReportId
                  );
                  if (report === undefined) return;
                  const filename = formatReportName(report) + ".pdf";
                  setDownloading(true);
                  await downloadBillingMutation.mutateAsync({
                    id: selectedReportId,
                    filename,
                  });
                  setDownloading(false);
                }}
                disabled={selectedReportId === "_" || downloading}
                loading={downloading}
              >
                {t("financial:downloadReport")}
              </Button>
            </>
          )}
        </div>
      </div>

      {getBillingReportsQuery.isFetching ? <Loader /> : null}
    </div>
  );
}
