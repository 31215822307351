interface Props {
  children: React.ReactNode;
}

export default function Wrapper({ children }: Props) {
  return (
    <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-5xl">{children}</div>
    </div>
  );
}
