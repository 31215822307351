import { ParkAddContext } from "@/contexts/ParkAddContext";
import { useContext } from "react";

export default function useParkAdd() {
  const {
    step,
    loading,
    canGoNext,
    canGoBack,
    onboardingComplete,
    next,
    back,
    goTo,
    getPark,
    createPark,
    updatePark,
    exit,
  } = useContext(ParkAddContext);

  return {
    step,
    loading,
    canGoNext,
    canGoBack,
    onboardingComplete,
    next,
    back,
    goTo,
    getPark,
    createPark,
    updatePark,
    exit,
  };
}
