import PlateCheckerRequestContent from "@/containers/plate-checker/components/request/request-content";
import PlateCheckerRequestHeader from "@/containers/plate-checker/components/request/request-header";
import PlateCheckerRequestReport from "@/containers/plate-checker/components/request/request-report";
import { PlateCheckerRequestContext } from "@/containers/plate-checker/contexts/plate-checker-request.context";
import { PlateCheckerTypes } from "@/containers/plate-checker/types/plate-checker.types";

import { useReducer } from "react";

interface Props extends PlateCheckerTypes.Request {
  parkId: string;
}

export default function PlateCheckerRequest({ parkId, ...request }: Props) {
  const [isContentVisible, toggleContentVisibility] = useReducer(
    (state) => !state,
    false
  );

  return (
    <PlateCheckerRequestContext.Provider
      value={{
        parkId,
        request,
        isContentVisible,
        toggleContentVisibility,
      }}
    >
      <PlateCheckerRequestHeader />
      <PlateCheckerRequestContent />
      <PlateCheckerRequestReport />
    </PlateCheckerRequestContext.Provider>
  );
}
