import {
  OwnerNotifications,
  OwnerNotificationsRecipient,
} from "@/@types/owner";
import { useMutation, useQuery } from "@tanstack/react-query";
import api from "./api";

export type OwnerOnboardingStep =
  | "started"
  | "link-bank-account"
  | "kyc-started"
  | "kyc-completed"
  | "completed";
export interface OwnerOnboardingRequest {
  step: OwnerOnboardingStep;
}

export interface OwnerBusinessTypeUpdateRequest {
  businessType: "company" | "individual";
}

export const useUpdateOnboardingStepMutation = () =>
  useMutation({
    mutationFn: async (step: OwnerOnboardingStep) => {
      const { data } = await api.put<OwnerOnboardingRequest>(
        "/v2/owners/me/onboarding",
        { step: step }
      );
      return data;
    },
  });

export const useSetBusinessTypeMutation = () => {
  return useMutation({
    mutationKey: ["onboarding"],
    mutationFn: async (businessType: "company" | "individual") => {
      const { data } = await api.put<OwnerBusinessTypeUpdateRequest>(
        "/v2/owners/me/businessType",
        { businessType: businessType }
      );
      return data;
    },
  });
};

export const useUpdateOwnerNotifications = () => {
  return useMutation({
    mutationKey: ["updateUserNotifications"],
    mutationFn: async (notifications: OwnerNotifications) => {
      const { data } = await api.put<OwnerNotifications>(
        `/v2/owners/me/notifications`,
        notifications
      );
      return data;
    },
  });
};

export const useGetOwnerNotifications = () => {
  return useQuery({
    queryKey: ["ownerNotifications"],
    queryFn: async () => {
      const { data } = await api.get<OwnerNotifications>(
        `/v2/owners/me/notifications`
      );
      return data;
    },
  });
};

export const useGetOwnerNotificationsRecipients = () => {
  return useQuery({
    queryKey: ["ownerNotificationsRecipients"],
    queryFn: async () => {
      const { data } = await api.get<OwnerNotificationsRecipient[]>(
        `/v2/owners/me/recipients`
      );
      return data;
    },
  });
};

export const useUpdateOwnerNotificationsRecipients = () => {
  return useMutation({
    mutationKey: ["updateOwnerNotificationsRecipients"],
    mutationFn: async (recipients: OwnerNotificationsRecipient[]) => {
      const { data } = await api.put<OwnerNotificationsRecipient[]>(
        `/v2/owners/me/recipients`,
        recipients
      );
      return data;
    },
  });
};