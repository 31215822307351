import { HTMLAttributes } from "react";

interface FieldLabelProps extends HTMLAttributes<HTMLLabelElement> {
  label: string;
  htmlFor?: string;
  className?: string;
}

export default function FieldLabel({
  label,
  htmlFor,
  ...rest
}: FieldLabelProps) {
  return (
    <label
      htmlFor={htmlFor || ""}
      className="block text-sm font-semibold leading-5 text-simple-900"
      {...rest}
    >
      {label}
    </label>
  );
}
