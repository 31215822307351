import { Place } from "@/@types/park";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import { CPException } from "@/models/exceptions/CPException";
import { getCurrentPark, getParkPlace, removeParkPlace } from "@/utils/storage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export default function ParkAddAddressConfirmation() {
  const { t } = useTranslation(["park", "validation"]);
  const navigate = useNavigate();

  const [formError, setFormError] = useState<string | undefined>();
  const [formAction, setFormAction] = useState<"next" | "exit">("next");
  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();
  const { next, back, exit, createPark, updatePark } = useParkAdd();

  useEffect(() => {
    const park = getCurrentPark();
    const place = getParkPlace();

    if (place) {
      setSelectedPlace(place);
    } else if (park?.address && park?.location) {
      setSelectedPlace({
        streetNumber: park.address.streetNumber,
        street: park.address.street,
        city: park.address.city,
        state: park.address.state,
        country: park.address.country,
        zip: park.address.zip,
        coordinates: park.location,
      });
    } else {
      navigate("/parks/add/1");
    }

    return;
  }, []);

  const initialValues = {
    streetNumber: selectedPlace?.streetNumber || "",
    street: selectedPlace?.street || "",
    city: selectedPlace?.city || "",
    state: selectedPlace?.state || "",
    country: selectedPlace?.country || "",
    zip: selectedPlace?.zip || "",
  };

  const validationSchema = Yup.object().shape({
    streetNumber: Yup.string().required(t("validation:streetNumberRequired")),
    street: Yup.string().required(t("validation:streetRequired")),
    city: Yup.string().required(t("validation:cityRequired")),
    state: Yup.string().required(t("validation:stateRequired")),
    country: Yup.string().required(t("validation:countryRequired")),
    zip: Yup.string().required(t("validation:zipRequired")),
  });

  const {
    submitForm,
    handleChange,
    handleBlur,
    values,
    isValid,
    errors,
    touched,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const park = getCurrentPark();

      setFormError(undefined);

      if (!selectedPlace) {
        setFormError("No address selected");
        return;
      }

      try {
        if (!park?.objectId) {
          await createPark(values, selectedPlace.coordinates);
        } else {
          await updatePark({
            address: values,
            location: selectedPlace.coordinates,
          });
        }

        removeParkPlace();

        formAction === "next" ? next() : exit();
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  function onBack() {
    back();
  }

  function onNext() {
    setFormAction("next");
    submitForm();
  }

  function onSaveAndExit() {
    setFormAction("exit");
    submitForm();
  }

  return (
    <ParkAddShell
      loading={isSubmitting}
      displayFooter={selectedPlace !== undefined}
      actions={{
        saveAndExit: {
          visible: true,
          disabled:
            selectedPlace === undefined || (!isValid && dirty) || isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: (!isValid && dirty) || isSubmitting,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      <FormHeading
        title={t("park:confirmAddress")}
        subtitle={t("park:modifyMissingOrWrongAddressData")}
      />

      {formError && <FormError title={formError} />}

      <FormFields
        fields={[
          <FormField size="3-6" key="streetNumber">
            <FieldText
              id="streetNumber"
              name="streetNumber"
              label={t("park:streetNumber")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.streetNumber}
              error={(touched.streetNumber && errors.streetNumber) || undefined}
            />
          </FormField>,
          <FormField size="3-6" key="street">
            <FieldText
              id="street"
              name="street"
              label={t("park:street")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.street}
              error={(touched.street && errors.street) || undefined}
            />
          </FormField>,
          <FormField key="city">
            <FieldText
              id="city"
              name="city"
              label={t("park:city")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={(touched.city && errors.city) || undefined}
            />
          </FormField>,
          <FormField key="state">
            <FieldText
              id="state"
              name="state"
              label={t("park:state")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={(touched.state && errors.state) || undefined}
            />
          </FormField>,
          <FormField size="4-6" key="country">
            <FieldText
              id="country"
              name="country"
              label={t("park:country")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={(touched.country && errors.country) || undefined}
            />
          </FormField>,
          <FormField size="2-6" key="zip">
            <FieldText
              id="zip"
              name="zip"
              label={t("park:zip")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zip}
              error={(touched.zip && errors.zip) || undefined}
            />
          </FormField>,
        ]}
      />
    </ParkAddShell>
  );
}
