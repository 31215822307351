import React, { createContext } from "react";

export type NotificationContextProps = {};

const NotificationContext = createContext<NotificationContextProps>(null!);

interface Props {
  children: React.ReactNode;
}

const NotificationProvider = ({ children }: Props) => {
  return (
    <NotificationContext.Provider value={{}}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider };
export default NotificationContext;
