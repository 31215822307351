import { cookieOptions } from "@/config/cookie.config";
import keys from "@/config/keys";
import { resetHttpAuthToken } from "@/services/api";
import {
  clearStorage,
  removeBifrostEmail,
  removeBifrostSkipOnboarding,
} from "@/utils/storage";
import { StorageUtils } from "@/utils/storage.utils";
import { useQueryClient } from "@tanstack/react-query";
import mixpanel from "mixpanel-browser";
import { useCookies } from "react-cookie";

export default function useOnLogout() {
  const queryClient = useQueryClient();
  const [_, __, removeCookie] = useCookies([keys.authToken, keys.bifrostEmail]);

  return () => {
    StorageUtils.clear();
    resetHttpAuthToken();
    removeCookie(keys.authToken, cookieOptions);
    removeCookie(keys.bifrostEmail, cookieOptions);
    clearStorage();
    removeBifrostEmail();
    removeBifrostSkipOnboarding();
    queryClient.clear();
    mixpanel?.reset();
    window.location.href = "/";
  };
}
