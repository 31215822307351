import { FCP_VehicleSize } from "@/@types/park";
import FieldRadiosCards from "@/components/form/fields/FieldRadiosCards";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormParkVehicleSize() {
  const { park } = useParkEdit();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();

  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const initialValues: {
    maxVehicleSize: FCP_VehicleSize;
  } = {
    maxVehicleSize: park?.maxVehicleSize || "small",
  };

  const { values, submitForm, isSubmitting, setFieldValue, isValid } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        if (!park) return;

        setFormError(undefined);
        setFormSuccess(false);

        try {
          await updateParkAsync({
            parkId: park.objectId,
            updates: { maxVehicleSize: values.maxVehicleSize },
          });

          setFormSuccess(true);
          setFormError(undefined);

          setTimeout(() => {
            setFormSuccess(false);
          }, 5000);
        } catch (error) {
          if (error instanceof CPException) {
            setFormError(error.message);
          } else {
            setFormError(t("validation:genericError"));
          }
        }
      },
    });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:maxVehicleSize")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:editMaxVehicleSizeDescription")}
        </p>
      </div>

      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField>
                <FieldRadiosCards
                  onSelect={(option) =>
                    setFieldValue("maxVehicleSize", option.value)
                  }
                  selectedOptionValue={values.maxVehicleSize}
                  options={[
                    {
                      name: t("park:small"),
                      description: t("park:smallExample"),
                      value: "small",
                      image: (
                        <img
                          src="/images/vehicles/small/small.png"
                          width={75}
                          className="mt-2"
                        />
                      ),
                    },
                    {
                      name: t("park:medium"),
                      description: t("park:mediumExample"),
                      value: "medium",
                      image: (
                        <img
                          src="/images/vehicles/small/medium.png"
                          width={92}
                          className="mt-2"
                        />
                      ),
                    },
                    {
                      name: t("park:suv"),
                      description: t("park:suvExample"),
                      value: "suv",
                      image: (
                        <img
                          src="/images/vehicles/small/suv.png"
                          width={85}
                          className="mt-2"
                        />
                      ),
                    },
                    {
                      name: t("park:van"),
                      description: t("park:vanExample"),
                      value: "van",
                      image: (
                        <img
                          src="/images/vehicles/small/large.png"
                          width={95}
                          className="mt-1"
                        />
                      ),
                    },
                  ]}
                />
              </FormField>,
            ]}
          />
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>
    </div>
  );
}
