import AlertSimple from "@/components/alert/AlertSimple";
import FieldRadiosGroup from "@/components/form/fields/FieldRadiosGroup";
import FormError from "@/components/form/layout/FormError";
import FormSuccess from "@/components/form/layout/FormSuccess";
import Loader from "@/components/layout/Loader";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import {
  useAddExceptionMutation,
  useDeleteExceptionMutation,
  useGetExceptionsQuery,
  useUpdateParkMutation,
} from "@/services/park";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormParkClearOfSnow() {
  const { park } = useParkEdit();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { data: exceptionAndConflicts, isLoading: loadingExceptions } =
    useGetExceptionsQuery({ parkId: park?.objectId });
  const { mutateAsync: addExceptionAsync } = useAddExceptionMutation();
  const { mutateAsync: deleteExceptionAsync } = useDeleteExceptionMutation();
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();

  const [clearedOfSnow, setClearedOfSnow] = useState<string>("true");
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setClearedOfSnow(park?.metadata?.clearedOfSnow?.value ? "true" : "false");
  }, [park]);

  async function updateClearOfSnow() {
    if (!park) return;

    setLoading(true);
    setError(undefined);
    setSuccess(false);

    if (clearedOfSnow === "true") {
      const clearOfSnowExceptions = exceptionAndConflicts
        ? exceptionAndConflicts.filter(({ exception }) => {
            return exception.localizedLabel?.default === "spaceNotClearOfSnow";
          })
        : [];

      if (clearOfSnowExceptions.length > 0) {
        try {
          for (const clearOfSnowException of clearOfSnowExceptions) {
            await deleteExceptionAsync({
              parkId: park.objectId,
              exceptionId: clearOfSnowException.exception.objectId,
            });
          }

          queryClient.invalidateQueries({ queryKey: ["exceptions"] });
        } catch (error) {
          if (error instanceof CPException) {
            setError(error.message);
          } else {
            setError(t("validation:genericError"));
          }
        }
      }
    } else {
      // Create exception from 2025-01-15 to 2025-04-15 if space ain't clear of snow

      try {
        const start = new Date(`2025-01-15`);
        start.setHours(0, 0, 0, 0);

        const end = new Date(`2025-04-15`);
        end.setHours(23, 59, 59, 0);

        await addExceptionAsync({
          parkId: park.objectId,
          start,
          end,
          localizedLabel: [
            { languageCode: "fr", localizedString: "spaceNotClearOfSnow" },
            { languageCode: "en", localizedString: "spaceNotClearOfSnow" },
            { languageCode: "default", localizedString: "spaceNotClearOfSnow" },
          ],
          editable: false,
        });
      } catch (error) {
        if (error instanceof CPException) {
          setError(error.message);
        } else {
          setError(t("validation:genericError"));
        }
      }
    }

    try {
      await updateParkAsync({
        parkId: park.objectId,
        updates: {
          metadata: {
            ...(park?.metadata ? park.metadata : {}),
            clearedOfSnow: {
              value: clearedOfSnow === "true",
              type: "boolean",
              label: "Espace déneigé",
            },
          },
        },
      });

      queryClient.invalidateQueries({ queryKey: ["park" + park?.objectId] });

      setSuccess(true);
      setError(undefined);

      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setLoading(false);
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:clearedOfSnow")}
        </h2>
      </div>

      <div className="md:col-span-2">
        <div className="sm:max-w-xl">
          {error && (
            <div className="mb-5">
              <FormError title={error} />
            </div>
          )}

          <FieldRadiosGroup
            selectedOptionValue={clearedOfSnow}
            onSelect={({ value }) => setClearedOfSnow(value)}
            options={[
              {
                name: t("common:yes"),
                value: "true",
              },
              {
                name: t("common:no"),
                value: "false",
              },
            ]}
          />

          {clearedOfSnow === "false" && (
            <div className="mt-3">
              <AlertSimple title={t("park:parkSnowNotClearedTitle")} />
            </div>
          )}

          <div className="mt-5 flex items-center">
            <Button
              onClick={updateClearOfSnow}
              loading={loading}
              disabled={loading}
            >
              {t("common:save")}
            </Button>

            <FormSuccess visible={success} />
          </div>
        </div>
      </div>

      {loadingExceptions ? <Loader /> : null}
    </div>
  );
}
