import { FCP_User } from "@/@types/user";
import api from "@/services/api";
import { useQueryClient } from "@tanstack/react-query";

type GetUserResponse = Partial<FCP_User>;

export const useLazyGetUser = () => {
  const queryClient = useQueryClient();
  return async () => {
    return await queryClient.fetchQuery({
      cacheTime: 1,
      queryKey: ["lazyMe"],
      queryFn: async () => {
        const { data } = await api.get<GetUserResponse>(`/v2/users/me`);
        return data;
      },
    });
  };
};
