import FormError from "@/components/form/layout/FormError";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormParkVisibility() {
  const { park } = useParkEdit();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  async function updateVisibility() {
    if (!park) return;

    setLoading(true);

    try {
      await updateParkAsync({
        parkId: park.objectId,
        updates: {
          isInvisible: !park.isInvisible,
        },
      });

      queryClient.invalidateQueries({ queryKey: ["park" + park?.objectId] });
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setLoading(false);
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:visibility")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:editVisibilityDescription")}
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="sm:max-w-xl">
          {error && (
            <div className="mb-5">
              <FormError title={error} />
            </div>
          )}

          <div className="border border-silver-800 bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="sm:flex sm:items-center sm:justify-between">
                <div>
                  <h3 className="text-base font-semibold leading-6 text-simple-900">
                    {!park?.isInvisible
                      ? t("park:spaceIsVisible")
                      : t("park:spaceIsInvisible")}
                  </h3>
                </div>
                <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <Button
                    onClick={updateVisibility}
                    loading={loading}
                    disabled={loading}
                  >
                    {park?.isInvisible
                      ? t("park:makeVisible")
                      : t("park:makeInvisible")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
