import { FCP_Vehicle } from "@/@types/user";
import { FCP_VehicleModel } from "@/@types/vehicle";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import Spacer from "@/components/Spacer";
import { Button } from "@/components/ui/button";
import useParkAdd from "@/hooks/context/useParkAdd";
import { useLazyFindVehicleModels } from "@/hooks/query/useLazyFindVehicleModels";
import { CPException } from "@/models/exceptions/CPException";
import {
  useAddVehicleToWhitelistMutation,
  useDeleteVehicleMutation,
  useGetVehiclesQuery,
} from "@/services/user";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

interface ExtendedVehicle extends FCP_Vehicle {
  deleting: boolean;
}

export default function ParkAddVehiclesWhitelist() {
  const queryClient = useQueryClient();
  const { t } = useTranslation([
    "account",
    "park",
    "validation",
    "dates",
    "common",
  ]);
  const [query, setQuery] = useState<string>("");
  const [querying, setQuerying] = useState(false);
  const [hasNoResults, setHasNoResults] = useState<boolean | undefined>();
  const [vehiclePlate, setVehiclePlate] = useState<string>("");
  const [vehicleModel, setVehicleModel] = useState<
    FCP_VehicleModel | undefined
  >();
  const [vehicleModelString, setVehicleModeString] = useState<
    string | undefined
  >();
  const [foundVehicleModels, setFoundVehicleModels] = useState<
    FCP_VehicleModel[]
  >([]);
  const [timeoutPointer, setTimeoutPointer] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [addingVehicle, setAddingVehicle] = useState(false);
  const [vehicles, setVehicles] = useState<ExtendedVehicle[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [showForm, setShowForm] = useState(false);

  const { back, exit, next } = useParkAdd();

  const findVehicleModels = useLazyFindVehicleModels();
  const { mutateAsync: addVehicleAsync } = useAddVehicleToWhitelistMutation();
  const { mutateAsync: deleteAsync } = useDeleteVehicleMutation();
  const { data: fetchedVehicles, isLoading: isLoadingVehicles } =
    useGetVehiclesQuery();

  useEffect(() => {
    setVehicles(fetchedVehicles?.map((v) => ({ ...v, deleting: false })) || []);
  }, [fetchedVehicles]);

  useEffect(() => {
    if (query.length >= 3) {
      if (timeoutPointer) clearTimeout(timeoutPointer);
      setTimeoutPointer(
        setTimeout(async () => {
          setQuerying(true);
          const results = await findVehicleModels({ modelName: query });

          if (results) {
            setFoundVehicleModels(results);
            setHasNoResults(results.length === 0);
            setQuerying(false);
          }
        }, 400)
      );
    } else {
      setFoundVehicleModels([]);
      setHasNoResults(undefined);
      setQuerying(false);
    }

    return () => {
      if (timeoutPointer) {
        clearTimeout(timeoutPointer);
        setTimeoutPointer(null);
      }

      setFoundVehicleModels([]);
      setHasNoResults(undefined);
      setQuerying(false);
    };
  }, [query]);

  async function deleteVehicle(id: string) {
    setVehicles((prev) =>
      prev.map((v) => {
        if (v.id === id) {
          return { ...v, deleting: true };
        } else {
          return v;
        }
      })
    );

    try {
      await deleteAsync({ id });
      queryClient.invalidateQueries({ queryKey: ["vehiclesWhitelist"] });
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }

      setVehicles((prev) =>
        prev.map((v) => {
          if (v.id === id) {
            return { ...v, deleting: false };
          } else {
            return v;
          }
        })
      );
    }
  }

  async function addVehicle() {
    setError(undefined);
    setAddingVehicle(true);

    try {
      await addVehicleAsync({
        plate: vehiclePlate,
        modelId: vehicleModel?.vehicleModelId,
        noModelString: vehicleModelString,
      });
      queryClient.invalidateQueries({ queryKey: ["vehiclesWhitelist"] });
      setFoundVehicleModels([]);
      setHasNoResults(undefined);
      setQuerying(false);
      setQuery("");
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setShowForm(false);
    setAddingVehicle(false);
  }

  function onBack() {
    back();
  }

  async function onNext() {
    next();
  }

  function onSaveAndExit() {
    exit();
  }

  return (
    <ParkAddShell
      loading={isLoadingVehicles || addingVehicle}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: addingVehicle,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: addingVehicle,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: addingVehicle,
          onClick: onBack,
        },
      }}
    >
      {error && <FormError title={error} />}

      <div className="relative">
        {error && (
          <div className="mb-5">
            <FormError title={error} />
          </div>
        )}

        <FormFields
          fields={[
            <FormField key="header">
              <FormHeading title={t("park:addWhitelistVehicles")} />
              <p className="text-sm">
                {t("park:addWhitelistVehiclesDescription")}
              </p>
              <p className="text-rapide-600 mt-5 text-sm font-bold">
                {t("park:youCanSkipThisStepAndAddVehiclesLater")}
              </p>
            </FormField>,
            <FormField key="picker">
              {vehicles.length > 0 ? (
                <ul className="list-inside list-disc">
                  {vehicles.map((vehicle, index) => {
                    return (
                      <li
                        key={index}
                        className="my-3 flex items-center justify-between rounded-lg border border-silver-800 bg-white py-3 pl-3 pr-2 text-sm font-bold shadow-sm md:pl-5 md:pr-2"
                      >
                        <div>
                          {vehicle?.noModelString
                            ? vehicle.noModelString
                            : `${vehicle.make} ${vehicle.model}`}
                        </div>

                        <Button
                          variant="subtle"
                          color="danger"
                          size="sm"
                          onClick={() => deleteVehicle(vehicle.id)}
                          loading={vehicle.deleting}
                          className="pl-2 pr-2"
                        >
                          <MdClose size={18} className="text-red-500" />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <>
                  <Spacer />
                  <p>{t("account:noVehicleWhitelist")}</p>
                </>
              )}

              {showForm ? (
                <div className="mt-4 rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5">
                  <div className="px-4 py-5 sm:p-6">
                    <FormFields
                      gapY="md"
                      fields={[
                        <FormField key="vehicleModel">
                          <FieldText
                            name="vehicleModel"
                            label={t("account:vehicleModel")}
                            onChange={(e) => setQuery(e.target.value)}
                            value={
                              vehicleModel
                                ? `${vehicleModel.make} ${vehicleModel?.model}`
                                : query
                            }
                            disabled={!!vehicleModel}
                            TrailingIcon={
                              vehicleModel ? (
                                <MdClose
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setVehicleModel(undefined);
                                    setVehicleModeString(undefined);
                                  }}
                                />
                              ) : undefined
                            }
                          />

                          {!vehicleModel && query && !querying && (
                            <>
                              {hasNoResults === true && (
                                <div
                                  className="mt-2 max-h-[300px] overflow-auto rounded-md border shadow-lg"
                                  onClick={() => {
                                    setVehicleModeString(query);
                                    setFoundVehicleModels([]);
                                    setHasNoResults(undefined);
                                    setQuerying(false);
                                  }}
                                >
                                  <div className="flex cursor-pointer items-center rounded-tl-md rounded-tr-md border-b p-3 text-sm duration-100 ease-in-out hover:bg-silver-900">
                                    <span>{query}</span>
                                  </div>
                                </div>
                              )}

                              {hasNoResults === false && (
                                <ul className="mt-2 max-h-[300px] overflow-auto rounded-md border shadow-lg">
                                  {foundVehicleModels.map(
                                    (foundVehicleModel) => {
                                      return (
                                        <li
                                          key={foundVehicleModel.vehicleModelId}
                                          className="flex cursor-pointer items-center rounded-tl-md rounded-tr-md border-b p-3 text-sm duration-100 ease-in-out hover:bg-silver-900"
                                          onClick={() => {
                                            setVehicleModel(foundVehicleModel);
                                            setFoundVehicleModels([]);
                                            setHasNoResults(undefined);
                                            setQuerying(false);
                                          }}
                                        >
                                          <img
                                            src="/images/onboarding/icon-vehicle.svg"
                                            className="mr-3"
                                          />
                                          <span>
                                            {foundVehicleModel.make}{" "}
                                            <span>
                                              {foundVehicleModel.model}
                                            </span>
                                          </span>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              )}
                            </>
                          )}
                        </FormField>,
                        <FormField key="vehiclePlate">
                          <FieldText
                            name="vehiclePlate"
                            label={t("account:vehiclePlate")}
                            value={vehiclePlate}
                            onChange={(e) => setVehiclePlate(e.target.value)}
                          />
                        </FormField>,
                      ]}
                    />

                    <div className="mt-4">
                      <Button
                        onClick={() => addVehicle()}
                        disabled={
                          (!vehicleModel && !vehicleModelString) ||
                          !vehiclePlate
                        }
                      >
                        {t("common:add")}
                      </Button>

                      <Button
                        className="ml-2"
                        variant="subtle"
                        onClick={() => setShowForm(false)}
                      >
                        {t("common:cancel")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-4">
                  <Button onClick={() => setShowForm(true)}>
                    {t("account:addVehicleToWhitelist")}
                  </Button>
                </div>
              )}
            </FormField>,
          ]}
        />
      </div>
    </ParkAddShell>
  );
}
