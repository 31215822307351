import keys from "@/config/keys";
import ParkAddProvider from "@/contexts/ParkAddContext";
import { ParkEditProvider } from "@/contexts/ParkEditContext";
import { useLazyGetUser } from "@/hooks/query/useLazyGetUser";
import EmailVerification from "@/routes/EmailVerification";
import Error from "@/routes/Error";
import GateAuth from "@/routes/GateAuth";
import GateOnboarding from "@/routes/GateOnboarding";
import Home from "@/routes/Home";
import PlateChecker from "@/routes/PlateChecker";
import RedirectBifrost from "@/routes/RedirectBifrost";
import RedirectHolidays from "@/routes/RedirectHolidays";
import Root from "@/routes/Root";
import Account from "@/routes/account/Account";
import AccountPayment from "@/routes/account/Payment";
import AccountVehiclesWhitelist from "@/routes/account/VehiclesWhitelist";
import BillingReports from "@/routes/billing/BillingReports";
import FaqKyc from "@/routes/help/FaqKyc";
import FaqPricing from "@/routes/help/FaqPricing";
import FaqsList from "@/routes/help/FaqsList";
import KycEnd from "@/routes/onboarding/KycEnd";
import KycStart from "@/routes/onboarding/KycStart";
import LinkBankAccount from "@/routes/onboarding/LinkBankAccount";
import OnboardingEnd from "@/routes/onboarding/OnboardingEnd";
import OnboardingResume from "@/routes/onboarding/OnboardingResume";
import OnboardingStart from "@/routes/onboarding/OnboardingStart";
import ParkSubmitted from "@/routes/onboarding/ParkSubmitted";
import ParkEdit from "@/routes/parks/ParkEdit";
import ParkEditDelete from "@/routes/parks/ParkEditDelete";
import ParkEditPhotos from "@/routes/parks/ParkEditPhotos";
import ParkEditSchedule from "@/routes/parks/ParkEditShedule";
import ParksList from "@/routes/parks/ParksList";
import ParkAdd from "@/routes/parks/add/ParkAdd";
import ParkAddAddress from "@/routes/parks/add/ParkAddAddress";
import ParkAddAddressValidation from "@/routes/parks/add/ParkAddAddressConfirmation";
import ParkAddDirections from "@/routes/parks/add/ParkAddDirections";
import ParkAddFeatures from "@/routes/parks/add/ParkAddFeatures";
import { default as ParkAddLocation } from "@/routes/parks/add/ParkAddLocation";
import { default as ParkAddPhotos } from "@/routes/parks/add/ParkAddPhotos";
import { default as ParkAddPricing } from "@/routes/parks/add/ParkAddPricing";
import ParkAddReview from "@/routes/parks/add/ParkAddReview";
import { default as ParkAddSchedule } from "@/routes/parks/add/ParkAddSchedule";
import { default as ParkAddScheduleExceptions } from "@/routes/parks/add/ParkAddScheduleExceptions";
import ParkAddVehicleSize from "@/routes/parks/add/ParkAddVehicleSize";
import ParkAddVehiclesWhitelist from "@/routes/parks/add/ParkAddVehiclesWhitelist";
import ReservationsList from "@/routes/reservations/ReservationsList";
import {
  configureSentry,
  setLoggerUserContext,
} from "@/services/sentry.services";
import * as tracker from "@/utils/tracker";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { Fragment } from "react";
import { useCookies } from "react-cookie";
import { Toaster } from "react-hot-toast";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useEffectOnce } from "react-use";
import AccountPreferences from "./routes/account/Preferences";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: (
          <GateAuth>
            <GateOnboarding>
              <Home />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/plate-checker",
        element: (
          <GateAuth>
            <GateOnboarding>
              <PlateChecker />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/reservations",
        element: (
          <GateAuth>
            <GateOnboarding>
              <ReservationsList />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/parks",
        element: (
          <GateAuth>
            <GateOnboarding>
              <ParksList />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/parks/:id",
        element: (
          <GateAuth>
            <GateOnboarding>
              <ParkEditProvider>
                <ParkEdit />
              </ParkEditProvider>
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/parks/:id/photos",
        element: (
          <GateAuth>
            <GateOnboarding>
              <ParkEditProvider>
                <ParkEditPhotos />
              </ParkEditProvider>
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/parks/:id/schedule",
        element: (
          <GateAuth>
            <GateOnboarding>
              <ParkEditProvider>
                <ParkEditSchedule />
              </ParkEditProvider>
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/parks/:id/delete",
        element: (
          <GateAuth>
            <GateOnboarding>
              <ParkEditProvider>
                <ParkEditDelete />
              </ParkEditProvider>
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/account",
        element: (
          <GateAuth>
            <GateOnboarding>
              <Account />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/account/vehicles-whitelist",
        element: (
          <GateAuth>
            <GateOnboarding>
              <AccountVehiclesWhitelist />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/account/preferences",
        element: (
          <GateAuth>
            <GateOnboarding>
              <AccountPreferences />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/billing/reports",
        element: (
          <GateAuth>
            <GateOnboarding>
              <BillingReports />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/payment",
        element: (
          <GateAuth>
            <GateOnboarding>
              <AccountPayment />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/help/pricing",
        element: (
          <GateAuth>
            <GateOnboarding>
              <FaqPricing />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/help/kyc",
        element: (
          <GateAuth>
            <GateOnboarding>
              <FaqKyc />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/help",
        element: (
          <GateAuth>
            <GateOnboarding>
              <FaqsList />
            </GateOnboarding>
          </GateAuth>
        ),
      },
      {
        path: "/onboarding",
        element: (
          <GateAuth>
            <OnboardingResume />
          </GateAuth>
        ),
        errorElement: <Error />,
      },
      {
        path: "/onboarding/start",
        element: <OnboardingStart />,
        errorElement: <Error />,
      },
      {
        path: "/onboarding/kyc/start",
        element: (
          <GateAuth>
            <KycStart />
          </GateAuth>
        ),
        errorElement: <Error />,
      },
      {
        path: "/onboarding/kyc/end",
        element: (
          <GateAuth>
            <KycEnd />
          </GateAuth>
        ),
        errorElement: <Error />,
      },
      {
        path: "/onboarding/link-bank-account",
        element: (
          <GateAuth>
            <LinkBankAccount />
          </GateAuth>
        ),
        errorElement: <Error />,
      },
      {
        path: "/onboarding/end",
        element: (
          <GateAuth>
            <OnboardingEnd />
          </GateAuth>
        ),
        errorElement: <Error />,
      },
      {
        path: "/parks/add",
        element: (
          <GateAuth>
            <ParkAddProvider>
              <ParkAdd />
            </ParkAddProvider>
          </GateAuth>
        ),
        errorElement: <Error />,
        children: [
          {
            path: "/parks/add/1",
            element: <ParkAddAddress />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/2",
            element: <ParkAddAddressValidation />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/3",
            element: <ParkAddLocation />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/4",
            element: <ParkAddDirections />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/5",
            element: <ParkAddVehicleSize />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/6",
            element: <ParkAddFeatures />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/7",
            element: <ParkAddPhotos />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/8",
            element: <ParkAddSchedule />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/9",
            element: <ParkAddScheduleExceptions />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/10",
            element: <ParkAddPricing />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/11",
            element: <ParkAddVehiclesWhitelist />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/12",
            element: <ParkAddReview />,
            errorElement: <Error />,
          },
          {
            path: "/parks/add/submitted",
            element: <ParkSubmitted />,
            errorElement: <Error />,
          },
        ],
      },
      {
        path: "/holidays",
        element: <RedirectHolidays />,
        errorElement: <Error />,
      },
      {
        path: "/bifrost",
        element: <RedirectBifrost />,
        errorElement: <Error />,
      },
      {
        path: "/email-verification",
        element: <EmailVerification />,
      },
    ],
  },
]);

configureSentry();

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  persistence: "localStorage",
});

if (import.meta.env.VITE_ENV !== "production") {
  mixpanel.disable();
}

export default function App() {
  const [cookie] = useCookies([keys.authToken]);
  const authToken = cookie[keys.authToken];
  const fetchUser = useLazyGetUser();

  /* Initialize Google Tag Manager */
  useEffectOnce(() => {
    (async () => {
      if (authToken) {
        try {
          const fetchedUser = await fetchUser();
          if (fetchedUser?.objectId) {
            tracker.identify(fetchedUser);
            setLoggerUserContext(fetchedUser);
          }
        } catch (error) {
          console.log(error);
        }
      }

      tracker.initialize();
    })();
  });

  return (
    <Fragment>
      <RouterProvider router={router} />
      <Toaster />
    </Fragment>
  );
}
