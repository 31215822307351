import { Button } from "@/components/ui/button";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface Props extends PropsWithChildren {
  title?: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  save: {
    onClick: () => void;
    label?: string;
    disabled?: boolean;
  };
  customHeightClassName?: string;
}

export default function Modal({
  title,
  description,
  open,
  onClose,
  save,
  children,
  customHeightClassName,
}: Props) {
  const { t } = useTranslation(["common"]);

  const headerClassName = "h-[65px] md:h-[65px] px-5";

  const footerClassName =
    "h-[85px] md:h-[85px] px-5 justify-between md:justify-end";

  let mainClassName = "h-[calc(100dvh-65px-85px)] md:h-[calc(90vh-65px-85px)]";

  if (customHeightClassName) {
    mainClassName = customHeightClassName;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 md:overflow-y-auto">
          <div className="flex h-full items-center justify-center p-0 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-none transform overflow-hidden bg-white text-left shadow-xl transition-all md:my-8 md:min-h-0 md:max-w-lg md:rounded-lg">
                <div
                  className={`${headerClassName} flex items-center justify-between border-b border-b-silver-800 bg-white`}
                >
                  <div className="text-left">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-bold leading-6 text-simple-900"
                      >
                        {title}
                      </Dialog.Title>
                    )}

                    {description && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-600">{description}</p>
                      </div>
                    )}
                  </div>

                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => onClose()}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div
                  className={`${mainClassName} relative mx-auto overflow-hidden bg-white md:px-0`}
                >
                  <div className="h-full overflow-auto">
                    <div className="mx-auto h-full md:max-w-[500px]">
                      <div className="h-full px-6 py-12 md:px-0 md:py-6">
                        {children}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`${footerClassName} border-silver-90 relative z-20 mx-auto flex w-full items-center border-t bg-white pb-[5px] md:static`}
                >
                  <Button
                    onClick={onClose}
                    variant="filled"
                    color="neutral"
                    className="mr-3"
                  >
                    {t("common:cancel")}
                  </Button>
                  <Button onClick={save.onClick} disabled={save.disabled}>
                    {save.label || t("common:save")}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
