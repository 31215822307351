import classNames from "@/utils/cx";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";

interface Option {
  name: string;
  description?: string;
  value: string;
}

interface Props {
  label?: string;
  options: Option[];
  selectedOptionValue: string;
  onSelect: (option: Option) => void;
}

export default function FieldSelect({
  label,
  options,
  selectedOptionValue,
  onSelect,
}: Props) {
  const [selected, setSelected] = useState<Option>(options[0]);

  useEffect(() => {
    if (!selectedOptionValue) return;

    const selectedOption = options.find(
      (option) => option.value === selectedOptionValue
    );

    if (selectedOption) setSelected(selectedOption);
  }, [selectedOptionValue, options]);

  return (
    <Listbox value={selected} onChange={onSelect}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-semibold leading-5 text-simple-900">
              {label}
            </Listbox.Label>
          )}
          <div className={`relative w-full ${label ? "mt-2" : ""}`}>
            <Listbox.Button className="relative w-full min-w-[80px] cursor-default rounded-md bg-white pb-2 pl-3 pr-10 pt-2.5 text-left text-simple-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-rapide-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.name}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-rapide-600 text-white" : "text-simple-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {option.description ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-silver-700",
                              "mt-1 block text-xs"
                            )}
                          >
                            {option.description}
                          </span>
                        ) : null}

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-rapide-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
