import { FCP_Park, Place } from "@/@types/park";
import keys from "@/config/keys";

/* --------------- Delete all stored data (used for logout) */

export const clearStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("cnp_")) {
      localStorage.removeItem(key);
    }
  });

  Object.keys(sessionStorage).forEach((key) => {
    if (key.startsWith("cnp_")) {
      sessionStorage.removeItem(key);
    }
  });
};

/* --------------- Auth */

// Stored auth token
export const getAuthToken = () => {
  return localStorage.getItem(keys.authToken);
};

export const saveAuthToken = (token: string) => {
  localStorage.setItem(keys.authToken, token);
};

export const removeAuthToken = () => {
  localStorage.removeItem(keys.authToken);
};

// Flag for whether user is logged in

export const setAfterAuthRedirect = (redirect: string) => {
  localStorage.setItem(keys.redirectAfterAuth, redirect);
};

export const getAfterAuthRedirect = () => {
  const redirect = localStorage.getItem(keys.redirectAfterAuth);
  if (redirect) localStorage.removeItem(keys.redirectAfterAuth);
  return redirect || "/";
};

export const removeAfterAuthRedirect = () => {
  localStorage.removeItem(keys.redirectAfterAuth);
};

// Stored signup email (passed from auth page to signup page)

export const setAuthPreflightEmail = (email: string) => {
  localStorage.setItem(keys.authPreflightEmail, email);
};

export const getAuthPreflightEmail = () => {
  const email = localStorage.getItem(keys.authPreflightEmail);
  return email || "";
};

export const removeAuthPreflightEmail = () => {
  localStorage.removeItem(keys.authPreflightEmail);
};

// Stored signup email (passed from auth page to signup page)

export const setSignupEmail = (email: string) => {
  localStorage.setItem(keys.signupEmail, email);
};

export const getSignupEmail = () => {
  const email = localStorage.getItem(keys.signupEmail);
  return email || "";
};

export const removeSignupEmail = () => {
  localStorage.removeItem(keys.signupEmail);
};

// Flag for whether user is navigating with bifrost

export const setBifrostEmail = (email: string) => {
  localStorage.setItem(keys.bifrostEmail, email);
};

export const getBifrostEmail = () => {
  return localStorage.getItem(keys.bifrostEmail);
};

export const removeBifrostEmail = () => {
  localStorage.removeItem(keys.bifrostEmail);
};

// Flag for whether user is navigating with bifrost and wanted to skip onboarding

export const setBifrostSkipOnboarding = () => {
  localStorage.setItem(keys.bifrostSkipOnboarding, "true");
};

export const getBifrostSkipOnboarding = () => {
  return localStorage.getItem(keys.bifrostSkipOnboarding);
};

export const removeBifrostSkipOnboarding = () => {
  localStorage.removeItem(keys.bifrostEmail);
};

// Current park place in local storage (used temporarily for step 1 and 2)

export const getParkPlace = () => {
  const place = localStorage.getItem(keys.parkAddPlace);
  if (place) return JSON.parse(place) as Place;
  else return undefined;
};

export const saveParkPlace = (place: Place) =>
  localStorage.setItem(keys.parkAddPlace, JSON.stringify(place));

export const removeParkPlace = () => {
  localStorage.removeItem(keys.parkAddPlace);
};

/* --------------- Park add */

// Current park in session storage

export const getCurrentPark = () => {
  const park = sessionStorage.getItem(keys.parkAdd);
  if (park) return JSON.parse(park) as Partial<FCP_Park>;
  else return undefined;
};

export const saveCurrentPark = (park: Partial<FCP_Park>) => {
  sessionStorage.setItem(keys.parkAdd, JSON.stringify(park));
};

export const removeCurrentPark = () => {
  sessionStorage.removeItem(keys.parkAdd);
};
