import { FCP_Vehicle } from "@/@types/user";
import { FCP_VehicleModel } from "@/@types/vehicle";
import Badge from "@/components/Badge";
import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import Loader from "@/components/layout/Loader";
import { Button } from "@/components/ui/button";
import { useLazyFindVehicleModels } from "@/hooks/query/useLazyFindVehicleModels";
import { CPException } from "@/models/exceptions/CPException";
import {
  useAddVehicleToWhitelistMutation,
  useDeleteVehicleMutation,
  useGetVehiclesQuery,
} from "@/services/user";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

interface ExtendedVehicle extends FCP_Vehicle {
  deleting: boolean;
}

export default function FormVehiclesWhitelist() {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["account", "common", "validation"]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<string>("");
  const [querying, setQuerying] = useState(false);
  const [vehiclePlate, setVehiclePlate] = useState<string>("");
  const [vehicleModel, setVehicleModel] = useState<
    FCP_VehicleModel | undefined
  >();
  const [vehicleModelString, setVehicleModeString] = useState<
    string | undefined
  >();
  const [vehicles, setVehicles] = useState<ExtendedVehicle[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [plateAlreadyExists, setPlateAlreadyExists] = useState<boolean>(false);
  const [foundVehicleModels, setFoundVehicleModels] = useState<
    FCP_VehicleModel[]
  >([]);

  const [timeoutPointer, setTimeoutPointer] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const findVehicleModels = useLazyFindVehicleModels();
  const { mutateAsync: addVehicleAsync } = useAddVehicleToWhitelistMutation();
  const { mutateAsync: deleteAsync } = useDeleteVehicleMutation();
  const { data: fetchedVehicles } = useGetVehiclesQuery();

  useEffect(() => {
    setVehicles(fetchedVehicles?.map((v) => ({ ...v, deleting: false })) || []);
  }, [fetchedVehicles]);

  useEffect(() => {
    if (query.length >= 3) {
      if (timeoutPointer) clearTimeout(timeoutPointer);
      setTimeoutPointer(
        setTimeout(async () => {
          setQuerying(true);
          const results = await findVehicleModels({ modelName: query });

          if (results) {
            setFoundVehicleModels(results);
            setQuerying(false);
          }
        }, 400)
      );
    } else {
      setFoundVehicleModels([]);
      setQuerying(false);
    }

    return () => {
      if (timeoutPointer) {
        clearTimeout(timeoutPointer);
        setTimeoutPointer(null);
      }

      setFoundVehicleModels([]);
      setQuerying(false);
    };
  }, [query]);

  useEffect(() => {
    if (vehicles) {
      const foundPlates = vehicles?.find((v) => v.plate === vehiclePlate);
      setPlateAlreadyExists(!!foundPlates);
    }
  }, [vehiclePlate, vehicles]);

  async function deleteVehicle(id: string) {
    setVehicles((prev) =>
      prev.map((v) => {
        if (v.id === id) {
          return { ...v, deleting: true };
        } else {
          return v;
        }
      })
    );

    try {
      await deleteAsync({ id });
      queryClient.invalidateQueries({ queryKey: ["vehiclesWhitelist"] });
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }

      setVehicles((prev) =>
        prev.map((v) => {
          if (v.id === id) {
            return { ...v, deleting: false };
          } else {
            return v;
          }
        })
      );
    }
  }

  async function addVehicle() {
    setError(undefined);
    setLoading(true);

    try {
      await addVehicleAsync({
        plate: vehiclePlate,
        modelId: vehicleModel?.vehicleModelId,
        noModelString: vehicleModelString,
      });
      queryClient.invalidateQueries({ queryKey: ["vehiclesWhitelist"] });
      setFoundVehicleModels([]);
      setQuerying(false);
      setQuery("");
      setVehicleModel(undefined);
      setVehicleModeString(undefined);
      setVehiclePlate("");
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }
    }

    setShowForm(false);
    setLoading(false);
  }

  return (
    <div className="relative">
      <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7">
            {t("account:vehiclesWhitelist")}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("account:vehiclesWhitelistDescription")}
          </p>
        </div>

        <div className="md:col-span-2">
          <div className="sm:max-w-xl">
            {error && (
              <div className="mb-5">
                <FormError title={error} />
              </div>
            )}

            <FormFields
              fields={[
                <FormField key="vehicle">
                  {vehicles.length > 0 ? (
                    <ul className="list-inside list-disc">
                      {vehicles.map((vehicle, index) => {
                        return (
                          <li
                            key={index}
                            className="flex items-center justify-between border-b border-silver-800 bg-white py-2 text-sm font-bold"
                          >
                            <div className="flex items-center justify-between ">
                              <Badge variant="info">{vehicle.plate}</Badge>
                              <span className="ml-2 flex-1">
                                {vehicle?.noModelString
                                  ? vehicle.noModelString
                                  : `${vehicle.make} ${vehicle.model}`}
                              </span>
                            </div>

                            <Button
                              variant="subtle"
                              color="danger"
                              size="sm"
                              onClick={() => deleteVehicle(vehicle.id)}
                              loading={vehicle.deleting}
                              className="pl-2 pr-2"
                            >
                              <MdClose size={18} className="text-red-500" />
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p>{t("account:noVehicleWhitelist")}</p>
                  )}

                  {showForm ? (
                    <div className="mt-4 rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5">
                      <div className="px-4 py-5 sm:p-6">
                        <FormFields
                          gapY="md"
                          fields={[
                            <FormField key="vehicleModel">
                              <FieldText
                                name="vehicleModel"
                                label={t("account:vehicleModel")}
                                onChange={(e) => setQuery(e.target.value)}
                                value={
                                  vehicleModel
                                    ? `${vehicleModel.make} ${vehicleModel?.model}`
                                    : query
                                }
                                disabled={!!vehicleModel}
                                isTrailingIconInteractive={true}
                                TrailingIcon={
                                  vehicleModel ? (
                                    <MdClose
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setVehicleModel(undefined);
                                        setVehicleModeString(undefined);
                                      }}
                                    />
                                  ) : undefined
                                }
                              />

                              {!vehicleModel && query && !querying && (
                                <>
                                  <ul className="mt-2 max-h-[300px] overflow-auto rounded-md border shadow-lg">
                                    {foundVehicleModels.map(
                                      (foundVehicleModel) => {
                                        return (
                                          <li
                                            key={
                                              foundVehicleModel.vehicleModelId
                                            }
                                            className="flex cursor-pointer items-center rounded-tl-md rounded-tr-md border-b p-3 text-sm duration-100 ease-in-out hover:bg-silver-900"
                                            onClick={() => {
                                              setVehicleModel(
                                                foundVehicleModel
                                              );
                                              setFoundVehicleModels([]);
                                              setQuerying(false);
                                            }}
                                          >
                                            <img
                                              src="/images/onboarding/icon-vehicle.svg"
                                              className="mr-3"
                                            />
                                            <span>
                                              {foundVehicleModel.make}{" "}
                                              <span>
                                                {foundVehicleModel.model}
                                              </span>
                                            </span>
                                          </li>
                                        );
                                      }
                                    )}
                                    <li
                                      className="flex cursor-pointer items-center rounded-tl-md rounded-tr-md border-b p-3 text-sm duration-100 ease-in-out hover:bg-silver-900"
                                      onClick={() => {
                                        setVehicleModeString(query);
                                        setFoundVehicleModels([]);
                                        setQuerying(false);
                                      }}
                                    >
                                      <img
                                        src="/images/onboarding/icon-vehicle.svg"
                                        className="mr-3"
                                      />
                                      <span>{query}</span>
                                    </li>
                                  </ul>
                                </>
                              )}
                            </FormField>,
                            <FormField key="vehiclePlate">
                              <FieldText
                                name="vehiclePlate"
                                label={t("account:vehiclePlate")}
                                cornerHint={
                                  plateAlreadyExists
                                    ? t("account:plateAlreadyExists")
                                    : ""
                                }
                                value={vehiclePlate}
                                onChange={(e) =>
                                  setVehiclePlate(e.target.value)
                                }
                              />
                            </FormField>,
                          ]}
                        />

                        <div className="mt-4">
                          <Button
                            onClick={() => addVehicle()}
                            disabled={
                              (!vehicleModel && !vehicleModelString) ||
                              !vehiclePlate
                            }
                          >
                            {t("common:add")}
                          </Button>

                          <Button
                            className="ml-2"
                            variant="subtle"
                            onClick={() => setShowForm(false)}
                          >
                            {t("common:cancel")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <Button onClick={() => setShowForm(true)}>
                        {t("account:addVehicleToWhitelist")}
                      </Button>
                    </div>
                  )}
                </FormField>,
              ]}
            />
          </div>
        </div>
      </div>

      {loading ? <Loader /> : null}
    </div>
  );
}
