import { FCP_PriceIndicator } from "@/@types/park";
import Badge from "@/components/Badge";
import Spacer from "@/components/Spacer";
import FieldNumber from "@/components/form/fields/FieldNumber";
import FieldRadiosGroup from "@/components/form/fields/FieldRadiosGroup";
import FormError from "@/components/form/layout/FormError";
import FormHeading from "@/components/form/layout/FormHeading";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import ParkAddShell from "@/components/layout/park/add/Shell";
import useParkAdd from "@/hooks/context/useParkAdd";
import { useLazyGetPriceIndicators } from "@/hooks/query/useLazyGetPriceIndicators";
import { CPException } from "@/models/exceptions/CPException";
import {
  getFormattedPrice,
  getFormattedPriceForTimeUnit,
} from "@/utils/formatter";
import { getCurrentPark } from "@/utils/storage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ParkAddPricing() {
  const { t } = useTranslation(["park", "validation"]);

  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<string | undefined>();
  const [formAction, setFormAction] = useState<"next" | "exit">("next");
  const { next, back, exit, updatePark } = useParkAdd();
  const fetchPriceIndicators = useLazyGetPriceIndicators();
  const park = getCurrentPark();

  useEffect(() => {
    if (!park?.objectId) return;

    (async () => {
      const data = await fetchPriceIndicators({ parkId: park?.objectId });

      if (data) {
        setFieldValue("priceIndicator", data);
        setFieldValue("price", data.basePriceGuidance);
        setFieldValue("optimizedPrice", data.basePriceGuidance);

        if (park?.uxMetadata?.pricingMode) {
          setFieldValue("mode", park?.uxMetadata?.pricingMode);
          if (park.basePrice) setFieldValue("price", park.basePrice);
        } else {
          setFieldValue("mode", "optimized");
        }
      }

      setLoading(false);
    })();
  }, []);

  const initialValues: {
    mode: "optimized" | "manual";
    price: string; // Needs to be a string for the FieldNumber component
    optimizedPrice: number;
    priceIndicator?: FCP_PriceIndicator;
  } = {
    mode: "optimized",
    price: park?.basePrice?.toString() || "1",
    optimizedPrice: 1,
  };

  const { values, submitForm, isSubmitting, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setFormError(undefined);

      try {
        await updatePark({
          basePrice:
            values.mode === "optimized"
              ? values.optimizedPrice
              : parseFloat(values.price),
          uxMetadata: {
            ...(park?.uxMetadata ? park.uxMetadata : {}),
            pricingMode: values.mode,
          },
        });

        formAction === "next" ? next() : exit();
      } catch (error) {
        if (error instanceof CPException) {
          setFormError(error.message);
        } else {
          setFormError(t("validation:genericError"));
        }
      }
    },
  });

  const oneHourPrice = getFormattedPriceForTimeUnit(
    "hour",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const fiveHoursPrice = getFormattedPriceForTimeUnit(
    "5hours",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const twentyFourHoursPrice = getFormattedPriceForTimeUnit(
    "24hours",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const weekPrice = getFormattedPriceForTimeUnit(
    "week",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const monthPrice = getFormattedPriceForTimeUnit(
    "month",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values.priceIndicator
  );

  function onBack() {
    back();
  }

  function onNext() {
    setFormAction("next");
    submitForm();
  }

  function onSaveAndExit() {
    setFormAction("exit");
    submitForm();
  }

  return (
    <ParkAddShell
      loading={isSubmitting || loading}
      actions={{
        saveAndExit: {
          visible: true,
          disabled: isSubmitting,
          onClick: onSaveAndExit,
        },
        next: {
          visible: true,
          disabled: isSubmitting,
          onClick: onNext,
        },
        back: {
          visible: true,
          disabled: isSubmitting,
          onClick: onBack,
        },
      }}
    >
      {formError && <FormError title={formError} />}

      <FormFields
        fields={[
          <FormField key="pricing-explanation">
            <FormHeading title={t("park:customizePricing")} />
            <p className="text-sm">{t("park:pricingExplanation1")}</p>
            <Spacer />
            <p className="text-sm">{t("park:pricingExplanation2")}</p>
          </FormField>,
          <FormField key="pricing-mode">
            <FieldRadiosGroup
              options={[
                {
                  name: t("park:optimizedPricing"),
                  value: "optimized",
                  badge: (
                    <Badge variant="success">
                      {t("park:recommended").toUpperCase()}
                    </Badge>
                  ),
                },
                {
                  name: t("park:manualPricing"),
                  value: "manual",
                },
              ]}
              selectedOptionValue={values.mode}
              onSelect={({ value }) => {
                setFieldValue("mode", value);

                if (value === "optimized") {
                  setFieldValue("price", values.optimizedPrice);
                }
              }}
            />
          </FormField>,
          values.mode === "manual" ? (
            <FormField>
              <div className="rounded-lg border border-silver-800 bg-white shadow">
                <div className="flex justify-center px-4 py-5 sm:p-6">
                  <FieldNumber
                    name="price"
                    onNumberChange={(value) => setFieldValue("price", value)}
                    fieldValue={values.price}
                    incrementType="float"
                    increment={0.05}
                    max={5}
                  />
                </div>
              </div>
            </FormField>
          ) : null,
        ]}
      />

      <div className="mt-5 rounded-[12px] bg-green-600 p-[5px]">
        <div className="flex rounded-[8px] bg-white p-5">
          <div className="sm:text-md mr-8 flex items-center border-r pr-8 text-center text-sm font-bold">
            <div>
              {values.mode === "optimized"
                ? t("park:suggestedFirstHourPrice")
                : t("park:firstHourPrice")}
              <br />
              <div className="mt-2 text-3xl font-bold text-green-700">
                {getFormattedPrice(parseFloat(values.price), "CA", "CAD")}
              </div>
            </div>
          </div>
          <div className="text-xs sm:text-sm">
            <p className="mb-3 font-bold">{t("park:pricePaidByCustomer")}</p>
            <ul>
              <li className="flex justify-between">
                <span className="font-bold">{t("park:1hour")}</span>
                {oneHourPrice}
              </li>
              <li className="flex justify-between">
                <span className="font-bold">{t("park:5hours")}</span>
                {fiveHoursPrice}
              </li>
              <li className="flex justify-between">
                <span className="font-bold">{t("park:24hours")}</span>
                {twentyFourHoursPrice}
              </li>
              <li className="flex justify-between">
                <span className="font-bold">{t("park:1week")}</span>
                {weekPrice}
              </li>
              <li className="flex justify-between">
                <span className="font-bold">{t("park:1month")}</span>
                {monthPrice}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ParkAddShell>
  );
}
