import { Button } from "@/components/ui/button";
import { getLanguage } from "@/utils/language";
import clsx from "clsx";
import {
  addMinutes,
  format,
  isBefore,
  isSameDay,
  parse,
  startOfDay,
} from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";

interface DatePickerProps {
  visible: boolean;
  date: Date;
  minDate: Date;
  minTime: string;
  selectedTime: string;
  onDateSelect: (date: Date) => void;
  onTimeSelect: (time: string) => void;
  onClose: (visible: boolean) => void;
}

export default function ExceptionDatePicker({
  visible,
  date,
  minDate,
  minTime,
  selectedTime,
  onDateSelect,
  onTimeSelect,
  onClose,
}: DatePickerProps) {
  const { t } = useTranslation(["common"]);
  const isEN = getLanguage() === "en";
  const datePickerLocale = isEN ? enUS : fr;

  if (!visible) return null;

  const times = Array.from({ length: 48 }, (_, i) =>
    format(addMinutes(startOfDay(new Date()), i * 30), "HH:mm")
  );

  return (
    <div className="absolute z-10 mt-5 rounded-md bg-white p-2 text-sm shadow-md ring-1 ring-silver-800">
      <div className="flex flex-col sm:flex-row">
        <DayPicker
          locale={datePickerLocale}
          mode="single"
          selected={date}
          fromDate={minDate}
          onSelect={(date) => (date ? onDateSelect(date) : null)}
        />

        <div className="mt-[-10px] flex h-auto max-w-[75vw] items-center overflow-auto px-3 sm:ml-2 sm:mr-5 sm:mt-5 sm:block sm:h-[300px] sm:border-l sm:border-silver-800 sm:px-0 sm:pl-5">
          {times.map((time, index) => {
            const isDisabled =
              isSameDay(date, minDate) &&
              isBefore(
                parse(time, "HH:mm", new Date()),
                parse(minTime, "HH:mm", new Date())
              );

            return (
              <div
                key={index}
                onClick={() => !isDisabled && onTimeSelect(time)}
                className={clsx(
                  selectedTime === time &&
                    !isDisabled &&
                    "border-rapide-600 bg-rapide-600 text-white",
                  isDisabled &&
                    "cursor-not-allowed opacity-50 hover:border-silver-800 hover:bg-white hover:text-simple-900",
                  "mx-1 my-3 cursor-pointer rounded-md border border-silver-800 p-3 text-center text-xs font-bold text-simple-900 hover:border-rapide-600 hover:bg-rapide-600 hover:text-white sm:mx-0 sm:first:mt-0"
                )}
              >
                {time}
              </div>
            );
          })}
        </div>
      </div>

      <div className="mx-3 mb-3 sm:mx-6 sm:mb-7 sm:mt-6 sm:border-t sm:border-t-silver-800 sm:pt-3">
        <div className="group">
          <Button onClick={() => onClose(false)}>{t("save")}</Button>
          <Button
            className="ml-2 mt-3"
            variant="light"
            onClick={() => onClose(false)}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
