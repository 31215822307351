import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  to?: string;
}

export default function Redirect({ to }: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to ?? "/");
  }, [navigate]);

  return null;
}
