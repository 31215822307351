import { useTranslation } from "react-i18next";

interface Props {
  backgroundStyle?: "transparent" | "opaque";
}

export default function Loader({ backgroundStyle }: Props) {
  const { t } = useTranslation("common");

  let bgClassName = "";

  switch (backgroundStyle) {
    case "transparent":
      bgClassName = "bg-transparent";
      break;
    case "opaque":
      bgClassName = "bg-white";
      break;
    default:
      bgClassName = "bg-white/90";
  }

  return (
    <div
      className={`absolute bottom-0 left-0 right-0 top-0 z-50 ${bgClassName} flex items-center justify-center transition duration-200 ease-in-out`}
    >
      <div
        className={`inline-block h-4 w-4 animate-spin rounded-full border-[2px] border-current border-t-transparent text-rapide-600`}
        role="status"
        aria-label="loading"
      >
        <span className="sr-only">{t("pleaseWait")}</span>
      </div>
    </div>
  );
}
