import FieldText from "@/components/form/fields/FieldText";
import FormError from "@/components/form/layout/FormError";
import FormSuccess from "@/components/form/layout/FormSuccess";
import { Button } from "@/components/ui/button";
import {
  useGetUserQuery,
  useUpdateUserBillingEmailsMutation,
} from "@/services/user";

import { FieldArray, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDeleteForever } from "react-icons/md";

export default function FormBillingEmails() {
  const { t } = useTranslation(["financial", "common"]);
  const [error, setError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data;
  const billingEmails = user?.billingEmails;

  const updateUserBillingEmailsMutation = useUpdateUserBillingEmailsMutation();

  const initialValues: {
    recipients: string[];
  } = {
    recipients: billingEmails || [],
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("financial:billingEmails")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("financial:billingEmailsDescription")}
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="sm:max-w-xl">
          {error ? (
            <div className="mb-5">
              <FormError title={error} />
            </div>
          ) : null}

          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              try {
                await updateUserBillingEmailsMutation.mutateAsync(
                  values.recipients
                );
                setFormSuccess(true);
              } catch (err: any) {
                setError(err.message);
              }
            }}
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
          >
            {({ values, handleChange, submitForm, isSubmitting, errors }) => {
              return (
                <FieldArray
                  name="recipients"
                  validateOnChange={false}
                  render={(arrayHelpers) => (
                    <div>
                      {values.recipients && values.recipients.length > 0 ? (
                        values.recipients.map((recipient, index) => (
                          <div key={index} className="mb-5 flex items-center">
                            <div className="min-w-[320px]">
                              <FieldText
                                name={`recipients.${index}`}
                                placeholder={t("financial:enterRecipientEmail")}
                                type="email"
                                value={recipient}
                                onChange={(e) => {
                                  handleChange(e);
                                  arrayHelpers.replace(index, e.target.value);
                                }}
                                error={errors?.recipients?.[index]?.toString()}
                              />
                            </div>

                            <Button
                              variant="filled"
                              color="neutral"
                              onClick={() => arrayHelpers.remove(index)}
                              className="ml-4"
                            >
                              <MdDeleteForever className="h-5 w-5 text-red-500" />
                            </Button>
                          </div>
                        ))
                      ) : (
                        <p className="mb-5">{t("financial:noRecipients")}</p>
                      )}

                      <div className="flex items-center">
                        <Button
                          variant="outline"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {t("common:add")}
                        </Button>

                        <Button
                          onClick={submitForm}
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          className="ml-3"
                        >
                          {t("common:save")}
                        </Button>

                        <FormSuccess visible={formSuccess} />
                      </div>
                    </div>
                  )}
                />
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}
