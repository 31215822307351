import { IconBulb } from "@tabler/icons-react";

interface Props {
  text: string;
  alignItems?: "center" | "start" | "end";
}

export default function FormHint({ text, alignItems = "center" }: Props) {
  let alignItemsClass = "items-center";

  if (alignItems === "start") {
    alignItemsClass = "items-start";
  } else if (alignItems === "end") {
    alignItemsClass = "items-end";
  }

  return (
    <p
      className={`my-5 flex text-xs font-bold text-rapide-600 ${alignItemsClass}`}
    >
      <span
        className={`mr-3 block text-rapide-600 ${
          alignItems === "center" ? "mt-[-3px]" : ""
        }`}
      >
        <IconBulb size={20} />
      </span>
      {text}
    </p>
  );
}
