import useOnLogout from "@/hooks/use-on-logout.hook";
import { useGetUserQuery } from "@/services/user";
import { getAltLanguageLabel, switchLanguage } from "@/utils/language";
import { ScanSearch } from "lucide-react";
import React, { createContext } from "react";
import { useTranslation } from "react-i18next";

export type NavigationContextProps = {
  primaryNavItems: {
    name: string;
    href: string;
    icon: React.ReactNode;
  }[];
  secondaryNavItems: {
    name: string;
    onClick: () => void;
    icon: React.ReactNode;
  }[];
};

const NavigationContext = createContext<NavigationContextProps>(null!);

interface Props {
  children: React.ReactNode;
}

const NavigationProvider = ({ children }: Props) => {
  const { t } = useTranslation("common");
  const logout = useOnLogout();

  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data;

  let primaryNavItems = [
    {
      name: t("overview"),
      href: "/",
      icon: <img src="/images/layout/icon-dashboard.svg" alt={t("overview")} />,
    },
    {
      name: t("reservations"),
      href: "/reservations",
      icon: (
        <img
          src="/images/layout/icon-reservations.svg"
          alt={t("reservations")}
        />
      ),
    },
    {
      name: t("parks"),
      href: "/parks",
      icon: <img src="/images/layout/icon-parks.svg" alt={t("parks")} />,
    },
    {
      name: t("account"),
      href: "/account",
      icon: <img src="/images/layout/icon-account.svg" alt={t("account")} />,
    },
    {
      name: t("billing"),
      href: "/billing/reports",
      icon: <img src="/images/layout/icon-billing.svg" alt={t("billing")} />,
    },
    {
      name: t("payment"),
      href: "/payment",
      icon: <img src="/images/layout/icon-payment.svg" alt={t("payment")} />,
    },
    {
      name: t("faqs"),
      href: "/help",
      icon: <img src="/images/layout/icon-help.svg" alt={t("faqs")} />,
    },
  ];

  // @ts-ignore
  if (user?.plateCheckerEnabled) {
    primaryNavItems.push({
      name: t("plateChecker"),
      href: "/plate-checker",
      icon: (
        <div className="flex h-[24px] w-[22px] items-center justify-center rounded-md bg-silver-900">
          <ScanSearch className="h-4 w-4 text-[#2E73D1]" strokeWidth={3} />
        </div>
      ),
    });
  }

  return (
    <NavigationContext.Provider
      value={{
        primaryNavItems,
        secondaryNavItems: [
          {
            name: getAltLanguageLabel(true),
            onClick: () => switchLanguage(),
            icon: (
              <img
                src="/images/layout/icon-language.svg"
                alt={t("changeLanguage")}
              />
            ),
          },
          {
            name: t("logout"),
            onClick: logout,
            icon: (
              <img src="/images/layout/icon-logout.svg" alt={t("logout")} />
            ),
          },
        ],
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationProvider };
export default NavigationContext;
