import {
  FCP_DailyAvailRange,
  FCP_Day,
  FCP_Park,
  FCP_WeekRange,
} from "@/@types/park";
import Badge from "@/components/Badge";
import FieldRadiosGroup from "@/components/form/fields/FieldRadiosGroup";
import FieldSelect from "@/components/form/fields/FieldSelect";
import FieldSwitch from "@/components/form/fields/FieldSwitch";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import Modal from "@/components/Modal";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import {
  appendAmPmToHour,
  generateHoursAndMinutesArray,
  getDayNumberFromName,
  getDaysArray,
  getTimeFromTimeblock,
  getTimeblocFromTime,
} from "@/utils/date";
import { CPSchedule, CPScheduleBlock, Scheduler } from "@clicknpark/react";
import { ArrowRightIcon, ClockIcon } from "@heroicons/react/20/solid";
import { IconDragDrop } from "@tabler/icons-react";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";

function getInitialValues(park?: Partial<FCP_Park>): {
  scheduleType: "all-time" | "week" | "custom";
  weekRanges: { [key: string]: FCP_WeekRange };
  customRanges: { [key: string]: CPScheduleBlock[] };
} {
  const isLegacyScheduleType =
    park?.uxMetadata?.scheduleType?.value === undefined;

  let scheduleType = park?.uxMetadata?.scheduleType?.value || "all-time";
  scheduleType = park?.allTimeAvail ? "all-time" : scheduleType;

  if (isLegacyScheduleType) {
    scheduleType = park?.allTimeAvail ? "all-time" : "custom";
  }

  const weekRanges: { [key: string]: FCP_WeekRange } = {
    monday: { checked: false, from: "00:00", to: "23:45" },
    tuesday: { checked: false, from: "00:00", to: "23:45" },
    wednesday: { checked: false, from: "00:00", to: "23:45" },
    thursday: { checked: false, from: "00:00", to: "23:45" },
    friday: { checked: false, from: "00:00", to: "23:45" },
    saturday: { checked: false, from: "00:00", to: "23:45" },
    sunday: { checked: false, from: "00:00", to: "23:45" },
  };

  const customRanges: { [key: string]: CPScheduleBlock[] } = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  // get week ranges from park if schedule type is week
  if (park?.uxMetadata?.scheduleType?.value === "week") {
    getDaysArray().forEach((day) => {
      const dayAvailability = park?.dailyAvail?.[day];
      const checked = !!dayAvailability || false;
      const from = dayAvailability
        ? getTimeFromTimeblock(dayAvailability[0].start.time)
        : "00:00";
      const to = dayAvailability
        ? getTimeFromTimeblock(dayAvailability[0].end.time)
        : "23:45";
      weekRanges[day] = { checked, from, to };
    });
  }

  // get custom ranges from park if schedule type is custom

  if (
    park?.uxMetadata?.scheduleType?.value === "custom" ||
    isLegacyScheduleType
  ) {
    getDaysArray().forEach((day) => {
      if (park?.dailyAvail?.[day]) {
        customRanges[day] =
          park.dailyAvail[day]?.map((dailyAvail) => {
            const startHourStr = getTimeFromTimeblock(
              dailyAvail.start.time
            ).split(":")[0];

            let endHourStr = getTimeFromTimeblock(dailyAvail.end.time).split(
              ":"
            )[0];

            if (dailyAvail.end.time === "23D") {
              endHourStr = "24:00";
            }

            const startHour = parseInt(startHourStr);
            const endHour = parseInt(endHourStr);

            return {
              endHour,
              startHour,
              id: `D${getDayNumberFromName(day)}-${startHour}-${endHour}`,
              parentId: `D${getDayNumberFromName(day)}`,
              length: endHour - startHour,
            };
          }) || [];
      }
    });
  }

  return { scheduleType, weekRanges, customRanges };
}

export default function FormParkDailyAvail() {
  const { park } = useParkEdit();
  const { t } = useTranslation(["park", "common", "validation", "dates"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalDay, setModalDay] = useState<FCP_Day | undefined>();
  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  // The advanced schedule that is currently edited in a modal (it lives as long as the modal is opened, then it is saved in the form values if the user clicks on save)
  const [currentEditedSchedule, setCurrentEditedSchedule] =
    useState<CPSchedule>({});

  const { values, submitForm, isSubmitting, setFieldValue, isValid } =
    useFormik({
      initialValues: getInitialValues(park),
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        if (!park) return;

        setFormError(undefined);
        setFormSuccess(false);

        const dailyAvail: {
          allTime?: boolean;
          offset: number | null;
          ranges: FCP_DailyAvailRange[] | null;
        } = {
          allTime: values.scheduleType === "all-time",
          offset: null,
          ranges: null,
        };

        if (values.scheduleType === "week") {
          let rangesAreSet = false;

          // Make sure at least one range is set
          getDaysArray().forEach((day) => {
            if (values.weekRanges[day].checked) rangesAreSet = true;
          });

          if (rangesAreSet) {
            dailyAvail.offset = new Date().getTimezoneOffset();

            const ranges = Object.keys(values.weekRanges)
              .map((key) => {
                const day = key as FCP_Day;

                if (values.weekRanges[day].checked === false) return null;

                return {
                  start: {
                    day,
                    time: getTimeblocFromTime(values.weekRanges[day].from),
                  },
                  end: {
                    day,
                    time: getTimeblocFromTime(values.weekRanges[day].to),
                  },
                };
              })
              .filter((range) => range !== null);

            dailyAvail.ranges = ranges as FCP_DailyAvailRange[];
          } else {
            setFormError(t("validation:atLeastOneDayRequired"));
            return;
          }
        } else if (values.scheduleType === "custom") {
          let rangesAreSet = false;

          // Make sure at least one range is set
          getDaysArray().forEach((day) => {
            if (values.customRanges[day].length > 0) rangesAreSet = true;
          });

          if (rangesAreSet) {
            dailyAvail.offset = new Date().getTimezoneOffset();

            const ranges = Object.keys(values.customRanges).map((key) => {
              const day = key as FCP_Day;

              return values.customRanges[day].map((range) => {
                let endTime = getTimeblocFromTime(range.endHour + ":00");

                if (range.endHour === 24) {
                  endTime = getTimeblocFromTime("23:45");
                }

                return {
                  start: {
                    day,
                    time: getTimeblocFromTime(range.startHour + ":00"),
                  },
                  end: {
                    day,
                    time: endTime,
                  },
                };
              });
            });

            dailyAvail.ranges = ranges.flat() as FCP_DailyAvailRange[];
          } else {
            setFormError(t("validation:atLeastOneDayRequired"));
            return;
          }
        }

        try {
          await updateParkAsync({
            parkId: park.objectId,
            updates: {
              dailyAvail,
              uxMetadata: {
                ...(park?.uxMetadata ? park.uxMetadata : {}),
                scheduleType: {
                  value: values.scheduleType,
                  type: "string",
                  label: "Type d'horaire",
                },
              },
            },
          });

          setFormSuccess(true);
          setFormError(undefined);

          setTimeout(() => {
            setFormSuccess(false);
          }, 5000);
        } catch (error) {
          if (error instanceof CPException) {
            setFormError(error.message);
          } else {
            setFormError(t("validation:genericError"));
          }
        }
      },
    });

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7">
          {t("park:schedule")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t("park:editScheduleDescription")}
        </p>
      </div>
      <form
        className="md:col-span-2"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="sm:max-w-xl">
          {formError && (
            <div className="mb-5">
              <FormError title={formError} />
            </div>
          )}

          <FormFields
            fields={[
              <FormField key="scheduleType">
                <FieldRadiosGroup
                  onSelect={(option) =>
                    setFieldValue("scheduleType", option.value)
                  }
                  selectedOptionValue={values.scheduleType}
                  options={[
                    {
                      name: t("park:scheduleAllTime"),
                      value: "all-time",
                      badge: (
                        <Badge variant="success" bordered>
                          {t("park:recommended").toUpperCase()}
                        </Badge>
                      ),
                    },
                    {
                      name: t("park:scheduleWeekdays"),
                      value: "week",
                    },
                    {
                      name: t("park:scheduleCustom"),
                      value: "custom",
                    },
                  ]}
                />
              </FormField>,
            ]}
          />

          {values.scheduleType === "week" ? (
            <ul className="col-span-6 mt-5 rounded-md border">
              {getDaysArray().map((day: FCP_Day) => {
                return (
                  <li
                    key={`week-${day}`}
                    className={`px-4 py-3 ${
                      day !== "monday" && "border-t pb-3"
                    }`}
                  >
                    <FieldSwitch
                      label={t(`dates:${day}`)}
                      checked={values.weekRanges[day].checked}
                      onChange={(checked) => {
                        setFieldValue("weekRanges", {
                          ...values.weekRanges,
                          [day]: { ...values.weekRanges[day], checked },
                        });
                      }}
                    />

                    <div
                      className={`mb-2 mt-5 flex items-center justify-between ${
                        values.weekRanges[day].checked ? "block" : "hidden"
                      }`}
                    >
                      <div className="flex flex-1 items-center">
                        <FieldSelect
                          options={generateHoursAndMinutesArray().map(
                            (time) => ({
                              name: time,
                              value: time,
                            })
                          )}
                          selectedOptionValue={values.weekRanges[day].from}
                          onSelect={({ value }) =>
                            setFieldValue("weekRanges", {
                              ...values.weekRanges,
                              [day]: {
                                ...values.weekRanges[day],
                                from: value,
                              },
                            })
                          }
                        />
                      </div>

                      <div className="mx-5 flex h-full items-center justify-center">
                        <FaArrowRight className="h-[15px] w-[15px] text-rapide-600" />
                      </div>

                      <div className="flex flex-1 items-center justify-end">
                        <FieldSelect
                          options={generateHoursAndMinutesArray().map(
                            (time) => ({
                              name: time,
                              value: time,
                            })
                          )}
                          selectedOptionValue={values.weekRanges[day].to}
                          onSelect={({ value }) =>
                            setFieldValue("weekRanges", {
                              ...values.weekRanges,
                              [day]: {
                                ...values.weekRanges[day],
                                to: value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}

          {values.scheduleType === "custom" ? (
            <ul className="col-span-6 mt-5 rounded-md border">
              {getDaysArray().map((day: FCP_Day) => {
                return (
                  <li
                    key={`custom-${day}`}
                    onClick={() => {
                      setCurrentEditedSchedule({
                        [`D${getDayNumberFromName(day)}`]:
                          values.customRanges[day],
                      });
                      setModalDay(day);
                      setModalOpen(true);
                    }}
                    className={`cursor-pointer px-4 pb-2 pt-3 hover:bg-rapide-600/5 ${
                      day !== "monday" && "border-t"
                    }`}
                  >
                    <div className="item-center flex justify-between">
                      <span className="text-sm font-bold leading-6 text-simple-900">
                        {t(`dates:${day}`)}
                      </span>

                      <FaArrowRight className="mt-1 h-[15px] w-[15px] text-rapide-600" />
                    </div>

                    {values.customRanges[day] && (
                      <ul className="mt-1">
                        {values.customRanges[day]?.map((range, index) => (
                          <li
                            className="flex items-center text-xs"
                            key={`custom-range-${day}-${index}`}
                          >
                            <ClockIcon className="mr-2 w-[14px] text-rapide-600" />
                            {appendAmPmToHour(range.startHour)}{" "}
                            <ArrowRightIcon className="mx-1 w-[12px]" />{" "}
                            {appendAmPmToHour(range.endHour)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>

        <div className="mt-5 flex items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t("common:save")}
          </Button>

          <FormSuccess visible={formSuccess} />
        </div>
      </form>

      {getDaysArray().map((day: FCP_Day) => {
        const ts = currentEditedSchedule[`D${getDayNumberFromName(day)}`];

        function closeModal() {
          setModalOpen(false);
          setTimeout(() => setModalDay(undefined), 500);
        }

        return (
          <Modal
            key={day}
            title={modalDay ? t(`dates:${modalDay}`) : ""}
            open={modalOpen && modalDay === day}
            save={{
              onClick: () => {
                setFieldValue("customRanges", {
                  ...values.customRanges,
                  [day]: ts,
                });

                closeModal();
              },
            }}
            onClose={closeModal}
          >
            <p className="mb-8 flex items-center">
              <IconDragDrop className="mr-2 w-[20px] text-rapide-600" />
              <strong className="mt-1 text-sm text-rapide-600">
                <span className="hidden sm:block">
                  {t("park:dragToCreateSchedules")}
                </span>
                <span className="block sm:hidden">
                  {t("park:dragToCreateSchedulesMobile")}
                </span>
              </strong>
            </p>
            <Scheduler
              schedule={currentEditedSchedule}
              options={{ mode: "day", id: `D${getDayNumberFromName(day)}` }}
              onScheduleChanged={(schedule) =>
                setCurrentEditedSchedule(schedule)
              }
            />
          </Modal>
        );
      })}
    </div>
  );
}
