import account from "./account.json";
import auth from "./auth.json";
import common from "./common.json";
import dashboard from "./dashboard.json";
import dates from "./dates.json";
import faqs from "./faqs.json";
import financial from "./financial.json";
import onboarding from "./onboarding.json";
import park from "./park.json";
import plateChecker from "./plate-checker.json";
import reservations from "./reservations.json";
import validation from "./validation.json";

const locale = {
  common,
  auth,
  account,
  validation,
  onboarding,
  park,
  dates,
  dashboard,
  reservations,
  faqs,
  financial,
  plateChecker,
};

const enLocale = locale; // Simplifies copy paste... every locale index is the same

export default enLocale;
