import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import { getLanguage } from "@/utils/language";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default function FaqPricing() {
  const { t } = useTranslation(["common", "faqs"]);

  const H2 = ({ children }: PropsWithChildren) => (
    <h2 className="mt-8 text-lg font-semibold">{children}</h2>
  );

  const H3 = ({ children }: PropsWithChildren) => (
    <h2 className="text-md mt-8 font-semibold">{children}</h2>
  );

  const P = ({ children }: PropsWithChildren) => (
    <p className="my-5">{children}</p>
  );

  const UL = ({ children }: PropsWithChildren) => (
    <ul className="my-3 pl-3">{children}</ul>
  );

  const LI = ({ children }: PropsWithChildren) => (
    <li className="list-disc py-1">{children}</li>
  );

  const OL = ({ children }: PropsWithChildren) => (
    <ul className="my-3 pl-3">{children}</ul>
  );

  const OLLI = ({ children }: PropsWithChildren) => (
    <li className="ml-4 list-decimal py-1">{children}</li>
  );

  return (
    <Shell>
      <PageHeader
        title={t("faqs:howPricingWorks")}
        breadcrumbs={[
          {
            name: t("common:faqs"),
            href: "/help",
            current: false,
          },
        ]}
      />

      <PageSection>
        {getLanguage() === "en" ? (
          <P>
            <strong>English text will come soon.</strong>
          </P>
        ) : null}

        <P>
          Les montants que vous recevez pour une réservation sont impactés par
          deux choses :
        </P>

        <OL>
          <OLLI>Le tarif payé par l’automobiliste</OLLI>
          <OLLI>Les frais de service et de transaction</OLLI>
        </OL>

        <H2>Tarif payé par l’automobiliste</H2>

        <P>
          Notre algorithme détermine le tarif de la réservation en fonction du
          tarif de base fixé par le propriétaire (2.00$ par exemple). À partir
          de ce tarif horaire, un rabais de volume est appliqué après la
          cinquième heure de location afin de permettre à votre stationnement
          d’être compétitif dans son marché et donc de maximiser vos revenus de
          location.
        </P>

        <H3>
          Tableau d’exemple avec un tarif horaire de 2$ et les rabais volume à
          partir de la cinquième heure de location.
        </H3>

        <div>
          <img src="/images/faq/price-function.png" alt="" className="my-5" />

          <UL>
            <LI>
              <strong>1 heure</strong>: 2,00$
            </LI>
            <LI>
              <strong>5 heure</strong>: 10,00$
            </LI>
            <LI>
              <strong>10 heures</strong>: 12,63$ **
            </LI>
            <LI>
              <strong>24 heures</strong>: 20,00$
            </LI>
            <LI>
              <strong>Semaine</strong>: 87,19$
            </LI>
            <LI>
              <strong>Mois</strong>: 128,04$
            </LI>
          </UL>
        </div>

        <P>
          ** Important : Selon les prix du marché, cet espace se loue environ au
          tarif de 12 $ à 15$ avec comme hypothèse un tarif à 2$/heure. Sans
          l’application du rabais de volume l’automobiliste aurait dû payer (2$
          x 10h = 20$ ce qui n’est pas compétitif pour maximiser le potentiel de
          vos revenus.
        </P>

        <H2>Frais de service et de transaction</H2>

        <P>
          clicknpark charge des frais de service de 30% sur le montant payé par
          l’automobiliste. Ces frais permettent de couvrir les coûts
          d’utilisation et de développement de la plateforme en plus de vous
          offrir des outils de suivi de vos réservations en temps réel, l’accès
          au service client de clicknpark et des campagnes marketing pour
          maximiser la rentabilité de votre espace.
        </P>
        <P>
          Des frais de transaction de 3% du montant payé par l’automobiliste
          sont prélevés par clicknpark pour couvrir les frais de cartes de
          crédit.
        </P>
        <P>
          Le montant versé au propriétaire correspond donc au montant payé par
          l'automobiliste moins les frais de service et de transaction, soit 67%
          du montant payé par l’automobiliste.
        </P>
        <P>
          <strong>
            Le propriétaire et clicknpark sont respectivement responsables de
            déclarer les taxes de vente.
          </strong>
        </P>
        <P>
          <strong>Note :</strong> le propriétaire résidentiel (non inscrit aux
          taxes) n’est pas tenu de prélever des taxes de vente si ses revenus de
          location sont en deçà de 30 000$ / sur 12 mois consécutifs.
        </P>
        <P>
          Votre paiement sera transféré automatiquement 24 heures après le début
          de la réservation.
        </P>
      </PageSection>
    </Shell>
  );
}
