import { FCP_Payout } from "@/@types/billing";
import Pagination from "@/components/Pagination";
import AlertSimple from "@/components/alert/AlertSimple";
import FormError from "@/components/form/layout/FormError";
import Loader from "@/components/layout/Loader";
import { useLazyGetPayouts } from "@/hooks/query/useLazyGetPayouts";
import { CPException } from "@/models/exceptions/CPException";
import { formatDate } from "@/utils/date";
import { getLanguage } from "@/utils/language";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Payouts() {
  const { t } = useTranslation(["common", "dashboard", "validation"]);
  const [limit] = useState<number>(25);
  const [page, setPage] = useState<string>("1");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [payouts, setPayouts] = useState<FCP_Payout[]>([]);
  const [payoutsTotal, setPayoutsTotal] = useState<number>(0);
  const fetchPayouts = useLazyGetPayouts();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { count, items } = await fetchPayouts({
          page: parseInt(page),
          limit,
        });
        setPayoutsTotal(count);
        setPayouts(items);
      } catch (error) {
        if (error instanceof CPException) {
          setError(error.message);
        } else {
          setError(t("validation:genericError"));
        }
      }

      setLoading(false);
    })();
  }, [page]);

  return (
    <>
      {loading && <Loader />}

      {error && <FormError title={error} />}

      <h3 className="mb-5 text-base font-semibold leading-6 text-simple-900">
        {t("dashboard:payouts")}
      </h3>

      <AlertSimple
        title={t("common:payoutsWeAreAwareOldPayoutsNotVisible", {
          email: `${getLanguage() === "fr" ? "aide" : "help"}@clicknpark.com`,
        })}
      />

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {payoutsTotal > 0 ? (
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-silver-800">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-simple-900 sm:pl-6"
                      >
                        {t("dashboard:arrivesOn")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-simple-900"
                      >
                        {t("dashboard:status")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-simple-900"
                      >
                        {t("dashboard:amount")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {payouts?.map((p, index) => {
                      let date = "";

                      if (p?.paidOutAt) {
                        date = formatDate(new Date(p.paidOutAt));
                      } else if (p?.payoutDate) {
                        date = formatDate(new Date(p.payoutDate));
                      }

                      const amount = p.formattedAmount;

                      const textColorClassName =
                        p.status === "canceled"
                          ? "text-gray-300"
                          : "text-simple-900";

                      return (
                        <tr key={`payout-${index}`}>
                          <td
                            className={`font-regular whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 ${textColorClassName}`}
                          >
                            {p.status === "canceled" ? <s>{date}</s> : date}
                          </td>
                          <td
                            className={`whitespace-nowrap px-3 py-4 text-sm ${textColorClassName}`}
                          >
                            {p.status === "canceled" ? (
                              <s>{t(`dashboard:${p.status}`)}</s>
                            ) : (
                              t(`dashboard:${p.status}`)
                            )}
                          </td>
                          <td
                            className={`textColorClassName whitespace-nowrap px-3 py-4 text-sm ${textColorClassName}`}
                          >
                            {p.status === "canceled" ? <s>{amount}</s> : amount}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <Pagination
                perPage={limit}
                total={payoutsTotal}
                currentPage={parseInt(page)}
                onPageChange={(updatedPage: number) =>
                  setPage(updatedPage.toString())
                }
              />
            </div>
          ) : (
            <p className="min-w-full sm:px-6 lg:px-8">
              {t("dashboard:noPayouts")}
            </p>
          )}
        </div>
      </div>
    </>
  );
}
